import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-withdraw-money',
  templateUrl: './withdraw-money.component.html',
  styleUrls: ['./withdraw-money.component.css']
})
export class WithdrawMoneyComponent implements OnInit {
  showMe: boolean = false
  constructor() { }

  ngOnInit(): void {
  }

  withdrawls = [
    {
      invest: "R0.00",
      locked: "(R0.00)",
      unsettled: "(R0.00)",
      available: "R0.00",
      withdraw: "0.00",
      validation: "Amount must be between R0.01 and R0.00",
    },
  ]

  toogleTag() {
    this.showMe = !this.showMe
  }

 

}
