<div class="page-cont">
  <div class="site-content">
    <div class="page-body">
      <div class="section-header">
        Section 4: WEEE Services Rendered
      </div>
      <form [formGroup]="ServicesRenderedForm" class="form-group" autocomplete="off">
        <h6 class="pb">If you are a street collector, fill out this section:</h6>
        <div id="service-provider">
          <div class="second-sec">
            <label class="form-label">WEEE Collector Name</label><input type="text" name="collectorName" formControlName="collectorName" placeholder="Enter Name of Collector" /><br /><br />
            <label class="form-label">WEEE Collector Physical Address</label><input type="text" name="collectorAddress" formControlName="collectorAddress" placeholder="Enter Address of Collector" /><br /><br />
            <label class="form-label">Buy-Back Centres Used</label><input type="text" name="buyBackCenter" formControlName="buyBackCenter" placeholder="Enter Buy Back Centres" /><br /><br />
            <label class="form-label">WEEE Holding / Consolidation Depot Used</label><input type="text" name="consolidationPort" formControlName="consolidationPort" placeholder="Enter Holding/Consolidation Depot" /><br /><br />
          </div>
          <div class="third-sec">
            <label class="form-label">WEEE Refurbishers / Re-purposers Used</label><input type="text" name="refurbisherName" formControlName="refurbisherName" placeholder="Enter Name of Re-purposer" /><i (click)="add_to_list('refurbisher')" class="fa-solid fa-circle-plus" aria-hidden="true">Add Name</i><br /><br />
            <div class="third-sec add-on" *ngFor="let num of extra.refurbisher; let i = index;">
              <input type="text" name="refurbisher{{num}}" formControlName="refurbisher{{num}}" placeholder="Enter Name of Re-purposer" />
              <i (click)="remove_from_list('refurbisher', i)" class="fa-solid fa-circle-xmark" aria-label="Remove Name Field">Remove</i>
              <br />
            </div>
            <label class="form-label">WEEE Dismantlers Used</label><input type="text" name="dismantlerName" formControlName="dismantlerName" placeholder="Enter Name of Dismantlers" /><i (click)="add_to_list('dismantler')" class="fa-solid fa-circle-plus" aria-hidden="true">Add Name</i><br /><br />
            <div class="third-sec add-on" *ngFor="let num of extra.dismantler; let i = index;">
              <input type="text" name="dismantler{{num}}" formControlName="dismantler{{num}}" placeholder="Enter Name of Dismantler" />
              <i (click)="remove_from_list('dismantler', i)" class="fa-solid fa-circle-xmark" aria-label="Remove Name Field">Remove</i>
              <br />
            </div>
            <label class="form-label">WEEE Recyclers Used</label><input type="text" name="recyclerName" formControlName="recyclerName" placeholder="Enter Name of Recyclers" /><i (click)="add_to_list('recycler')" class="fa-solid fa-circle-plus" aria-hidden="true">Add Name</i><br /><br />
            <div class="third-sec add-on" *ngFor="let num of extra.recycler; let i = index;">
              <input type="text" name="recycler{{num}}" formControlName="recycler{{num}}" placeholder="Enter Name of Recycler" />
              <i (click)="remove_from_list('recycler', i)" class="fa-solid fa-circle-xmark" aria-label="Remove Name Field">Remove</i>
              <br />
            </div>
            <label class="form-label">WEEE Transports Used</label><input type="text" name="transportUsed" formControlName="transportUsed" placeholder="Enter Name of Transports" /><i (click)="add_to_list('transportUsed')" class="fa-solid fa-circle-plus" aria-hidden="true">Add Name</i><br /><br />
            <div class="third-sec add-on" *ngFor="let num of extra.transportUsed; let i = index;">
              <input type="text" name="transportUsed{{num}}" formControlName="transportUsed{{num}}" placeholder="Enter Name of Transport Used" />
              <i (click)="remove_from_list('transportUsed', i)" class="fa-solid fa-circle-xmark" aria-label="Remove Name Field">Remove</i>
              <br />
            </div>
          </div>
        </div>
        <div class="second-sec" id="add_provider"></div>
        <div>

        </div>
        <div class="footer-sec">
          <div class="page-scroll">
            <button class="prev" (click)="pt1();save()">PREVIOUS</button>
            <span>4/8</span>
            <button class="next" (click)="pt2();save()">NEXT</button>
            <progress value="50" max="100"></progress>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
