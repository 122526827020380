<div class="page-cont">
    <div class="site-content">
      <div class="page-body">
        <div class="section-header">
          Section 1: Company Details
        </div>
        <form [formGroup]="CompanyDetailsForm" class="form-group" autocomplete="off">
          <div class="first-sec">
            <label style="margin-right: 6%;font-weight:bolder" class="required">Please select the service(s) you render: </label>
            <input type="checkbox" formControlName="wasteCollector" name="wasteCollector" value="wasteCollector" /><label class="checkbox">Waste Collector</label>
            <input type="checkbox" formControlName="recycler" name="recycler" value="recycler" /><label class="checkbox">Recycler</label>
            <input type="checkbox" formControlName="transporter" name="transporter" value="transporter" /><label class="checkbox">Transporter </label>
          </div>
          <div class="second-sec">
            <label class="form-label required" required>Legal Entity</label><input type="text" placeholder="Legal Entity" name="legalEntity" formControlName="legalEntity" /><br /><br />
            <div class="input-error" *ngIf="CompanyDetailsForm.controls.legalEntity?.invalid && (CompanyDetailsForm.controls.legalEntity?.dirty || CompanyDetailsForm.controls.legalEntity?.touched)">
              <div *ngIf="CompanyDetailsForm.controls.legalEntity?.errors?.required">
                Legal Entity Name is required.
              </div>
            </div>
            <label class="form-label required" required>Trade Name</label><input type="text" placeholder="Trade Name" name="tradeName" formControlName="tradeName" /><br /><br />
            <div class="input-error" *ngIf="CompanyDetailsForm.controls.tradeName?.invalid && (CompanyDetailsForm.controls.tradeName?.dirty || CompanyDetailsForm.controls.tradeName?.touched)">
              <div *ngIf="CompanyDetailsForm.controls.tradeName?.errors?.required">
                Legal Entity Name is required.
              </div>
            </div>
            <label class="form-label">Registration</label><input type="text" formControlName="providerRegistration" placeholder="Registration Number" /><br /><br />
            <label class="form-label">Registered Address</label><input type="text" formControlName="registeredAddress" placeholder="Registered Address" /><br /><br />
            <label class="form-label">Company Tax Number</label><input type="text" formControlName="taxNumber" placeholder="Company Tax Number" /><br /><br />
            <label class="form-label">Company VAT Number</label><input type="text" formControlName="vatNumber" placeholder="Company VAT Number" /><br /><br />
            <label class="form-label">Company Email Address</label><input type="text" formControlName="companyEmail" placeholder="Company Email Address" /><br /><br />
            <label class="form-label">Finance Email Address</label><input type="text" formControlName="financeEmail" placeholder="Finance Email Address" /><br /><br />
            <label class="form-label">Website Address</label><input type="text" formControlName="websiteAddress" placeholder="Website Address" /><br /><br />
          </div>
          <div class="third-sec">
            <label class="form-label">Number of Years of Company Operation</label><input type="number" name="yearEstablished" formControlName="yearEstablished" placeholder="" /><span>Number of years</span><br /><br />
            <label class="form-label">Number of Years as WEEE Services Provider</label><input type="number" name="yearsAsProvider" formControlName="yearsAsProvider" placeholder="" /><span>Number of years</span><br /><br />
          </div>
          <div class="fourth-sec row">
            <label class="form-label col-sm-5" style="margin-right: 1.7%;">Select WEEE Services Geographic Locations</label>
            <div class="col-sm-7" >
              <span *ngFor="let x of provinces; let i = index">
                <input type="checkbox" class="location" name="x.value" formControlName="{{x.value}}" (change)="addLocation(x.value)" /><label class="location-label">{{x.value}}</label><br *ngIf="(i+1)%3==0" />
              </span>
            </div>
          </div>

        </form>
        <div class="footer-sec">
          <div class="page-scroll">
            <button class="prev" disabled>PREVIOUS</button>
            <span>1/8</span>
            <button class="next" (click)="pt2();save()">NEXT</button>
            <progress value="12.5" max="100"></progress>
          </div>
        </div>
      </div>
    </div>
</div>
