<div class="page-wrapper text-center">
  <div id="header" class="section-container">
    <h3>Welcome! It's great to have you on board</h3>
    <h5>Getting started is simple with these 3 steps</h5>
    <div class="content-box-row content-box-wrap">
      <div class="content-box-sm border">
        <div></div>
        <div class="content-box-row">
          <div class="get-started-icon icon-container-md">
            <img src="../../assets/dashboard/icons/wallet.svg" />
          </div>
          <div class="get-started-text content-box-column">
            <a src="#">Fund your account</a>
          </div>
        </div>
      </div>
      <div class="content-box-sm border">
        <div></div>
        <div class="content-box-row">
          <div class="get-started-icon icon-container-md">
            <img src="../../assets/dashboard/icons/building.svg" />
          </div>
          <div class="get-started-text content-box-column">
            <p>Make your first purchase:</p>
            <a [routerLink]="['/browse-properties']">Browse Properties</a>
          </div>
        </div>
      </div>
      <div class="content-box-sm border">
        <div></div>
        <div class="content-box-row">
          <div class="get-started-icon icon-container-md">
            <img src="../../assets/dashboard/icons/contact.svg" />
          </div>
          <div class="get-started-text content-box-column">
            <a src="#">Complete your bank details</a>
            <p>so that you can withdraw funds later</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="summary" class="section-container">
    <div class="content-box-sm shadow padding-15">
      <p class="no-margin">LATEST VALUATION</p>
      <h3 class="no-margin">{{valuation | percent: '1.0-2'}}</h3>
      <p class="no-margin">{{valuation | currency: 'ZAR': 'symbol-narrow': '1.0-0'}}</p>
    </div>
    <div class="content-box-sm shadow padding-15">
      <p class="no-margin">UNREALIZED VALUATION GAIN</p>
      <h3 class="no-margin">{{valuationGain | percent: '1.0-2'}}</h3>
      <p class="no-margin">{{valuationGain | currency: 'ZAR': 'symbol-narrow': '1.0-0'}}</p>
    </div>
    <div class="content-box-sm shadow padding-15">
      <p class="no-margin">TOTAL RENTAL DIVIDEND RECEIVED</p>
      <h3 class="no-margin">{{rentalDividend | currency: 'ZAR': 'symbol-narrow': '1.0-0'}}</h3>
    </div>
  </div>
  <div id="iconMessage" class="section-container">
    <div class="icon-container-md">
      <img src="../../assets/dashboard/icons/charts_empty@2x.png" />
    </div>
    <h5>Start building your portfolio to see your portfolio performance here</h5>
  </div>
  <div id="balances" class="section-container">
    <div class="tile-container">
      <div class="shadow padding-15" *ngFor="let balance of balanceList">
        <div class="tile-row">
          <p class="no-margin balance">{{balance.DisplayName}}</p>
          <div class="balance-row">
            <p class="balance">{{balance.Value | currency:'ZAR':'symbol-narrow':'1.2-2'}}</p>
            <div class="balance">
              <img class="list-arrow" src="../../assets/dashboard/icons/list-arrow.svg" (click)="toggleTile(balance.Id)" />
            </div>
          </div>
        </div>
        <ul class="balance" id="balance.Id" *ngIf="balance.toggle">
          <li style="width:100%" class="tile-row" *ngFor="let item of balance.Items">
            <div style="width:100%" *ngIf="balance.Type != 1">
              <a style="width:100%" class="tile-row" [routerLink]="[item.Link]"><div class="tile-row"><img class="icon" src="{{item.Icon}}" /><pre></pre><p style="vertical-align:central">{{item.Text}}</p></div><img class="list-arrow rotate" src="../../assets/dashboard/icons/arrow-right.svg" /></a>
            </div>
            <p class="no-margin" *ngIf="balance.Type == 1">{{item.DisplayName}}</p>
            <span>{{item.Value | currency:'ZAR':'symbol-narrow':'1.2-2'}}</span>
          </li>
        </ul>
      </div>
    </div>
    <!--<div class="tile-container">
      <div class="shadow padding-15">
        <div class="tile-row">
          <p class="no-margin balance">Profit/Loss</p>
          <div class="balance-row">
            <p class="balance">R 0.00/+ 0%</p>
            <div class="balance">
              <img class="list-arrow" src="../../assets/dashboard/icons/list-arrow.svg" (click)="toggleTile('profit')" />
            </div>
          </div>
        </div>
        <ul class="balance" id="profit" *ngIf="tileStatus.profit">
          <li class="tile-row">
            <p class="no-margin">Rental Dividend</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">Latest Valuation Gains</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">Purchase Value</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">Pending Purchases</p>
            <span>
              R 0.00
            </span>
          </li>
        </ul>
      </div>
      <div class="shadow padding-15">
        <div class="tile-row">
          <p class="no-margin">Total Costs</p>
          <span>
            R 0.00
            <img class="list-arrow" src="../../assets/dashboard/icons/list-arrow.svg" (click)="toggleTile('costs')" />
          </span>
        </div>
        <ul id="costs" class="balance" *ngIf="tileStatus.costs">
          <li class="tile-row">
            <p class="no-margin">Brokerage Costs</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">Statutory Costs</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">IPO Capital raising fees</p>
            <span>
              R 0.00
            </span>
          </li>
        </ul>
      </div>
      <div class="shadow padding-15">
        <div class="tile-row">
          <p class="no-margin">Net Accruals</p>
          <span>
            R 0.00
            <img class="list-arrow" src="../../assets/dashboard/icons/list-arrow.svg" (click)="toggleTile('accruals')" />
          </span>
        </div>
        <ul id="accruals" class="balance" *ngIf="tileStatus.accruals">
          <li class="tile-row">
            <p class="no-margin">Accrued Interest Income</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">Accrued Platform Fee</p>
            <span>
              R 0.00
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="tile-container">
      <div class="shadow padding-15">
        <div class="tile-row">
          <p class="no-margin">Your funds to invest</p>
          <span>
            R 0.00
            <img class="list-arrow" src="../../assets/dashboard/icons/list-arrow.svg" (click)="toggleTile('funds')" />
          </span>
        </div>
        <ul id="funds" class="balance" *ngIf="tileStatus.funds">
          <li class="tile-row">
            <p class="no-margin">Withdrawable Funds</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">Reserved Funds</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">Unsettled Cash</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">Locked Funds</p>
            <span>
              R 0.00
            </span>
          </li>
        </ul>
      </div>

      <div class="shadow padding-15">
        <div class="tile-row">
          <p class="no-margin">Your funds to invest</p>
          <span>
            R 0.00
            <img class="list-arrow" src="../../assets/dashboard/icons/list-arrow.svg" (click)="toggleTile('funds')" />
          </span>
        </div>
        <ul id="funds" class="balance" *ngIf="tileStatus.funds">
          <li class="tile-row">
            <p class="no-margin">Withdrawable Funds</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">Reserved Funds</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">Unsettled Cash</p>
            <span>
              R 0.00
            </span>
          </li>
          <li class="tile-row">
            <p class="no-margin">Locked Funds</p>
            <span>
              R 0.00
            </span>
          </li>
        </ul>
      </div>
      <div class="shadow padding-15">
        <div class="tile-row">
          <p class="no-margin">Account Actions</p>
          <span>
            <img class="list-arrow" src="../../assets/dashboard/icons/list-arrow.svg" (click)="toggleTile('actions')" />
          </span>
        </div>
        <ul id="actions" class="balance" *ngIf="tileStatus.actions">
          <li class="tile-row">
            <a class="tile-row" [routerLink]="['/wallet/fund-this-account']"><div class="tile-row"><img class="icon" src="../../assets/dashboard/icons/money.svg" /><pre></pre>Fund this account</div><img class="list-arrow rotate" src="../../assets/dashboard/icons/arrow-right.svg" /></a>
          </li>
          <li class="tile-row">
            <a class="tile-row" [routerLink]="['/wallet/withdraw-money']"><div class="tile-row"><img class="icon" src="../../assets/dashboard/icons/arrow-circle-right.svg" /><pre></pre>Withdraw money</div><img class="list-arrow rotate" src="../../assets/dashboard/icons/arrow-right.svg" /></a>
          </li>
          <li class="tile-row">
            <a class="tile-row" [routerLink]="['/wallet/fund-this-account']"><div class="tile-row"><img class="icon" src="../../assets/dashboard/icons/arrow-circle-bidirectional.svg" /><pre></pre>Inter-account transfers</div><img class="list-arrow rotate" src="../../assets/dashboard/icons/arrow-right.svg" /></a>
          </li>
        </ul>
      </div>
    </div>-->
  </div>
  <div id="investments" class="section-container">
    <div class="invest-wizard">
      <div class="tab-button" (click)="setActiveTab(0)" [style]="activeTab == 0 ? 'border-bottom: 3px solid #C57A0F' : 'border: none'"><p>My Properties</p></div>
      <div class="tab-button" (click)="setActiveTab(1)" [style]="activeTab == 1 ? 'border-bottom: 3px solid #C57A0F' : 'border: none'"><p>Pending Investments</p></div>
      <div class="tab-button" (click)="setActiveTab(2)" [style]="activeTab == 2 ? 'border-bottom: 3px solid #C57A0F' : 'border: none'"><p>Transaction History</p></div>
      <div class="tab-button" (click)="setActiveTab(3)" [style]="activeTab == 3 ? 'border-bottom: 3px solid #C57A0F' : 'border: none'"><p>My Market Place</p></div>
    </div>
    <div class="invest-content">
      <div id="myProperties" *ngIf="activeTab == 0">
        <div class="content-container">
          <div class="table-container">
            <div *ngIf="transaction$ | async as information else notransactions">
              <table class="table">
                <thead>
                  <tr>
                    <th>Property</th>
                    <th>Unit Price</th>
                    <th>Unit Quantity</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let investment of information.vaps">
                    <td>{{ investment.valueJson.propertyName }}</td>
                    <td>{{ investment.valueJson.unitPrice | currency: 'ZAR': 'symbol-narrow': '1.2-2' }}</td>
                    <td>{{ investment.valueJson.unitsApplied }}</td>
                    <td>{{ investment.valueJson.total | currency: 'ZAR': 'symbol-narrow': '1.2-2' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ng-template #notransactions>
              <div>
                <strong>No Transactions Found</strong>
              </div>
            </ng-template>
          </div>
          <div class="button-container">
            <button id="download-transaction" class="button button--dark-blue">Download Transactions</button>
          </div>
        </div>
      </div>
      <div id="pendingInvestments" *ngIf="activeTab == 1">
        <div class="title-container">
          <h5>Pending Orders</h5>
        </div>
        <div class="content-container" *ngIf="!properties">
          <img class="no-property-img" src="../../assets/dashboard/new listings_empty@2x.png" />
          <p>Looking for a new property?</p>
          <a [routerLink]="['/browse-properties']">Browse our new properties</a>
        </div>
        <div class="content-body" *ngIf="properties">
          <div class="property-tile" *ngFor="let property of properties">
            <div class="property-tile-img">
              <img class="property-img" [src]="property.img" />
            </div>
            <div class="tile-content">
              <h5>{{property.title}}</h5>
              <div class="space-between">
                <p>Funding Target:</p>
                <p>{{property.target | currency: 'ZAR': 'symbol-narrow': '1.0-0'}}</p>
              </div>
              <div class="progress-bar-container">
                <div class="space-between">
                  <p class="balance-text bold" [ngStyle]="{color: progress(property.balance, property.target) < 1 ? '#005bff' : '#31ff00'}">{{progress(property.balance, property.target) | percent: '1.0-2'}} Funded</p>
                  <a class="small-text" href="">What's this?</a>
                </div>
                <div class="progress-bar">
                  <div class="progress" [ngStyle]="setProgressStyle(progress(property.balance, property.target))"></div>
                </div>
              </div>
              <div class="space-between">
                <span class="small-text">
                  Internal Rate of Return (IRR)
                  <i class="fa-solid fa-info-circle"><span class="tooltiptext">Combination of expected yield and assumed capital growth per year (after all costs). Please refer to the prospectus for details on the assumptions used in the calculation.</span></i>
                </span>
                <p class="small-text bold">{{property.rateOfReturn | percent: '1.0-2'}}</p>
              </div>
              <div class="space-between">
                <span class="small-text">
                  Est Net Rental Yield
                  <i class="fa-solid fa-info-circle"><span class="tooltiptext">Estimated annual rental received, less estimated expenses divided by purchase price</span></i>
                </span>
                <p class="small-text bold">{{property.netRentalYield | percent: '1.0-2'}}</p>
              </div>
              <div class="space-between">
                <span class="small-text">
                  Market Valuation
                  <i class="fa-solid fa-info-circle"><span class="tooltiptext">Determined by the most recent sales value of equivalent units in the property or by recent independent valuations of the units.</span></i>
                </span>
                <p class="small-text bold">{{property.marketValuation | currency: 'ZAR': 'symbol-narrow': '1.0-0'}}</p>
              </div>
              <div class="space-between">
                <span class="small-text">
                  Discount
                  <i class="fa-solid fa-info-circle"><span class="tooltiptext">Percentage of bulk purchase discount  achieved vs market valuation</span></i>
                </span>
                <p class="small-text bold">{{property.discount | percent: '1.0-2'}}</p>
              </div>
              <div class="button-group">
                <button class="button button--dark-blue" [routerLink]="['/view-properties']">View Property</button>
              </div>
              <div class="center">
                <p class="small-text">{{property.note}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="transactionHistory" *ngIf="activeTab == 2">
        <div class="title-container">
          <h5>Transactions</h5>
          <select>
            <option>Last 30 days</option>
            <option>Last 60 days</option>
            <option>Last 90 days</option>
          </select>
        </div>
        <div class="content-container">
          <div class="table-container">
            <div *ngIf="transaction$ | async as information else notransactions">
              <table class="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Period</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let transaction of information.transactions">
                    <td>{{ transaction.transactionDate | date: 'dd/MM/yyyy' }}</td>
                    <td>{{ transaction.description }}</td>
                    <td>{{ transaction.debit | currency: 'ZAR': 'symbol-narrow': '1.2-2' }}</td>
                    <td>{{ transaction.credit | currency: 'ZAR': 'symbol-narrow': '1.2-2' }}</td>
                    <td>{{ transaction.period }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ng-template #notransactions>
              <div>
                <strong>No Transactions Found</strong>
              </div>
            </ng-template>
          </div>
          <div class="button-container">
            <button id="download-transaction" class="button button--dark-blue">Download Transactions</button>
          </div>
        </div>
      </div>
      <div id="marketPlace" *ngIf="activeTab == 3">
        <div class="title-container">
          <h5>Market Place</h5>
        </div>
        <div class="content-container">
          <div class="table-container">
            <table class="table" *ngIf="transactions">
              <thead>
                <tr>
                  <th>BID/OFFER</th>
                  <th>PROPERTY</th>
                  <th>NO. SHARES</th>
                  <th>VALUE</th>
                  <th>PRICE</th>
                  <th>PROBABLE PRICE</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let offer of offers">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="button-container">
            <button id="download-transaction" class="button button--dark-blue">Download Transactions</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
