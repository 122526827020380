import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';
import { ApiAccessService } from '../../api-access.service';
import { Attachment } from '../../models/attachment.model';
import { Entity } from '../../models/entity.model';
import { Registration } from '../../models/registration.model';
import { RequirementDetail } from '../../models/requirement-detail.model';
import { Requirement } from '../../models/requirement.model';
import { SystemCode, SystemCodeModel } from '../../models/system-code.model';
import { navbarData } from '../sidenav/nav-data';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { forEachChild } from 'typescript';

@Component({
  selector: 'app-site-info',
  templateUrl: './site-info.component.html',
  styleUrls: ['./site-info.component.css']
})
export class SiteInfoComponent implements OnInit {
  @Output()
  close_model = new EventEmitter<string>();

  SiteInfoForm: FormGroup;
  EntityDetails: FormGroup;
  entity: Registration;
  navData = navbarData;

  provinces: SystemCode[];
  countries: SystemCode[];
  months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  years: number[];
  options: {
    saving: boolean,
    duplicate: boolean,
    got_system_codes: boolean,
    uploading: boolean,
    complete: boolean,
    requirementClicked: RequirementDetail,
  }

  sites: number[];

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log("route changed");
        this.save();
      }
    });
}

  ngOnInit(): void {
     this.SiteInfoForm = new FormGroup({
      facilityName: new FormControl(),
      lease: new FormControl(),
      owned: new FormControl(),
      yearsLeased: new FormControl(),
      physicalSecurity: new FormControl(),
      noPhysicalSecurity: new FormControl(),
      dayOnlySecurity: new FormControl(),
      nightOnlySecurity: new FormControl(),
      allDaySecurity: new FormControl(),
      alarmSystem: new FormControl(),
      alarmMonitoring: new FormControl(),
      alarmResponse: new FormControl(),
      insurance: new FormControl(),
      noAlarmSystem: new FormControl(),
      noAlarmMonitoring: new FormControl(),
      noAlarmResponse: new FormControl(),
      noInsurance: new FormControl(),
      insuranceType: new FormControl(),
      coverAmount: new FormControl(),
      numberOffices: new FormControl(),
      officeEquipment: new FormControl(),
      wiFi: new FormControl(),
      printer: new FormControl(),
      laptops: new FormControl(),
      financialSystem: new FormControl(),
      operationalSystem: new FormControl(),
      administrationSystem: new FormControl(),
      onetonVehicle: new FormControl(),
      fivetonVehicle: new FormControl(),
      eighttonVehicle: new FormControl(),
      tentonVehicle: new FormControl(),
      fourteentonVehicle: new FormControl(),
      twentytonVehicle: new FormControl(),
      forklift: new FormControl(),
      ownLogistics: new FormControl(),
       logisticsSystem: new FormControl(),
       noForklift: new FormControl(),
       noOwnLogistics: new FormControl(),
       noLogisticsSystem: new FormControl(),
      collectionRadius: new FormControl(),
     });
    this.reset_forms();
  }

  reset_forms() {
    this.sites = [];
    this.add_site(1);
    var obj = sessionStorage.getItem('siteinfoform');
    if (sessionStorage.length > 0 && obj) {
       var ob = JSON.parse(obj);
      for (var i = 2; i < 6; i++) {
        if (ob.hasOwnProperty("ERFSize" + i)) { this.add_site(i); };
      }
      this.SiteInfoForm.patchValue(ob);
    }
    this.entity = {
      attachments: [],
      company: {},
      companyEntity: {},
      contactPersons: [],
      entity: {},
      requirements: []
    };

  }

  add_site(i?: number) {
    if (this.sites.length > 5) {
      window.alert("Max of 5 sites allowed for now");
    }
    let num = i ? i : this.sites.length ? this.sites[this.sites.length - 1] + 1 : 1;
    this.SiteInfoForm.addControl("ERFSize" + num, new FormControl(""));
    this.SiteInfoForm.addControl("buildingSize" + num, new FormControl(""));
    this.SiteInfoForm.addControl("insideSpace" + num, new FormControl(""));
    this.SiteInfoForm.addControl("outsideSpace" + num, new FormControl(""));
   
    this.sites.push(num);
  }

  remove_site(num: number, index: number) {
    this.SiteInfoForm.removeControl("ERFSize" + num);
    this.SiteInfoForm.removeControl("buildingSize" + num);
    this.SiteInfoForm.removeControl("insideSpace" + num);
    this.SiteInfoForm.removeControl("outsideSpace" + num);
     this.sites.splice(index, 1);
  }


  save() {

    if (this.SiteInfoForm.value) {
      sessionStorage.setItem('siteinfoform', JSON.stringify(this.SiteInfoForm.value));
      sessionStorage.setItem('siteInfo', "true");
    }

  }


  pt1() {
    this.router.navigate(['service-portal/service-portal/dismantling-consolidation'])
  }

  pt2() {
    this.router.navigate(['service-portal/service-portal/business-process'])
  }

}
