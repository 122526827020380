import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {

  @Output() close = new EventEmitter<string>();
  @Output() confirmed = new EventEmitter<string>();

  @Input() message = "";
  @Input() title = "";

  constructor() { }

  ngOnInit() { }

  close_model(message: string) {
    this.close.emit(message);
  }

  confirm() {
    this.confirmed.emit();
  }
}
