import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiAccessService } from '../../api-access.service';
import { PaymentMethod } from '../../models/payment-method';
import { Property } from '../../models/property.model';
import { AuthState } from '../../state/auth/auth.reducer';
import { saveObjectValue } from '../../state/object-value/object-value.actions';

@Component({
  selector: 'app-view-properties',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.css']
})
export class PropertyDetailComponent implements OnInit {

  constructor(private store: Store<AuthState>, private currencyPipe: CurrencyPipe, private route: ActivatedRoute, private apiService: ApiAccessService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    if (history.state.instance) {
      this.property$ = of(<Property>history.state);
    } else {
      this.property$ = this.route.paramMap.pipe(
        switchMap((params) => {
          return this.apiService.get_property(+params.get('instance'));
        })
      );
    }
  }
  paymentMethod = {};
  paymentForm = new FormGroup({
    amount: new FormControl('', [Validators.required, Validators.min(100)]),
    method: new FormControl('', [Validators.required])
  });

  paymentMethods: PaymentMethod[] = [
    {
      method: "CC",
      type: "Credit",
      text: "Credit or Debit Card"
    },
    {
      method: "BT",
      type: "SID",
      text: "Instant EFT (SiD)"
    },
    {
      method: "EW",
      type: "Zapper",
      text: "Zapper"
    },
    {
      method: "EW",
      type: "Mobicred",
      text: "Mobicred"
    },
    {
      method: "EW",
      type: "Momopay",
      text: "Momopay"
    }, {
      method: "EW",
      type: "MasterPass",
      text: "MasterPass"
    }, {
      method: "EW",
      type: "SnapScan",
      text: "SnapScan"
    }, {
      method: "EW",
      type: "PayPal",
      text: "PayPal"
    }
  ]

  property$: Observable<Property>;

  progress(balance, target) {
    return balance / target;
  }

  setProgressStyle(progress) {
    var progressStyle: Record<string, any> = {};

    progressStyle = {
      'background-color': Math.floor(progress * 100) / 100 < 1 ? '#005bff' : '#31ff00',
      'width': Math.floor(progress) < 1 ? Math.floor(progress * 100) + '%' : '100%'
    };
    return progressStyle;
  }

  makePayment(instance: number) {
    this.store.dispatch(saveObjectValue({
      instance: instance,
      amount: this.paymentForm.get('amount').value * 100,
      method: <PaymentMethod>this.paymentForm.get('method').value
    }));
  }
}
