import { CurrencyPipe } from '@angular/common';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxCurrencyModule } from 'ngx-currency';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { environment } from '../environments/environment';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './about-us/contact-us/contact-us.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { MyProfileComponent } from './account/my-profile/my-profile.component';
import { AppComponent } from './app.component';
import { BrowsePropertiesComponent } from './browse-properties/browse-properties.component';
import { PropertyDetailComponent } from './browse-properties/detail/property-detail.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { httpInterceptorProviders } from './http-interceptors';
import { LoginComponent } from './dialogs/login/login.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SuccessfullComponent } from './payment/successfull/successfull.component';
//import { ListPropertyComponent } from './properties/list-property/list-property.component';
//import { AuctionCalenderComponent } from './properties/auction-calender/auction-calender.component';
import { ViewComponent } from './properties/view/view.component';
import { RegisterComponent } from './register/register.component';
import { ConfirmComponent } from './dialogs/confirm/confirm.component';
import { AuthEffects } from './state/auth/auth.effects';
import { authReducer } from './state/auth/auth.reducer';
import { ObjectEffects } from './state/object-value/object-value.effects';
import { objectReducer } from './state/object-value/object-value.reducer';
import { PropEffects } from './state/properties/properties.effects';
import { propReducer } from './state/properties/properties.reducer';
import { systemReducer } from './state/system/system.reducer';
import { AlertsComponent } from './system/alerts/alerts.component';
import { FundThisAccountComponent } from './wallet/fund-this-account/fund-this-account.component';
import { InterAccountTransfersComponent } from './wallet/inter-account-transfers/inter-account-transfers.component';
import { WithdrawMoneyComponent } from './wallet/withdraw-money/withdraw-money.component';
import { GoodsManagementComponent } from './goods-management/goods-management.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordResetComponent } from './dialogs/password-reset/password-reset.component';
import { ServicePortalComponent } from './service-portal/service-portal.component';
import { SidenavComponent } from './service-portal/sidenav/sidenav.component';
import { CompanyDetailsComponent } from './service-portal/company-details/company-details.component';
import { BodyComponent } from './service-portal/body/body.component';
import { UploadDocumentsComponent } from './service-portal/upload-documents/upload-documents.component';
import { ManagementComponent } from './service-portal/management/management.component';
import { ServicesRenderedComponent } from './service-portal/services-rendered/services-rendered.component';
import { WasteCollectorComponent } from './service-portal/waste-collector/waste-collector.component';
import { DismantlingConsolidationComponent } from './service-portal/dismantling-consolidation/dismantling-consolidation.component';
import { SiteInfoComponent } from './service-portal/site-info/site-info.component';
import { BusinessProcessComponent } from './service-portal/business-process/business-process.component';




@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    HowItWorksComponent,
    //AuctionCalenderComponent,
    //ListPropertyComponent,
    ViewComponent,
    //RentUnitComponent,
    //NewsComponent,
    ContactUsComponent,
    //DividendCalendarComponent,
    AboutUsComponent,
    LoginComponent,
    FooterComponent,
    RegisterComponent,
    AboutUsComponent,
    LoginComponent,
    BrowsePropertiesComponent,
    //HelpCenterComponent,
    DashboardComponent,
    MyProfileComponent,
    ChangePasswordComponent,
    WithdrawMoneyComponent,
    FundThisAccountComponent,
    InterAccountTransfersComponent,
    SuccessfullComponent,
    PropertyDetailComponent,
    AlertsComponent,
    PageNotFoundComponent,
    GoodsManagementComponent,
    ConfirmComponent,
    PasswordResetComponent,
    ServicePortalComponent,
    SidenavComponent,
    CompanyDetailsComponent,
    BodyComponent,
    UploadDocumentsComponent,
    ManagementComponent,
    ServicesRenderedComponent,
    WasteCollectorComponent,
    DismantlingConsolidationComponent,
    SiteInfoComponent,
    BusinessProcessComponent
  ],
  imports: [
    StoreModule.forRoot({ auth: authReducer, properties: propReducer, system: systemReducer, object: objectReducer }),
    EffectsModule.forRoot([AuthEffects, PropEffects, ObjectEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    RouterModule.forRoot([

      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'how-it-works', component: HowItWorksComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'login', component: LoginComponent },
      //{ path: 'news', component: NewsComponent },
      //{ path: 'properties/auction-calender', component: AuctionCalenderComponent },
      //{ path: 'properties/list-property', component: ListPropertyComponent },
      // { path: 'properties/rent-unit', component: RentUnitComponent },
      { path: 'properties/view', component: ViewComponent },
      //{ path: 'properties/dividend-calendar', component: DividendCalendarComponent },
      { path: 'about-us/contact-us', component: ContactUsComponent },
      //{ path: 'about-us/help-center', component: HelpCenterComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'browse-properties', component: BrowsePropertiesComponent },
      { path: 'browse-properties/:instance', component: PropertyDetailComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'account/my-profile', component: MyProfileComponent },
      { path: 'account/change-password', component: ChangePasswordComponent },
      { path: 'wallet/withdraw-money', component: WithdrawMoneyComponent },
      { path: 'wallet/fund-this-account', component: FundThisAccountComponent },
      { path: 'wallet/inter-account-transfers', component: InterAccountTransfersComponent },
      { path: 'payment/response/:type', component: SuccessfullComponent },
      { path: 'wallet/inter-account-transfers', component: InterAccountTransfersComponent },
      { path: 'goods-management', component: GoodsManagementComponent },
      {
        path: 'service-portal', component: ServicePortalComponent, children: [

          { path: 'service-portal/company-details', component: CompanyDetailsComponent },
          { path: 'service-portal/upload-documents', component: UploadDocumentsComponent },
          { path: 'service-portal/management', component: ManagementComponent },
          { path: 'service-portal/services-rendered', component: ServicesRenderedComponent },
          { path: 'service-portal/waste-collector', component: WasteCollectorComponent },
          { path: 'service-portal/dismantling-consolidation', component: DismantlingConsolidationComponent },
          { path: 'service-portal/site-info', component: SiteInfoComponent},
          { path: 'service-portal/business-process', component: BusinessProcessComponent},

        ]
      },
      { path: '**', component: PageNotFoundComponent }
    ], { relativeLinkResolution: 'legacy' }),
    SlickCarouselModule,
    NgxCurrencyModule,
    NgbModule
  ],
  providers: [httpInterceptorProviders, CurrencyPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
