<div class="container" *ngIf="(info$ | async) || {} as info">
  <div class="row justify-content-center  pt-3">
    <div class="row btn-group " role="group" aria-label="Basic example">
      <button type="button" class="col-sm-3 btn btn-primary text-muted" (click)="go_to('first')">Personal Info</button>
      <button type="button" class="col-sm-3 btn btn-primary  text-muted" (click)="go_to('second')">Contact Info</button>
      <!--<button type="button" class="btn btn-primary text-muted" (click)="go_to('third')">Identity/KYC</button>-->
      <!--<button type="button" class="btn btn-primary text-muted" (click)="go_to('forth')">Tax Info</button>-->
      <button type="button" class="col-sm-3 btn btn-primary  text-muted" (click)="go_to('fifth')">Bank Info</button>
    </div>
  </div>

  <div class="page-body" *ngIf="is_pages(['first', 'second', 'third', 'forth', 'fifth'])">
    <!---------------First Tab-------------------->
    <div class="card pt-5" *ngIf="current_page('first')">
      <div class="card-header text-center">
        <h5 class="text-muted">Personal Details</h5>
      </div>
      <form [formGroup]="entityForm"
            (ngSubmit)="save_entity()">
        <div class="card-body">
          <fieldset class="form-columns-2">
            <div class="row">
              <div class="col-md-6">
                <label for="Name" class="required">First Name</label>
                <input type="text" class="form-control" id="Name" formControlName="name" required>
                <div class="input-error" *ngIf="entityForm.controls.name?.invalid && (entityForm.controls.name?.dirty || entityForm.controls.name?.touched)">
                  <div *ngIf="entityForm.controls.name?.errors?.required">
                    first names is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label class="required" for="Surname">Surname</label>
                <input type="text" class="form-control" id="Surname" formControlName="surname" required>
                <div class="input-error" *ngIf="entityForm.controls.surname?.invalid && (entityForm.controls.surname?.dirty || entityForm.controls.surname?.touched)">
                  <div *ngIf="entityForm.controls.surname?.errors?.required">
                    surname is required.
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset class="form-columns-2">
            <div class="row">
              <div class="col-md-6">
                <label for="BirthDate" class="required">Date of Birth</label>
                <input type="date" class="form-control" id="BirthDate" max="1979-12-31" formControlName="birthDate" required>
                <div class="input-error" *ngIf="entityForm.controls.birthDate?.invalid && (entityForm.controls.birthDate?.dirty || entityForm.controls.birthDate?.touched)">
                  <div *ngIf="entityForm.controls.birthDate?.errors?.required">
                    date of birth is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label for="Title">Tittle</label>
                <select id="Title" class="form-control" formControlName="title">
                  <option *ngFor="let t of titles" [ngValue]="t.id">{{t.value}}</option>
                </select>
              </div>
            </div>
          </fieldset>

          <div class="display-7 col-lg-12 mx-auto px-0 pt-5 pb-4"> Note: If you are not a South African resident or citizen but would like access to a ZAR account you will need to enter a valid SA ID. </div>
          <fieldset class="form-columns-2">
            <div class="row">
              <div class="col-md-6">
                <label for="IdType">Identification Type</label>
                <select id="IdType" class="form-control">
                  <option selected>SA ID no.</option>
                </select>
              </div>
              <div class="col-md-6">
                <label class="required">Identification Number</label>
                <input type="text" class="form-control" formControlName="identity" required>
                <div class="input-error" *ngIf="entityForm.controls.identity?.invalid && (entityForm.controls.identity?.dirty || entityForm.controls.identity?.touched)">
                  <div *ngIf="entityForm.controls.identity?.errors?.required">
                    identity number is required.
                  </div>
                  <div *ngIf="entityForm.controls.identity?.errors?.maxLength || entityForm.controls.identity?.errors?.minLength || entityForm.controls.identity?.errors?.pattern">
                    identity number must be 13 digits
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <div class="row">
            <div class="col">
              <div class="form-group col-md-12 pt-3" style="display: inline-flex;">
                <div class="form-check form-check-inline ">
                  <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="female" [value]="17">
                  <label class="form-check-label" for="female">Female</label>
                </div>
                <div class="form-check form-check-inline ">
                  <input class="form-check-input" type="radio" name="gender" formControlName="gender" id="male" [value]="16">
                  <label class="form-check-label" for="male">Male</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!--<div class="hs-form-field">
                <label>Country of Birth</label>
                <select class="form-control" formControlName="country">
                  <option *ngFor="let c of countries" [ngValue]="c.id">
                    {{c.value}}
                  </option>
                </select>
              </div>-->
              <!--<div class="form-group col-md-12">
                <label for="inputState">Country of Birth</label>
                <select id="inputState" class="form-control">
                  <option selected>South African</option>
                  <option>other</option>
                </select>
              </div>-->
            </div>
            <div class="col">
              <!--<div class="hs-form-field">
                <div class="ng-autocomplete">
                  <label>City Of Birth</label>
                  <ng-autocomplete [data]="suburbs"
                                   [searchKeyword]="keyword"
                                   [itemTemplate]="itemTemplate"
                                   [notFoundTemplate]="notFound"
                                   formControlName="suburb"
                                   (inputChanged)='onEntityChangeSearch($event)'>
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.value"></a>
                  </ng-template>

                  <ng-template #notFound let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>
              </div>-->
              <!--<div class="form-group col-md-12">
                <label for="inputCity">City Of Birth</label>
                <input type="text" class="form-control" id="inputFirstName">
              </div>-->
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!--<div class="hs-form-field">
                <label>Country Of Residence</label>
                <select class="form-control" formControlName="country">
                  <option *ngFor="let c of countries" [ngValue]="c.id">
                    {{c.value}}
                  </option>
                </select>
              </div>-->
              <!--<div class="form-group col-md-12">
                <label for="inputState">Country Of Residence</label>
                <select id="inputState" class="form-control">
                  <option selected>South African</option>
                  <option>other</option>
                </select>
              </div>-->
            </div>
            <div class="col">
              <!--<div class="hs-form-field">
                <label>Marital Status</label>
                <select class="form-control" formControlName="status">
                  <option *ngFor="let s of maritalstatuses" [ngValue]="s.id">
                    {{s.value}}
                  </option>
                </select>
              </div>-->
              <!--<div class="form-group col-md-12">
                <label for="inputState">Marital Status</label>
                <select id="inputState" class="form-control">
                  <option selected>Single</option>
                  <option>Married</option>
                </select>
              </div>-->
            </div>
          </div>
          <div class="row">
            <div class="col">
              <!--<div class="hs-form-field">
                <label>Citezenship</label>
                <select class="form-control" formControlName="country">
                  <option *ngFor="let c of countries" [ngValue]="c.id">
                    {{c.value}}
                  </option>
                </select>
              </div>-->
              <!--<div class="form-group col-md-12">
                <label for="inputState">Citezenship</label>
                <select id="inputState" class="form-control">
                  <option selected>South African</option>
                  <option>other</option>
                </select>
              </div>-->
            </div>

          </div>

          <div class="d-flex justify-content-center pt-5">
            <button type="submit" [disabled]="!entityForm.valid"  class= "hs-button btn-primary large">Save & Proceed</button>
          </div>
        </div>
      </form>
    </div>

    <!---------------Second Tab-------------------->
    <div class="card pt-5" *ngIf="current_page('second')">
      <div class="card-body ">
        <div class="text-center">
          <h5 class="text-muted">Address Information</h5>
          <span flex></span>
          <button type="button" (click)="add_address()" class="mybtn btn-primary text-muted">
            Add
          </button>
        </div>

        <div class="content-container">
          <div class="table-responsive-sm table-container">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    {{(info?.addresses?.length)? 'Addresses':'No Addresses Found' }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let address of info?.addresses">
                  <td>{{ address.addressText }}</td>
                  <td style="width:160px">
                    <button type="button" class="mybtn btn-primary text-muted" (click)="edit_address(address)">Edit</button>
                    <button type="button" class="mybtn btn-primary text-muted" (click)="openDelete_address(delete_address)">Delete</button>
                    <ng-template #delete_address let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title" id="modal-title">Address Deletion</h4>
                        <button type="button" class="btn-close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')"></button>
                      </div>
                      <div class="modal-body">
                        <p><strong>Are you sure you want to delete <span class="text-primary">"Address"</span> ?</strong></p>
                        <p>
                          All information associated to this user profile will be permanently deleted.
                          <span class="text-danger">This operation can not be undone.</span>
                        </p>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">No</button>
                        <button type="button" class="btn btn-danger" (click)="modal.close('Ok click');del_address(address?.id)">Yes</button>
                      </div>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        <div class="text-center">
          <h5 class="text-muted">Contact Information</h5>
          <span>
            <button type="button" (click)="add_contact()" class="mybtn btn-primary text-muted">Add</button>
          </span>
        </div>

        <div class="content-container">
          <div class="table-responsive-sm table-container">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    {{(info?.contactNumbers?.length)? 'Contact Numbers':'No Contact Numbers Found' }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let contact of info?.contactNumbers">
                  <td>(+27){{contact.number}}</td>
                  <td style="width:160px">
                    <button type="button" class="mybtn btn-primary text-muted" (click)="edit_contact(contact)">Edit</button>
                    <button type="button" class="mybtn btn-primary text-muted" (click)="openDelete_contact(delete_contact)">Delete</button>
                    <ng-template #delete_contact let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title" id="modal-title">Contact Deletion</h4>
                        <button type="button" class="btn-close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')"></button>
                      </div>
                      <div class="modal-body">
                        <p><strong>Are you sure you want to delete <span class="text-primary">"Contact"</span> ?</strong></p>
                        <p>
                          All information associated to this user profile will be permanently deleted.
                          <span class="text-danger">This operation can not be undone.</span>
                        </p>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">No</button>
                        <button type="button" class="btn btn-danger" (click)="modal.close('Ok click');del_contact(contact?.id)">Yes</button>
                      </div>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card-header text-center">
          <h5 class="text-muted">Email Information</h5>
          <span>
            <button type="button" (click)="add_email()" class="mybtn btn-primary text-muted">Add</button>
          </span>
        </div>

        <div class="content-container">
          <div class="table-responsive-sm table-container">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    {{(info?.emails?.length)? 'Email Addresses':'No Email Addresses Found' }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let email of info?.emails">
                  <td>{{ email.address }}</td>
                  <td style="width:160px">
                    <button type="button" class="mybtn btn-primary text-muted" (click)="edit_email(email)">Edit</button>
                    <button type="button" class="mybtn btn-primary text-muted" (click)="openDelete_email(delete_email)">Delete</button>
                    <ng-template #delete_email let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title" id="modal-title">Email Deletion</h4>
                        <button type="button" class="btn-close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')"></button>
                      </div>
                      <div class="modal-body">
                        <p><strong>Are you sure you want to delete <span class="text-primary">"Email"</span> ?</strong></p>
                        <p>
                          All information associated to this user profile will be permanently deleted.
                          <span class="text-danger">This operation can not be undone.</span>
                        </p>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">No</button>
                        <button type="button" class="btn btn-danger" (click)="modal.close('Ok click');del_email(email?.id)">Yes</button>
                      </div>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
    <!---------------Third Tab-------------------->
    <div class="card pt-5" *ngIf="current_page('third')">
      <div class="card-header text-center">
        <h5 class="text-muted">Identity/KYC</h5>
      </div>
      <div class="card-body ">
        <form>
          <div class="row">
            <div class="col">
              <div class="form-group col-md-12">
                <label class="p-1" for="inputState">Source of Income*</label>
                <div class="p-1 fw-bold">What would you classify as your primary source of income?</div>
                <select id="inputState" class="form-control">
                  <option selected>Salary</option>
                  <option></option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="form-group col-md-12">
                <label class="p-1" for="inputState">Account Funds*</label>
                <div class="p-1 fw-bold">Please select the source of funds from which your accounts will be funded.</div>
                <select id="inputState" class="form-control">
                  <option selected>Salary</option>
                  <option>little bit of this and that</option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <form>
          <div class="row">
            <div class="col">
              <div class="form-group col-md-12">
                <label class="p-1" for="inputState">Current Earnings Status*</label>
                <div class="p-1 fw-bold">What sector does your current earnings come from?</div>
                <select id="inputState" class="form-control">
                  <option selected>Private sector employment</option>
                  <option>little bit of this and that</option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="form-group col-md-12">
                <label class="p-1" for="inputState">Income Band*</label>
                <div class="p-1 fw-bold">Please select the income band which is applicable to your annual income.</div>
                <select id="inputState" class="form-control">
                  <option selected>189 881 - 296 540</option>
                  <option>little bit of this and that</option>
                </select>
              </div>
            </div>
          </div>
        </form>

        <div class="d-flex justify-content-center pt-5">
          <button type="button" class="btn btn-primary text-muted">Save & Proceed</button>
        </div>

      </div>
    </div>

    <!---------------Fourth Tab-------------------->
    <div class="card pt-5" *ngIf="current_page('fourth')">
      <div class="card-header text-center">
        <h5 class="text-muted">Tax Information</h5>
        <h6 class="text-muted pt-2 ">Why do we need this?</h6>
        <div class="display-7 col-lg-12 mx-auto px-0 pt-2 text-start ">
          We always like to make sure we (and you) are in line with global reporting requirements. New legislation means that we need to get a couple more details from you. We'd be super grateful if you could take a few minutes to complete the below. Thanks so much for helping us out and if you've got any concerns, please let us know by logging a ticket via our <a href=""> Help Centre.</a>
        </div>
      </div>
      <div class="card-body ">
        <div class="text-start  pt-3">
          <h5 class="text-muted">Tax Residence</h5>
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (click)="taxx()">
          <label class="form-check-label" for="flexCheckDefault">
            Are you registered for tax purposes?
          </label>
        </div>
        <div *ngIf="residence">
          <p class="text-muted pt-2">First up, we need to know where you pay tax.</p>
          <div class="text-muted">
            Please use the dropdown below to select the country where you pay it, enter your tax identification number and type for that country.
          </div>
          <div class="text-muted  pt-2">
            If you pay tax in more than one country, please add the additional details by clicking the '+ Add Another Tax Residence' button. Once you have clicked the button please supply the relevant country, corresponding tax identification number and type.
          </div>
          <form>
            <div class="row">
              <div class="col">
                <div class="form-group col-md-12">
                  <label class="p-1" for="inputState">Tax Identification Type*</label>
                  <select id="inputState" class="form-control">
                    <option selected>Please Select</option>
                    <option>little bit of this and that</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="form-group col-md-12">
                  <label class="p-1" for="inputState">What is your tax number? (TIN for US Citizens)*</label>
                  <input type="text" class="form-control" id="inputFirstName">
                </div>
              </div>
            </div>
          </form>
          <form>
            <div class="row">
              <div class="col">
                <div class="form-group col-md-12">
                  <label class="p-1" for="inputState">Tax Residence*</label>
                  <select id="inputState" class="form-control">
                    <option selected>Search....</option>
                    <option>little bit of this and that</option>
                  </select>
                </div>
              </div>
              <div class="col">

              </div>
            </div>
          </form>
          <form>
            <div class="row  pt-3">
              <div class="col">
                <div class="form-group col-md-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      Primary residence?
                    </label>
                  </div>
                </div>
              </div>
              <div class="col">
                <button type="button" class="btn btn-info  text-muted"> - Remove</button>
              </div>
            </div>
          </form>
          <form>
            <div class="row  pt-3">
              <div class="col">
                <button type="button" class="btn btn-info  text-muted"> + Add another Tax Residence</button>
              </div>
              <div class="col">

              </div>
            </div>
          </form>
        </div>
        <div *ngIf="residence2">
          <form class="pt-3">
            <div class="row">
              <div class="col">
                <div class="form-group col-md-12">
                  <label for="inputState">Reason</label>
                  <input type="text" class="form-control" id="inputFirstName">
                </div>
              </div>

            </div>
          </form>
        </div>
        <div class="d-flex justify-content-center pt-5">
          <button type="button" class="btn btn-primary text-muted">Save & Proceed</button>
        </div>
      </div>
    </div>

    <!---------------Firth Tab-------------------->
    <div class="card pt-5" *ngIf="current_page('fifth')">
      <div class="card-header text-center">
        <h5 class="text-muted">Bank Details</h5>
        <div class="display-7 col-sm-12 mx-auto text-start pt-3 pb-3">
          <div class="fw-bold">Why do we need this?</div>
          <div>Without your bank details, you won't be able to withdraw funds or set up a debit order.</div>
          <div><font class="fw-bold">Please read the guidelines further down the page </font>to avoid any unnecessary disruption to your investment activities.</div>
        </div>
      </div>
      <div class="card-body ">
        <div class="text-center">
          <h5 class="text-muted">Add Account</h5>
        </div>

        <div class="d-flex justify-content-center pt-1">
          <!----<button type="button" class="btn btn-primary text-muted">Add</button>--->
          <button type="button" (click)="add_bank()" class="mybtn btn-primary text-muted">
            Add
          </button>
        </div>

        <div class="content-container">
          <div class="table-responsive-sm table-container">
            <table class="table">
              <thead>
                <tr *ngIf="info?.banks?.length else noBanks">
                  <th>Bank Name</th>
                  <th>Branch</th>
                  <th>Branch Code</th>
                  <th>Account Type</th>
                  <th>Account No</th>
                  <th>Bank Type</th>
                  Account
                </tr>
                <ng-template #noBanks>
                  <tr>No Banks Found</tr>
                </ng-template>

              </thead>
              <tbody>
                <tr *ngFor="let bank of info?.banks">
                  <td>{{ get_from_lst(bank.name,banknames) }}</td>
                  <td>{{ bank.branchName }}</td>
                  <td> {{ bank.branch }}</td>
                  <td>{{ get_from_lst(bank.type,btypes) }}</td>
                  <td>{{ bank.account }}</td>
                  <td>{{ get_from_lst(bank.bankType,banktypes) }}</td>
                  <td>
                    <button type="button" class="mybtn btn-primary text-muted" (click)="edit_bank(bank)">Edit</button>
                    <button type="button" class="mybtn btn-primary text-muted" (click)="openDelete_bank(delete_bank)">Delete</button>
                    <ng-template #delete_bank let-modal>
                      <div class="modal-header">
                        <h4 class="modal-title" id="modal-title">Bank Deletion</h4>
                        <button type="button" class="btn-close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')"></button>
                      </div>
                      <div class="modal-body">
                        <p><strong>Are you sure you want to delete <span class="text-primary">"Bank"</span> ?</strong></p>
                        <p>
                          All information associated to this user profile will be permanently deleted.
                          <span class="text-danger">This operation can not be undone.</span>
                        </p>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">No</button>
                        <button type="button" class="btn btn-danger" (click)="modal.close('Ok click');del_bank(bank?.id)">Yes</button>
                      </div>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-body" *ngIf="is_pages(['address', 'email', 'contact', 'bank'])">
    <!----------------------First pop up------------------->
    <div class="card pt-5" *ngIf="current_page('address')">
      <div class="formWrapper">
        <div class="theForm">
          <form [formGroup]="addressForm" (ngSubmit)="save_address()">
            <div class="card-header text-center">
              <h5 class="text-muted">Address Information</h5>
            </div>
            <fieldset class="form-columns-2">
              <div class="row">
                <div class="col-md-6">
                  <label class="required">Street No</label>
                  <input type="number" class="form-control" id="streetNo" formControlName="streetNo" min="1" oninput="validity.valid||(value=value.replace(/\D+/g, ''))" required>
                  <div class="input-error" *ngIf="addressForm.controls.streetNo?.invalid && (addressForm.controls.streetNo?.dirty || addressForm.controls.streetNo?.touched)">
                    <div *ngIf="addressForm.controls.streetNo?.errors?.required">
                      Street No is required.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="required">Street Name</label>
                  <input type="text" class="form-control" id="streetName" formControlName="streetName" required>
                  <div class="input-error" *ngIf="addressForm.controls.streetName?.invalid && (addressForm.controls.streetName?.dirty || addressForm.controls.streetName?.touched)">
                    <div *ngIf="addressForm.controls.streetName?.errors?.required">
                      street Name is required.
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-columns-2">
              <div class="row">
                <div class="col-md-6">
                  <label>Building</label>
                  <input type="text" class="form-control" formControlName="building">
                </div>
                <div class="col-md-6">
                  <div class="ng-autocomplete">
                    <label class="required">City/Suburb</label>
                    <ng-autocomplete [data]="suburbs"
                                     [searchKeyword]="keyword"
                                     [itemTemplate]="itemTemplateStatic"
                                     [notFoundTemplate]="notFoundTemplate"
                                     formControlName="suburb"
                                     (inputChanged)='onChangeSearch($event)'
                                     (selected)='onSelected($event)'>
                    </ng-autocomplete>

                    <ng-template #itemTemplateStatic let-item>
                      <a [innerHTML]="item.value"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-columns-2">
              <div class="row">
                <div class="col-md-6">
                  <label class="required">Province</label>
                  <select class="form-control" formControlName="province">
                    <option *ngFor="let p of provinces" [ngValue]="p.id">
                      {{p.value}}
                    </option>
                  </select>
                  <div class="input-error" *ngIf="addressForm.controls.province?.invalid && (addressForm.controls.province?.dirty || addressForm.controls.province?.touched)">
                    <div *ngIf="addressForm.controls.province?.errors?.required">
                      province is required.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="required">Country</label>
                  <select class="form-control" formControlName="country">
                    <option *ngFor="let c of countries" [ngValue]="c.id">
                      {{c.value}}
                    </option>
                  </select>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-columns-2">
              <div class="row">
                <div class="col-md-6">
                  <label class="required">Postal Code</label>
                  <input type="number" class="form-control" id="postCode" formControlName="postCode">
                  <div class="input-error" *ngIf="addressForm.controls.postCode?.invalid && (addressForm.controls.postCode?.dirty || addressForm.controls.postCode?.touched)">
                    <div *ngIf="addressForm.controls.postCode?.errors?.required">
                      Postal code is required.
                    </div>
                    <div *ngIf="addressForm.controls.postCode?.errors?.pattern">
                      Postal code must be 4 digitals.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Type</label>
                  <select class="form-control" formControlName="type">
                    <option *ngFor="let t of addresstypes" [ngValue]="t.id">
                      {{t.value}}
                    </option>
                  </select>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-columns-2">
              <div class="col">
                <div class="form-group col-md-12">
                  <div class="form-check form-check-inline pt-3">
                    <input class="form-check-input" type="checkbox" formControlName="preferred">
                    <label class="form-check-label" for="preferred">Preferred</label>
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <div class="form-check form-check-inline pt-3">
                    <input class="form-check-input" type="checkbox" formControlName="dispatch">
                    <label class="form-check-label" for="dispatch">Dispatch</label>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="d-flex justify-content-center pt-5">
              <span style="padding-right:10px"><button type="button" class="hs-button btn-primary large" (click)="cancel('second')">Cancel</button> </span>
              <span><button type="submit" class="hs-button btn-primary large" [disabled]="!addressForm.dirty || !addressForm.valid || saving_address">Save</button></span>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!----------------------Second pop up------------------->
    <div class="card pt-5" *ngIf="current_page('email')">
      <div class="formWrapper">
        <div class="theForm">
          <form [formGroup]="emailForm" (ngSubmit)="save_email()">
            <div class="card-header text-center">
              <h5 class="text-muted">Email Information</h5>
            </div>
            <fieldset class="form-columns-2">
              <div class="row">
                <div class="col-md-6">
                  <label class="required">Email</label>
                  <input class="form-control" id="Eaddress" type="text" formControlName="address" />
                  <div class="input-error" *ngIf="emailForm.controls.address?.invalid && (emailForm.controls.address?.dirty || emailForm.controls.address?.touched)">
                    <div *ngIf="emailForm.controls.address?.errors?.required">
                      Email address required.
                    </div>
                    <div *ngIf="emailForm.controls.address?.errors?.pattern">
                      Format does not match that of an email (example test@gmail.com)
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label>Type</label>
                  <select class="form-control" formControlName="type">
                    <option *ngFor="let t of addresstypes" [ngValue]="t.id">
                      {{t.value}}
                    </option>
                  </select>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-columns-2">
              <div class="col">
                <div class="form-group col-md-12">
                  <div class="form-check form-check-inline pt-3">
                    <input class="form-check-input" type="checkbox" formControlName="preferred" />
                    <label class="form-check-label" for="preferred">Preferred</label>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="d-flex justify-content-center pt-5">
              <span style="padding-right:10px"><button type="button" class="hs-button btn-primary large" (click)="cancel('second')">Cancel</button> </span>
              <span><button type="submit" class="hs-button btn-primary large" [disabled]="!emailForm.dirty || !emailForm.valid || saving_email">Save</button></span>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!----------------------Third pop up------------------->
    <div class="card pt-5" *ngIf="current_page('contact')">
      <div class="formWrapper">
        <div class="theForm">
          <form [formGroup]="contactForm" (ngSubmit)="save_contact()">
            <div class="card-header text-center">
              <h5 class="text-muted">Contact Information</h5>
            </div>
            <fieldset class="form-columns-2">
              <div class="row">
                <div class="col-md-6">
                  <label>Country Calling Code For Mobile</label>
                  <select class="form-control">
                    <option></option>
                    <option selected>SA +27</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label class="required">Mobile Number</label>
                  <input type="text" class="form-control" formControlName="number">
                  <div class="input-error" *ngIf="contactForm.controls.number?.invalid && (contactForm.controls.number?.dirty || contactForm.controls.number?.touched)">
                    <div *ngIf="contactForm.controls.number.errors.required">
                      Mobile Number is required.
                    </div>
                    <div *ngIf="contactForm.controls.number.errors.pattern">
                      Enter a 10 digit RSA Cellphone number.
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-columns-2">
              <div class="row">
                <div class="col-md-6">
                  <label>Type</label>
                  <select class="form-control" formControlName="type">
                    <option *ngFor="let t of addresstypes" [ngValue]="t.id">
                      {{t.value}}
                    </option>
                  </select>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-columns-2">
              <div class="col">
                <div class="form-group col-md-12">
                  <div class="form-check form-check-inline pt-3">
                    <input class="form-check-input" type="checkbox" formControlName="preferred">
                    <label class="form-check-label" for="preferred">Preferred</label>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="d-flex justify-content-center pt-5">
              <span style="padding-right:10px"><button type="button" class="hs-button btn-primary large" (click)="cancel('second')">Cancel</button> </span>
              <span><button type="submit" class="hs-button btn-primary large" [disabled]="!contactForm.dirty || !contactForm.valid || saving_contact">Save</button></span>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!----------------------Fourth pop up------------------->
    <div class="card pt-5" *ngIf="current_page('bank')">
      <div class="formWrapper">
        <div class="theForm">
          <form [formGroup]="bankForm" (ngSubmit)="save_bank()">
            <div class="card-header text-center">
              <h5 class="col-md-12 text-muted">Account Information</h5>
            </div>
            <fieldset class="form-columns-2">
              <div class="row">
                <div class="col-md-6">
                  <label class="required">Bank Name</label>
                  <select class="form-control" formControlName="name">
                    <option *ngFor="let b of banknames" [ngValue]="b.id">
                      {{b.value}}
                    </option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label class="required">Branch Name</label>
                  <input type="text" class="form-control" id="branchName" formControlName="branchName">
                </div>
              </div>
            </fieldset>
            <fieldset class="form-columns-2">
              <div class="row">
                <div class="col-md-6">
                  <label class="required">Branch Code</label>
                  <input type="text" class="form-control" id="branch" formControlName="branch" required>
                  <div class="input-error" *ngIf="bankForm.controls.branch?.invalid && (bankForm.controls.branch?.dirty || bankForm.controls.branch?.touched)">
                    <div *ngIf="bankForm.controls.branch?.errors?.required">
                      Branch Code is required.
                    </div>
                    <div *ngIf="bankForm.controls.branch?.errors?.pattern">
                      Branch Number must be 6 digits.
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="required">Account Number</label>
                  <input type="text" maxlength="10" class="form-control" id="account" formControlName="account" required>
                  <div class="input-error" *ngIf="bankForm.controls.account?.invalid && (bankForm.controls.account?.dirty || bankForm.controls.account?.touched)">
                    <div *ngIf="bankForm.controls.account?.errors?.required">
                      Account Number is required.
                    </div>
                    <div *ngIf="bankForm.controls.account?.errors?.pattern">
                      Account Number must be 10 digits.
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-columns-2">
              <div class="row">
                <div class="col-md-6">
                  <label class="required">Account Type</label>
                  <select class="form-control" formControlName="type">
                    <option *ngFor="let a of btypes" [ngValue]="a.id">
                      {{a.value}}
                    </option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label class="required">Bank Type</label>
                  <select class="form-control" formControlName="bankType">
                    <option *ngFor="let k of banktypes" [ngValue]="k.id">
                      {{k.value}}
                    </option>
                  </select>
                </div>
              </div>
            </fieldset>
            <fieldset class="form-columns-2">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-check form-check-inline pt-3">
                    <input class="form-check-input" type="checkbox" formControlName="verified">
                    <label class="form-check-label" for="verified">Verified</label>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="row">
              <div class="col-md-3"></div>
              <div class="col-md-3">
                <span><button type="button" class="hs-button btn-primary large" (click)="cancel('fifth')">Cancel</button> </span>
              </div>
              <div class="col-md-3">
                <span><button type="submit" class="hs-button btn-primary large" [disabled]="!bankForm.dirty || !bankForm.valid || saving_bank">Save</button></span>
              </div>
              <div class="col-md-3"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>





