import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { getProperties } from '../state/properties/properties.actions';
import { getProps } from '../state/properties/properties.selectors';

@Component({
  selector: 'app-browse-properties',
  templateUrl: './browse-properties.component.html',
  styleUrls: ['./browse-properties.component.css']
})
export class BrowsePropertiesComponent implements OnInit {

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    const us = this.store.select(getProps).subscribe(x => {
      if (!x) {
        this.store.dispatch(getProperties());
      } else {
        us.unsubscribe();
      }
    });
  }

  properties$ = this.store.select(getProps);

  progress(balance, target) {
    return balance / target;
  }

  setProgressStyle(progress) {
    var progressStyle: Record<string, any> = {};

    progressStyle = {
      'background-color': Math.floor(progress * 100) / 100 < 1 ? '#005bff' : '#31ff00',
      'width': Math.floor(progress) < 1 ? Math.floor(progress*100) + '%' : '100%'
    };

    return progressStyle;
  }

}

