<ng-template #login let-modal>
  <app-login (close_model)="close_login($event)"></app-login>
</ng-template>

<ng-template #register>
  <app-register (close_model)="close_register($event)"></app-register>
</ng-template>

<div id="container" class="main-content-section">
  <div id="#site-content" class="full-page">
    <div class="tab">
      <div class="sect-1">
        <div class="sec">
          <button class="tab-button" (click)="p1('first','')">Home</button>
          <div class="dropdown">
            <button class="btn tab-button" (click)="p1('second','')">
              About ERA
            </button>
            <div class="dropdown-menu">
              
              <a class="dropdown-item" (click)="p1('second','.governance')">Governance </a>
              <a class="dropdown-item" (click)="p1('second','.external-governance')">External Governance</a>
              <a class="dropdown-item" (click)="p1('second','.current-membership')">Current Membership Base</a>
              <a class="dropdown-item" (click)="p1('second','.epr-stakeholders')">EPR Stakeholders</a>
              <a class="dropdown-item" (click)="p1('second','.associations')">Associations</a>
            </div>
          </div>
        </div>
        <div class="sec">
          <div class="dropdown">
            <button class="btn tab-button" (click)="p1('third','')">
              About ERA's WEEE EPR Scheme
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" (click)="p1('third','.epr-scheme')">ERA WEEE EPR Scheme</a>
            </div>
          </div>
        </div>
      </div>
      <div class="sect-1">
        <div class="sec">
          <button class="tablinks" (click)="p1('fourth','')">Producer Portal</button>
        </div>
        <div class="sec">
          <button class="tablinks" (click)="p1('fifth','')">Service Provider Portal</button>
        </div>
        <div class="sec">
          <button class="tablinks" (click)="p1('seventh','')">Terminology</button>
        </div>
        <div class="sec">
          <button class="tablinks" (click)="p1('eighth','')">Articles and Events </button>
        </div>
      </div>
    </div>


    <div class="page-body" *ngIf="fullpage">
      <!--P1 Home-->
      <div *ngIf="first || is_first_load() || get_recent() == 'first'">

        
          <ngx-slick-carousel #slickIpoModal="slick-carousel" [config]="ipoSlideConfig" class="carousel" *ngIf="images3">
            <div ngxSlickItem *ngFor="let img of images3; index as i" class="grid-card">
              <div class="container-carousel">
                <img [src]="img" alt="" style="width:100%;height:350px;">
              </div>
              <div class="slick-text">
                <h1 class="a-slick-text">Get Your E-waste Recycled</h1>
              </div>
            </div>
        </ngx-slick-carousel>

        <div class="container4 gap1">
          <div class="row">
            <div class="col-sm-12 centering">
              <h1 class="head-head-text">Dear Visitor</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="centering1 ">
                Welcome to the ERA NPC PRO website.
              </h3>

              <p> This website aims to disseminate information on South Africa’s Waste Electronic and Electrical Equipment (WEEE) Extended Producer Responsibility (EPR) Regulations and related developments in South Africa.</p>
              <p>  It hosts ERA NPC’s primary communications and transactional platform as it relates to ERA’s work as a Producer Responsibility Organisation (PRO) and in which all official ERA transactions and reports are securely hosted.</p>
              <p> It aims to serve all Stakeholders, from ERA Members, EEE Producers, WEEE Collection and Recycling Service Providers, Government and the Public.</p>

            </div>
          </div>
          <div class="row gap">
            <div class="col-sm-12 head-container">
              <h1 class="head-text">Background to WEEE EPR Regulations and ERA NPC</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">

              <p >The National Environmental Management Waste Act (NEMWA) No. 59/2008 makes provision for the implementation of EPR programmes in South Africa.</p>
              <ol>
                <li class="list">In October 2014, the Department of Forestry Fisheries and Environment (DFFE) signalled that EPR will be implemented in accordance with Section 28 of the NEMWA, and in 2017 a Notice was published for the WEEE sector to prepare and submit Industry Waste Management Plans (IndWMP) for approval and funding by government via an e-waste levy. Following a year-long hiatus, the current Minister of the DFFE withdrew the Section 28 Notice and DFFE signalled that government would be seeking to develop and implement EPR.Regulations developed in terms of Section 18 of the NEMWA.</li>
                <li class="list">In November 2020, the DFFE Minister issued EPR Regulations (currently applicable for WEEE, lighting waste and paper and packaging waste) and specific Notices for each of these waste streams. The WEEE notice includes WEEE specific details including the envisaged collection and recycling targets over the next five years.</li>
                <li class="list">In December 2020, the Minister convened a Task Team of all sectors affected by the EPR Regulations. This industry led task team worked on formulating a set of proposed amendments aimed at making the EPR Regulations unambiguous and more implementable. The DFFE Minister also extended the implementation deadline for the EPR Regulations and WEEE Notice from 5 November 2020 to 5 May 2021.</li>
                <li class="list">The amended Regulations and WEEE Notice were published and became effective 5 May 2021 with a deadline for EEE Producers to be fully compliant with the EPR Regulations by 5 November 2021.</li>
              </ol>

            </div>
          </div>
          <!--<div class="row" style="margin-top:50px;">
            <div class="col-sm-12">
              <p>Timeline of ERA engagement with WEEE EPR Process</p>
            </div>
          </div>-->
          <!--<div class="row">
            <div class="col-sm-12">
              <div>
                <img src="url(assets/era/10 timeline-table.jpg)" style="width:100%">
              </div>
            </div>
          </div>-->
          <div class="row gap1">
            <div class="col-sm-12" style="display: flex;justify-content: space-evenly;">
              <a href="assets/era/CONSOLIDATED REGS FINAL May21.pdf" target="_blank" download>
                <div class="circle" style="text-align: center;">
                  <img src="assets/logo/axn07-y2aoq.svg" alt="Responsive image" class="img-fluid-logo3" [routerLink]="['/']" />
                </div>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12" style="display: flex;justify-content: space-evenly;color: #69a84f;">
              <div class="consolodate">Consolidated EPR Regulations</div>
            </div>
          </div>
          <div class="row gap1">
            <div class="col-sm-12 head-container">
              <h3 class="head-text">Essential Summary of WEEE EPR Regulations</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">

              <p class="paragraph">All existing importers and producers of EEE, or their assigned agents, are required by the EPR regulations to do the following:</p>
              <ol type="a">
                <li class="list">) Register as an EEE Producer with DFFE between 5 May and 5 November 2021. <a style="color:#69a84f" href="http://sawic.environment.gov.za/epr/">http://sawic.environment.gov.za/epr/</a></li>
                <li class="list">) Develop and implement a WEEE EPR Scheme or join an existing WEEE EPR Scheme as from 5 May 2021, but no later than 5 November 2021.</li>
                <li class="list">) All EPR Schemes must be registered with DFFE and performance on a range of targets specified in the EPR Regulations and WEEE Notice must be reported to DFFE.</li>
                <li class="list">) Penalties for non-compliance are specified in the EPR Regulations and WEEE Notice</li>
              </ol>

            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12 head-container">
              <h1 class="head-text">ERA’s Statutory Status</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>ERA E-waste Recycling Authority (ERA) is a registered Non-profit Company (NPC)<p>
              <p>CIPC Registration Number - 2018/248881/08 </p>
              <p>ERA NPC is registered as a Producer Responsibility Organisation (PRO) with the Department of Forestry, Fisheries and Environment (DFFE) in terms of Section 18 of the National Environmental Management: Waste Act (NEMWA) N0. 59/2008.</p>
              <p>DFFE PRO Registration Number 19/7/6/E/PRO/202106009/002</p>
            </div>
          </div>

          <!--<div class="row gap">
            <div class="col-sm-12" style="display: flex;justify-content: space-evenly;margin-bottom: 10px;">
              <div class="circle" style="text-align: center;">
                <i class="fa  fa-3x fa-download circle-icon" aria-hidden="true"></i>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12" style="display: flex;justify-content: space-evenly;color: #69a84f;">
              <div style="text-align: center;">Essential Summary of EPR Regulations</div>
            </div>
          </div>-->
          <div class="row gap1">
            <div class="col-sm-12 head-container">
              <h1 class="head-text">ERA Goals and Objectives</h1>
            </div>
          </div>
          <div class="row gap">
            <div class="col-sm-12">
              <h3 class="sub-heading">Vision Statement</h3>
              <p>ERA is to ensure that we help Producers’ meet their EPR obligations in a simple, effective, cost-effective and measurable way – as efficiently as possible.</p>
              <h3 class="sub-heading gap1">Mission Statement</h3>
              <p>
                To establish a sustainable and fair WEEE collection and recycling system that works to meet Producers’ environmental compliance obligations in a manner that meets our collective aspirations for our country, planet, and people.<br />
                Effective governance and the growth of a sustainable and effective WEEE management system in South Africa
              </p>
            </div>
          </div>



          <!--<div class="row gap">
            <div class="col-sm-12" style="display: flex;justify-content: space-evenly;margin-bottom: 10px;">
              <a href="https://www.surveymonkey.com/r/BK5WQVR" target="_blank" rel="noopener noreferrer">
                <div class="circle" style="text-align: center;">
                  <i class="fa fa-3x fa-calendar circle-icon" aria-hidden="true"></i>
                </div>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12" style="display: flex;justify-content: space-evenly;color: #69a84f;">
              <div style="text-align: center;">Use this form to request more info about ERA</div>
            </div>
          </div>-->
        </div>

      </div>
      <!--P2 About ERA-->
      <div *ngIf="get_recent() == 'second'">

        <ngx-slick-carousel #slickIpoModal="slick-carousel" [config]="ipoSlideConfig" class="carousel" *ngIf="images">
          <div ngxSlickItem *ngFor="let img of images; index as i" class="grid-card">

            <div class="container-carousel">
              <img [src]="img" alt="" style="width:100%;height:350px;">
            </div>
            <div class="slick-text ">
              <h1 class="a-slick-text">Learn more about ERA here</h1>
            </div>
          </div>

        </ngx-slick-carousel>

        <div class="container4 gap1 governance">
          <div class="row">
            <div class="col-sm-12 head-container">
              <h1 class="head-text">Governance</h1>
            </div>
            <div class="col-sm-12">
              <p>The ERA Memorandum of Incorporation makes provision for 10 Board members. There are 5 Board members, with the remainder of the Board positions reserved for further sectors we recruit to ERA NPC stable.<br /></p>
            </div>
          </div>
          <div class="row gap1">
            <div class="col-sm-12 ">
              <h3 class="sub-heading">
                Our Current Board Comprises
              </h3>
            </div>
          </div>
          <div class="row gap1">
            <div class="col-sm-12 mt-3">
              <table class="T1" style="width:55%;margin:0 auto;align-content:center">
                <tr>
                  <th class="th1" style="width:10%"><span class="span1">Board Member</span></th>
                  <th class="th1" style="width:17%"><span class="span1">Credentials</span></th>
                  <th class="th1" style="width:10%"><span class="span1">Position</span></th>
                </tr>
                <tr>
                  <td>M Bagriam</td>
                  <td>Director: Bagriam Attorneys</td>
                  <td>Chairperson</td>
                </tr>
                <tr>
                  <td>A du Plooy</td>
                  <td>Ex officio CEO ERA NPC</td>
                  <td>Excutive Director</td>
                </tr>
                <tr>
                  <td>J Parkin</td>
                  <td>Chief Commercial Officer: Alviva <br />Holdings</td>
                  <td>Non-Excutive Director</td>
                </tr>
                <tr>
                  <td>J Mthethwa</td>
                  <td>Director Corporate Services: Defy <br />Appliance South Africa</td>
                  <td>Non-Excutive Director</td>
                </tr>
                <tr>
                  <td>M Saunders</td>
                  <td>CEo Domestic: Appliances<br />Association</td>
                  <td>Non-Excutive Director</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12">
              <h3 class="sub-heading">
                Sub Committees
              </h3>
              <p>ERA has in accordance with king IV and Regulatory framework established the subcommittees for the development and oversight of critical policy areas to ensure proper governace and management thereof.<br /></p>
              <p>The current subcommittees are:</p>
              <ul>
                <li class="list">Technical Committee</li>
                <li class="list">Social, Transformation and Remuneration</li>
                <li class="list">Governance and Risk</li>
              </ul>
            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12 ">
              <h3 class="sub-heading2">
                Statutory Status
              </h3>
            </div>

          </div>

          <div class="row gap1">
            <div class="col-sm-12">
              <table class="T2" style="width:55%;margin:0 auto;align-content:center">
                <tr>
                  <th class="th1" colspan="2">DFFE PRO Registration Numbers</th>
                </tr>
                <tr>
                  <td style="width: 50%;">WEEE Registration Number</td>
                  <td>19/7/6/E/PRO/20210609/002</td>
                </tr>
                <tr>
                  <td>Lighting</td>
                  <td>19/7/7/L/PRO/20211115/003</td>
                </tr>
                <tr>
                  <td>Paper and Packaging</td>
                  <td>19/7/5/P/PRO/20211115/020</td>
                </tr>
                <tr>
                  <td>Business Plan Concurrence</td>
                  <td>Business Plan submitted awaiting concurrence</td>
                </tr>
                <tr style="height: 45px;">
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th class="th1" colspan="2">CIPC Registration Numbers</th>
                </tr>
                <tr>
                  <td>NPC/Company Registration Number</td>
                  <td>2018/248881/08</td>
                </tr>
                <tr style="height: 45px;">
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th class="th1" colspan="2">South African Revenue Services</th>
                </tr>
                <tr>
                  <td>Income Tax</td>
                  <td>9917670169</td>
                </tr>
                <tr>
                  <td>Vat Registration Number</td>
                  <td>4330304884</td>
                </tr>
                <tr>
                  <td>PAYE Registration Number</td>
                  <td>7960819789</td>
                </tr>
                <tr>
                  <td>Skills Development Levy</td>
                  <td>L960819789</td>
                </tr>
                <tr>
                  <td>UIF</td>
                  <td>U960819789</td>
                </tr>
              </table>
            </div>
          </div>


          <div class="row gap external-governance">
            <div class="col-sm-12 head-container">
              <h1 class="head-text">External Governance</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 ">
              <p>In maintaining and forging a culture of strong governance within ERA NPC, ERA has made extensive use of external professionals to provide the necessary<br /> checks and balances:</p>
            </div>

          </div>

          <div class="row gap1">
            <div class="col-sm-12 mt-3">
              <table class="T2" style="width: 55%; margin: 0 auto; align-content: center">
                <tr>
                  <td style="width: 50%;">Bankers</td>
                  <td>Standard Bank of South Africa Ltd</td>
                </tr>
                <tr>
                  <td>Accountants</td>
                  <td>Nexia SAB & T</td>
                </tr>
                <tr>
                  <td>Internal Auditors</td>
                  <td>SNG Grant Thornton</td>
                </tr>
                <tr>
                  <td>External Auditors</td>
                  <td>Meridian Chartered Accountants</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row gap current-membership">
            <div class="col-sm-12 head-container">
              <h1 class="head-text">Current Membership Base</h1>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 ">
              <p>Our current membership base comprises</p>
            </div>
            <div class="col-sm-12 mt-3">
            </div>
          </div>

          <div class="row gap1">
            <table class="T2" style="width: 55%; margin: 0 auto; align-content: center">
              <tr>
                <td>Defy Appliances South Africa</td>
                <td>Major Tech</td>
              </tr>
              <tr>
                <td>Cisco</td>
                <td>Schneider Electric</td>
              </tr>
              <tr>
                <td>Isuzu</td>
                <td>Datacentrix</td>
              </tr>
              <tr>
                <td>Oracle</td>
                <td>Tarsus</td>
              </tr>
              <tr>
                <td>Nestle</td>
                <td>DG Stores</td>
              </tr>
              <tr>
                <td>NSE South Africa t/a Nu Skin</td>
                <td>Solar Africa</td>
              </tr>
              <tr>
                <td>Solareff</td>
                <td>Daikin</td>
              </tr>
              <tr>
                <td>Pinnacle Micro</td>
                <td>Sithabile</td>
              </tr>
              <tr>
                <td>Smeg</td>
                <td>Asbis</td>
              </tr>
              <tr>
                <td>Axiz</td>
                <td>Vega</td>
              </tr>
              <tr>
                <td>Miele</td>
                <td>BSH Home Appliances</td>
              </tr>
              <tr>
                <td>HP South Africa</td>
                <td>Phillips Domestic Appliances</td>
              </tr>
              <tr>
                <td>Obscure Technologies</td>
                <td>Kemtek Imaging Systems</td>
              </tr>
              <tr>
                <td>HPE</td>
                <td>Huawei</td>
              </tr>
              <tr>
                <td>Mustek</td>
                <td>Inhep Electronic Holdings</td>
              </tr>
              <tr>
                <td>tevo</td>
                <td>Liebherr</td>
              </tr>
              <tr>
                <td>Rectron</td>
                <td>Brother International</td>
              </tr>
              <tr>
                <td>Dell South Africa</td>
                <td>Baker Hughes</td>
              </tr>
              <tr>
                <td>Roche Diagnostics</td>
                <td>IBM</td>
              </tr>
              <tr>
                <td>Massmart</td>
                <td></td>
              </tr>
            </table>
          </div>



          <div class="row gap epr-stakeholders">
            <div class="col-sm-12 head-container">
              <h1 class="head-text">EPR Stakeholders</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 ">
              <p>ERA NPC believes the following diagram best describes the stakeholder relationship within the confines of a Producer Responsibilty framework:</p>

            </div>
          </div>
          <div class="row gap1">
            <div class="col-sm-12" style="text-align: center">
              <img src="/assets/home/EPR-Stakeholders-diagram.png" class="responsive" width="600" height="600" />
            </div>
          </div>
          <div class="row gap associations">
            <div class="col-sm-12 head-container">
              <h1 class="head-text">Associations</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 ">
              <p>ERA is a registered member of the Global WEE Forum. Through this membership ERA has access to and can draw on the experience,  expertise and best practices of the forum's 43 member PROs operating across all continents.</p>
              <p>The WEE Forum has provide very handsome practical and strategic support in ERA's start-up phase and will continue to be a source of good practice and support as ERA EPR scheme matures.</p>
            </div>
          </div>

        </div>
        </div>
      <!--P2-1 ERA Members-->
      <div *ngIf="get_recent() == 'second_1'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-12 ">
              <span>You are here: </span> <i (click)="p1('first')" class="fa fa-home" aria-hidden="true"></i><span (click)="p1('second')" style="color: #69a84f;cursor: pointer;"> > About</span> > ERA Members
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12">
              <h1 class="sow-headline font-headlines">
                ERA Members
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 box">
              <div class="siteorigin-widget-tinymce textwidget">
                <p class="paragraph">ERA NPC has its origins in an Ewaste Steering Team that was selected from among approximately 120 delegates who met at the DFFE Consultative Conference held on 29 October 2014. The Steering Committee included the following persons:</p>
                <ol>
                  <li>Ashley du Plooy (Interim Chair) – Active</li>
                  <li>Allan Werth (Sims, WeCare and Recyclex) – resigned August 2021</li>
                  <li>Jonathan de Vries (E-Compliance and R2E2) – resigned August 2021</li>
                  <li>Jonathan Perry (Dell and ITA) – Active</li>
                  <li>Ruben Janse van Rensburg (HP and ITA) – Active</li>
                  <li>Mark Saunders (SADA) – Active</li>
                  <li>Susanne Karcher (SAEWA) – Resigned April 2021</li>
                  <li>Pravashen Naidoo (E-Waste Africa) – Resigned August 2021</li>
                  <li>Malcolm Whitehouse replaced by Jan Barnard (DESCO) – Resigned August 2021</li>
                  <li>Andrejz Tuleja (Whirlpool) – Stood down in 2016</li>
                  <li>Alex Haw (Massmart) – Stood down in 2016</li>
                  <li>Dion Smit (SA Precious Metals) – Stood down in 2017</li>
                  <li>Keith Anderson (EWASA) – Resigned in June 2015</li>
                  <li>Ulze van Wyk – Stood down after September 2018</li>
                  <li>Anben Pillay – Stood down in March 2015</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12">
              <h1 class="sow-headline font-headlines">
                Membership Facts
              </h1>
              <h4 class="sow-headline font-headlines">
                Producers
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 box">
              <ul>
                <li>South African Domestic Appliances Association (SADA) represents manufacturers and Importers of large and small appliances in South Africa.&nbsp; It comprises eight member companies and might account for between 60% and 70% of domestic appliances on the market</li>
                <li>Information Technology Association (ITA) represents manufacturers and importers of Information and Communication Technology in South Africa.&nbsp; It comprises over two hundred members and accounts for around 40%-50% of ICT on the market.</li>
              </ul>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-12">
              <h4 class="sow-headline font-headlines">
                Recyclers
              </h4>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 box">
              <p style="padding-left: 40px;">ERA, over the 7-year period that it has been engaged in the research, policy formulation and planning of the WEEE EPR Scheme, has benefited from a co-operative working relationship with the two largest WEEE Recycling companies in South Africa viz. Sims (now Recyclex) and Desco. Together with smaller WEEE recyclers represented by the now-defunct SAEWA (an alliance of twenty-one recyclers supporting emerging WEEE enterprises), Africa E-Waste, E-Waste Africa and others, account for more than 50% of WEEE collection and recycling.</p>
              <p style="padding-left: 40px;">ERA made a policy decision, after the publication of the EPR Regulations, to engage all WEEE Service Providers (collectors, transporters and recyclers) services through an open Request for Quotations (RFQ) process and to which it will apply consistent criteria for the selection of ERA Service Providers.</p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12">
              <h1 class="sow-headline font-headlines">
                Benefits of being an ERA Member
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 box">
              <ul>
                <li>ERAis a multi-stakeholder Producer Responsibility Organisation formed to undertake individual EEE companies’ EPR responsibilities on their behalf.</li>
                <li>ERA was mandated by the WEEE sector at a consultative conference held on 29 October 2014 to draft an Industry Waste Management Plan for the WEEE sector.</li>
                <li>ERA has engaged the Department of Fisheries Forestry and Environment, extensively over the last 7 years in the formulation of a nation-wide WEEE Management Plan.</li>
                <li>ERA implemented the largest and most serious national consultative process in the formulation of the IndWMP which it submitted to government in 2018. This process is being repeated form the ERA WEEE EPR Scheme</li>
                <li>ERA membership currently comprises companies and associations that represent 80%+ of the domestic appliances on the SA market, 50%+ of the ITC goods on the SA market, as well as 70%+ of the county’s e-Waste recycling capacity.</li>
                <li>The six years of engagement between ERA’s producers and recyclers means that ERA has a collective track record and depth and breadth of knowledge and experience that very few, if any, individual corporations can match.</li>
                <li>ERA is a true Non-profit company which remains open to your inclusion and participation in shaping of the EPR regime for South Africa.</li>
                <li>Due size of ERA’s membership already, it is well positioned to implement an EPR Scheme that is effective and cost-effective in terms of you meeting your legal EPR obligations.</li>
                <li>ERA is currently part of the DEFF Task Force charged with co-opting feedback regarding the EPR Regulations and WEEE Notice into an amended set of Notices and Regulations – towards the objective of extracting the more onerous demands placed on EEE companies.&nbsp; To this end we are working on getting the current 6-month deadline for compliance (5 May 2021) extended by another 6 months.</li>
              </ul>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12">
              <h1 class="sow-headline font-headlines">
                Nomination for 2021 ERA Inaugural Board
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="col-sm-12 box">
                <ul>
                  <li><em>This form needs to be completed for each and every nomination made</em></li>
                  <li><em>Nominations can be from Producer Members and general members of the public</em></li>
                </ul>
              </div>
              <p><strong style="color: #808080;">Board Composition</strong></p>
              <div class="col-sm-12 box">
                <p>There will be 10 duly selected Board of Directors as follows:</p>
                <ul>
                  <li>1x Executive Director (ex-officio)</li>
                  <li>
                    6x Non-executive Directors comprising EEE stakeholder representatives from the following sectors:
                    <ul>
                      <li>ICT</li>
                      <li>Domestic Appliances</li>
                      <li>Retailers</li>
                      <li>Other</li>
                    </ul>
                  </li>
                  <li>3x Independent Non-executive Directors</li>
                </ul>
              </div>
              <p><span style="color: #808080;"><strong>Board Term</strong></span></p>
              <div class="col-sm-12 box">
                <ul>
                  <li>Two years</li>
                  <li>Terms for directors effective on 15 October 2021</li>
                </ul>
              </div>
              <p><strong style="color: #808080;">Board Selection</strong></p>
              <div class="col-sm-12 box">
                <p>The existing ERA Board of Directors and the Advisory Board (based on 7 years of engagement in the WEEE EPR process), will select the Inaugural Board of Directors from among nominees to establish competent governing capacity for the WEEE EPR Scheme.</p>
                <p>The Inaugural Board will determine by consensus and/or by a simple majority vote, all assigned board roles.</p>
                <p><span style="color: #808080;"><strong>Desired competencies and backgrounds for nominees</strong></span></p>
                <p>ERA NPC seeks to foster equitable and representative involvement of a diverse base of people on its Board. We thus envisage the composition of the Board to represent:</p>
                <ul>
                  <li>Industry experience</li>
                  <li>Diversity of background</li>
                  <li>Specialist expertise</li>
                  <li>‘Race’ and gender diversity</li>
                </ul>
              </div>
              <p><span style="color: #808080;"><strong>Board Selection Timeline</strong></span></p>
              <div class="col-sm-12 box">
                <ul>
                  <li><span style="color: #000000;">30 September: Deadline for nominations</span></li>
                  <li><span style="color: #000000;">1 October: Final list of candidates published</span></li>
                  <li><span style="color: #000000;">8 October: Nominee communication -Selection confirmation</span></li>
                  <li><span style="color: #000000;">15 October: Board confirmed and duly constituted</span></li>
                </ul>
              </div>
              <p><span style="color: #808080;"><strong>Board Meetings and High-level Terms</strong></span></p>
              <div class="col-sm-12 box">
                <ul>
                  <li>The Inaugural Board of Directors first order of business will be to elect a Board Chairperson. Unless for good and valid reason, all Board members are expected to attend at least the 4 quarterly Board meetings per annum, including the Annual General Meeting to be convened in March annually</li>
                  <li>Unless for good and valid reasons, Board members should serve and/or co-opt others to serve on Board sub-committees.</li>
                  <li>Travel and Board meeting expenses will be reimbursed</li>
                  <li>All Directors of the Board who are not paid for Board meetings that they attend will be eligible to payment of a stipend as determined by the Inaugural Board.</li>
                  <li>All Directors of the Board must sign a Declaration of Interest/s</li>
                </ul>
              </div>
              <p><span style="color: #808080;"><strong>Nomination Documentation Required and Deadlines</strong></span></p>
              <div class="col-sm-12 box">
                <p>The submission, on/before <strong>22 October 2021</strong> of the following:</p>
                <ol>
                  <li><em>Fill</em> in the <strong>Nomination Form </strong><em>below</em></li>
                  <li><em>Email</em> copy<em> of</em> <strong>CV/Resume</strong> to <i style="color: #69a84f" class="fa fa-envelope" aria-hidden="true"></i> <a style="color: #69a84f" href="mailto:erainfo@eranpc.%63o.%7aa">erainfo@eranpc.co.za</a><u></u></li>
                  <li><em>Email</em> <strong>Acceptance of Nomination</strong> to <i style="color: #69a84f" class="fa fa-envelope" aria-hidden="true"></i> <a style="color: #69a84f" href="mailto:erainfo@eranpc.%63o.%7aa">erainfo@eranpc.co.za</a> or complete in <em>Nomination Form below</em></li>
                </ol>
              </div>
            </div>
          </div>
          <div class="row mt-3" style="justify-content:center">
            <div class="col-sm-12" style="border:1px solid;width:fit-content;justify-content:center">
              <img src="url(assets/era/12 Ewaste-logo-300x150.png)" alt="Alternate Text" />
              <p style="color: #69a84f;text-align:center">Nominee Registration ERA <br /> Inaugural Board 2021</p>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12">
              <h1 class="sow-headline font-headlines">
                ERA Funding
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 box">
              <p>ERA has functioned on very substantial voluntary contributions from its members over the last seven years.  As the EPR legal environment has come into force, extensive work is required to shape the final ERA WEEE EPR Scheme.  It requires the deployment of more focused and committed personnel and resources.  We calculate that the collective voluntary contributions these last 7 years amounted to multiples of millions of ZAR in personnel and direct costs.  The funds required to operationalise the ERA WEEE EPR Scheme comes from the ERA joining (membership) fee, as well as advances made by members which will be repaid and attract levy discounts as determined by the ERA Board of Directors.</p>
              <p>Ongoing funding will be secured from the EPR levies due from Producers.</p>
              <p>Achieving a large Producer membership base remains a critical objective as this will enable ERA to function at scale to ensure that you meet your EPR obligations in a simple, effective, cost-effective and measurable way – as efficiently as possible.</p>
            </div>
          </div>
        </div>
      </div>
      <!--P2-2 ERA Goals and Objectives-->
      <div *ngIf="get_recent() == 'second_2'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-12 yah">
              <span>You are here: </span> <i (click)="p1('first')" class="fa fa-home" aria-hidden="true"></i><span (click)="p1('second')" style="color: #69a84f;cursor: pointer;"> > About</span> > ERA Goals and Objectives
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h1 class="sow-headline font-headlines">ERA Goals and Objectives</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h3 class="font-headlines"><b>Vision Statement</b></h3>
              <br />
              <p>ERA is to ensure that we help Producers’ meet their EPR obligations in a simple, effective, cost-effective and measurable way – as efficiently as possible.</p>
              <h3 class="font-headlines"><b>Mission Statement</b></h3>
              <br />
              <p>To establish a sustainable and fair WEEE collection and recycling system that works to meet Producers’ environmental compliance obligations in a manner that meets our collective aspirations for our country, planet, and people.</p>
              <p>Effective governance and the growth of a sustainable and effective WEEE management system in South Africa</p>
            </div>
          </div>
          <div class="row" style="text-align:center">
            <div class="col-sm-12">
              <img src="url(assets/era/14 goals-and-objectives-from-ppt-1.png)" />
            </div>
          </div>
        </div>
      </div>
      <!--P2-3 ERA ERA Key Performance Indicators (KPIs)-->
      <div *ngIf="get_recent() == 'second_3'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-12 yah">
              <span>You are here: </span> <i (click)="p1('first')" class="fa fa-home" aria-hidden="true"></i><span (click)="p1('second')" style="color: #69a84f;cursor: pointer;"> > About ERA’s WEEE EPR Scheme</span> > ERA Key Performance Indicators (KPIs)
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h1 class="sow-headline font-headlines">ERA Key Performance Indicators (KPIs)</h1>
              <p style="font-weight: 400;">To follow</p>
            </div>
          </div>
        </div>
      </div>
      <!--P2-4 ERA PRO Structure-->
      <div *ngIf="get_recent() == 'second_4'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-12 ">
              <span>You are here: </span> <i (click)="p1('first')" class="fa fa-home" aria-hidden="true"></i><span (click)="p1('second')" style="color: #69a84f;cursor: pointer;"> > About</span> > ERA PRO Structure
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12">
              <h1 class="sow-headline font-headlines">ERA PRO Structure</h1>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-sm-12" style="text-align:center">
              <img src="url(assets/era/13 ERA-Organigram.jpg)" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!--P2-5 ERA WEEE Scheme Collection and Recycling Targets-->
      <div *ngIf="get_recent() == 'second_5'">
        <div class="container4">
          <div class="row">
            <div class="col-sm-12 yah">
              <span>You are here: </span> <i (click)="p1('first')" class="fa fa-home" aria-hidden="true"></i><span (click)="p1('second')" style="color: #69a84f;cursor: pointer;"> > About</span> > ERA WEEE Scheme Collection and Recycling Targets
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <h1 class="sow-headline font-headlines">ERA WEEE Scheme Collection and Recycling Targets</h1>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-sm-12">
              <div class="siteorigin-widget-tinymce textwidget">
                <p>ERA has adopted a provisional target of <span style="color: #69a84f;"> 40 50%</span> of the national target for WEEE collection and recycling services during its first year of operation.  This collection and recycling target is set in line with ERA’s objective of achieving compliance with the EPR Regulations in an effective, accountable, simple and cost-effective manner.</p>
                <p>
                  ERA’s EPR Scheme aims to facilitate and support the nominal collection and recycling target for 2021 of between 16,000 and 18,0000 tons of WEEE during.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12" style="text-align:center">
              <img src="url(assets/era/11 Map-graphic.png)" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!--P3 About ERA’s WEEE EPR Scheme-->
      <div *ngIf="get_recent() == 'third'">


        <ngx-slick-carousel #slickIpoModal="slick-carousel" [config]="ipoSlideConfig" class="carousel" *ngIf="images2">
          <div ngxSlickItem *ngFor="let img of images2; index as i" class="grid-card">
            <div class="container-carousel">
              <img [src]="img" alt="" style="width:100%;height:350px;">
            </div>
            <div class="slick-text">
              <h1 class="a-slick-text">About ERA's WEEE EPR Scheme</h1>
            </div>
          </div>
        </ngx-slick-carousel>

        <div class="container4">

          <div class="row epr-scheme">
            <div class="col-sm-12 head-container">
              <h1 class="head-text">About ERA's WEEE EPR Scheme</h1>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p>The process through which an EEE Producer becomes a member of ERA NPC and it EPR Scheme is outlined in our Producer portal menu of this website.</p>
              <p>Prior to providing the headline summary of the ERA NPC EPR Scheme, we provide a brief process note as follows:</p>
              <ol>
                <li class="list">
                  The ERA EPR Scheme should derive from the total weight of product that its Producer Members place on the South Africa market relative to the total
                  weight of EEE product placed on the South African market. This exercise helps ERA to identify and adopt an appropriate portion of the published WEE
                  annual target for collection and recycling. At this early stage of EPR implentation, and the dearth of reliable EEE and WEEE data all-round, this had
                  not been an exact exercise and ERA has instead sought to adopt a resonable WEEE management target proportional to the number of WEEE PROs and
                  SAWIC registered EEE Producers.
                </li>
                <li class="list">
                  The ERA EPR Scheme is designed in full consultation with its Producer Members. In 2021/2022 the EPR planning process has been severly hampered by
                  ERA Members not submitting (a) their historical placed on market data, and (b) not submitting information on their existing WEEE management policies
                  and practices.
                </li>
                <li class="list">
                  As you may appreciate, it is critical that a new EPR dispensation as codified in the EPR Regulations R1184 and R1185 and the EPR Scheme arising from this
                  be extremely careful in the methodology it applies to establishing its annual targets, budget and EPR key.
                </li>
                <li class="list">ERA is willing to share its methodology with prospective EEE Producers in morre detail upon request.</li>
              </ol>
            </div>
          </div>

          <div class="row gap headline-summary">
            <div class="col-sm-12 head-container">
              <h1 class="head-text">Headline Summary of ERA EPR Scheme</h1>
            </div>
          </div>
          <div class="row gap">
            <div class="col-sm-12">
              <h3 class="sub-heading">Current Producer Members</h3>
              <p>- As at August 2022 are 38</p>

              <h3 class="sub-heading gap1">ERA Membership Joining Fee</h3>

            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12">
              <table class="T1" style="width:40%;margin:0 auto;align-content:center">
                <tr>
                  <th class="th1" style="width:20%"><span class="span1">Product Placed on the South African<br />Market P.A.</span></th>
                  <th class="th1" style="width:20%"><span class="span1">Joining Fee (ZAR)</span></th>
                </tr>
                <tr>
                  <td>Under 100 kgs</td>
                  <td>Exempt</td>
                </tr>
                <tr>
                  <td>100 - 1,000 kgs</td>
                  <td>2,500</td>
                </tr>
                <tr>
                  <td>1,001 - 5,000 kgs</td>
                  <td>5,000</td>
                </tr>
                <tr>
                  <td>5,000 - 10,000 kgs</td>
                  <td>7,500</td>
                </tr>
                <tr>
                  <td>Over 10,000 kgs</td>
                  <td>10,000</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12">
              <h3 class="sub-heading">Levy Basis for WEEE</h3>
              <p>The EERA EPR levy is based on the waste classification of EEE Products as follows:</p>
              <ol>
                <li class="list">General</li>
                <li class="list">Hazardous</li>
                <li class="list">High Hazard</li>
              </ol>


            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12">
              <h3 class="sub-heading">EPR Levy for EEE is as follows</h3>
            </div>
          </div>
          <div class="row gap1">
            <div class="col-sm-12">
              <table class="T1" style="width:40%;margin:0 auto;align-content:center">
                <tr>
                  <th class="th1" style="width:20%"><span class="span1">Catergory of WEE</span></th>
                  <th class="th1" style="width:20%"><span class="span1">Applicable Fee Per kg (ZAR)</span></th>
                </tr>
                <tr>
                  <td>General EEE</td>
                  <td>1.50 / kg</td>
                </tr>
                <tr>
                  <td>Hazardous EEE</td>
                  <td>2.25 / kg</td>
                </tr>
                <tr>
                  <td>High Hazard EEE</td>
                  <td>2.75 / kg</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12">
              <h3 class="sub-heading">EPR Scheme Collection / Recycling Target Range</h3>
            </div>
          </div>
          <div class="row gap1">
            <div class="col-sm-12">
              <table class="T1" style="width:40%;margin:0 auto;align-content:center">
                <tr>
                  <th class="th1" style="width:20%"><span class="span1">From</span></th>
                  <th class="th1" style="width:20%"><span class="span1">To</span></th>
                </tr>
                <tr>
                  <td>9000 tons/b</td>
                  <td>13,000 tons/b</td>
                </tr>
                <tr>
                  <td>25% of National Targets</td>
                  <td>35% of National Targets</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12">
              <h3 class="sub-heading">ERA Budget Composition</h3>
            </div>
          </div>
          <div class="row gap1">
            <div class="col-sm-12">

              <table class="T1" style="width:40%;margin:0 auto;align-content:center">
                <tr>
                  <th class="th1" style="width:20%"><span class="span1">Budget item</span></th>
                  <th class="th1" style="width:20%"><span class="span1">Budget Percentage</span></th>
                </tr>
                <tr>
                  <td>Administration</td>
                  <td>20%</td>
                </tr>
                <tr>
                  <td>Collection</td>
                  <td>8%</td>
                </tr>
                <tr>
                  <td>Transport</td>
                  <td>10%</td>
                </tr>
                <tr>
                  <td>Recycling</td>
                  <td>62%</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row gap">
            <div class="col-sm-12 head-container">
              <h3 class="head-text">ERA WEE Scheme Enterprise Support / Development Targets</h3>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p>ERA's enterprise development investments will be established after core budget requirements are met from EPR Levy income. the current scenario is highly variable.</p>
            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12 head-container">
              <h3 class="head-text">ERA WEE Scheme Job Support / Creation Target</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>Every 1,000 tons of WEEE sustains as estimated 25 jobs. Thus 9,000 tons of WEEE will sustain 250 jobs.</p>
            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12 head-container">
              <h3 class="head-text">Reporting</h3>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p>ERA Reports to DFFE bi-annually in July and March. The March report is an audited annual report.</p>
            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12">
              <h3 class="head-text">ERA Member Reporting</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>ERA Producer Members currently have the option of reporting their placed on market data on a monthly or quarterly basis.</p>
            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12">
              <h3 class="head-text">Annual Plan</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>By 30 November, following consultation with its members, ERA determines its EPR Levy Rates as well as its plan for the following year which is submits to DFFE for concurrence.</p>
            </div>
          </div>

          <div class="row gap1">
            <div class="col-sm-12">
              <h3 class="head-text">Members Meetings</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
                <table style="border-collapse: collapse; border: none;">
                  <tr style="border: none;">
                    <td style="border: none; white-space:nowrap">
                      <p> • Plenary discussion for Levy Setting</p>
                    </td>
                    <td style="border: none;">
                      <p>October</p>
                    </td>
                  </tr>
                  <tr style="border: none;">
                    <td style="border: none;">
                      <p> • Annual General Meeting</p>
                    </td>
                    <td style="border: none;">
                      <p>March</p>  
                    </td>
                  </tr>
                </table>
            </div>
          </div>

        </div>
          </div>
          <!--P3-1  ERA WEEE Scheme Collection / Recycling Target-->
          <div *ngIf="get_recent() == 'third_1'">
            <div class="container4">
              <div class="row">
                <div class="col-sm-12 ">
                  <span>You are here: </span> <i (click)="p1('first')" class="fa fa-home" aria-hidden="true"></i><span (click)="p1('third')" style="color: #69a84f;cursor: pointer;"> > About ERA’s WEEE EPR Scheme</span> ERA WEEE Scheme Collection / Recycling Target
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <h1 class="sow-headline font-headlines">
                    ERA WEEE Scheme Collection / Recycling Target
                  </h1>
                </div>
                <div class="row">
                  <div class="col-sm-12 box">
                    <p>The ERA EPR Scheme, in the light of the changed context for WEEE management, has to set its collection and recycling targets in proportion to the amount of EEE that its members place on the South African market.</p>
                    <p>ERA has set itself the target of representing EEE Producers who account for between 40% and 50% of the EEE on the market.&nbsp; This ambitious objective derives from the fact that it is in all Producer’s interests that ERA functions on a significant scale to reduce the relative costs of setting up effective systems to underpin its operations and transactions.</p>
                    <p>With the acquisition of relevant placed-on-market data provided by Producers, ERA will make further adjustments to its targeted WEEE collection and recycling targets.&nbsp; It needs to be noted, however, that a smaller membership base, and thus a smaller collection and recycling target, will result in the inevitable start-up costs being spread over a smaller levy base and thus create upward pressure on the levies that it will require from its members to cover operations.</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12" style="text-align:center">
                  <img src="url(assets/era/11 Map-graphic.png)" alt="" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 box">
                  <p>Based on primary research done by ERA into the DTIC’s trade statistics of a core basket 227 identified EEE products, we have managed to ascertain the global picture for that basket as follows:</p>
                  <p>The EPR Regulations state that when the ERA Scheme is presented to the DFFE, we must show very clear methodology for determining not only the EEE levy, but also the WEEE subsidies.&nbsp; The ERA Producers data will be critical to completing the working assumptions that determine our collection and recycling target as a PRO.</p>
                </div>
              </div>
            </div>
          </div>
          <!--P3-2 ERA WEEE Scheme Enterprise Support / Development Targets-->
          <div *ngIf="get_recent() == 'third_2'">
            <div class="container4">
              <div class="row">
                <div class="col-sm-12 ">
                  <span>You are here: </span> <i (click)="p1('first')" class="fa fa-home" aria-hidden="true"></i><span (click)="p1('third')" style="color: #69a84f;cursor: pointer;"> > About ERA’s WEEE EPR Scheme</span> > ERA WEEE Scheme Enterprise Support / Development Targets
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <h1 class="sow-headline font-headlines">
                    ERA WEEE Scheme Enterprise Support / Development Targets
                  </h1>

                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 box">
                  <p>ERA, in its initial WEEE IndWMP created substantial capex and opex budgets to support the stimulation of new enterprises along the entire WEEE collection and recycling value chain.&nbsp; Given the fractured manner in which we will be implementing EPR Schemes in South Africa, with multiple PROs and ICS’ now holding parts of the solution to achieving the WEEE targets, we felt it prudent to not be overly ambitious from the outset.&nbsp; We need to be cognisant of the fact that we are entering unchartered waters, and must at all costs avoid creating distortions in the costing of the EPR Scheme that cannot be corrected later.</p>
                  <p>The EPR Regulation 5A (1) (o) states that:</p>
                  <p><em>The producer responsibility organisation must – </em></p>
                  <p style="padding-left: 40px;"><em>“utilise new and existing infrastructure across extended producer responsibility schemes in a collaborative manner where feasible, or establish and operate new infrastructure within 3 years after completion of feasibility studies, where the initial feasibility study must be undertaken within 2 years of implementation of the extended producer responsibility scheme, that identifies the need for additional infrastructure”</em></p>
                  <p>While moving away from the fixed formula for enterprise support to grow the WEEE collection and recycling infrastructure, ERA has decided to create a modest budget reserve from which enterprise support can be funded on a case-to-case basis.&nbsp; We have also shifted away from a creating a supply-led programme to a demand-led programme.&nbsp; Should funding allow, we would be able to support in the region of up to 100 enterprises during our first year of operation.</p>
                </div>
              </div>
            </div>
          </div>
          <!--P3-3 ERA WEEE Scheme Job Support / Creation Target-->
          <div *ngIf="get_recent() == 'third_3'">
            <div class="container4">
              <div class="row">
                <div class="col-sm-12 ">
                  <span>You are here: </span> <i (click)="p1('first')" class="fa fa-home" aria-hidden="true"></i><span (click)="p1('third')" style="color: #69a84f;cursor: pointer;"> > About ERA’s WEEE EPR Scheme</span> > ERA WEEE Scheme Job Support / Creation Target
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <h1 class="sow-headline font-headlines">
                    ERA WEEE Scheme Job Support / Creation Target
                  </h1>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 box">
                  <p>ERA will have to more prudent in its job creation forecasts. We have defaulted to the working assumption that every 1000 tons of WEEE sustains 25 jobs as posited by Lydall M., Wonder N. , Yulandi J., 2017: Mapping South Africa’s Waste Electrical and Electronic Equipment (WEEE) Dismantling, Pre-Processing and Processing Technology Landscape. Pg 65</p>
                  <p>Based on the above formula and the target of 18,000 tons, we estimate that the ERA Scheme will sustain in the region of 500 jobs through its recycling subsidies and potentially a further 1,200 jobs through its enterprise support programme.</p>
                </div>
              </div>
            </div>
          </div>
          <!--P3-4 ERA WEEE EPR Scheme Budget / Resource Allocation-->
          <div *ngIf="get_recent() == 'third_4'">
            <div class="container4">
              <div class="row">
                <div class="col-sm-12">
                  <span>You are here: </span> <i (click)="p1('first')" class="fa fa-home" aria-hidden="true"></i><span (click)="p1('third')" style="color: #69a84f;cursor: pointer;"> > About ERA’s WEEE EPR Scheme</span> > ERA WEEE EPR Scheme Budget / Resource Allocation
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-sm-12">
                  <h1 class="sow-headline font-headlines">
                    ERA WEEE EPR Scheme Budget / Resource Allocation
                  </h1>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 box">
                  <p>The budget for the ERA Scheme’s first five years of operation will be presented at 29 September Webinar.</p>
                </div>
              </div>
            </div>
          </div>
          <!--P4 Welcome to the ERA Producer Member Portal-->
          <div *ngIf="get_recent() == 'fourth'">
            <ngx-slick-carousel #slickIpoModal="slick-carousel" [config]="ipoSlideConfig" class="carousel" *ngIf="images1">
              <div ngxSlickItem *ngFor="let img of images1; index as i" class="grid-card">
                <div class="container-carousel">
                  <img [src]="img" alt="" style="width:100%;height:350px;">
                </div>
                <div class="slick-text">
                  <h1 class="a-slick-text">Sign Up as a Producer</h1>
                </div>
              </div>
            </ngx-slick-carousel>
            <div class="container4">

              <div class="row">
                <div class="col-sm-12 head-container">
                  <h1 class="head-head-text"> Welcome to the ERA Producer Member Portal</h1>
                </div>
              </div>

              <div class="row p-bot">
                <div class="col-sm-12">

                  <p>
                    The page of the ERA Website provides a high-level overview of what the EPR Regulations require from EE Producers, as well as provides information and<br />
                    functional platforms for ERA Producer Members to manage with the ERA EPR Scheme.
                  </p>
                  <p>
                    The first step to becoming fully compliant and eligible for quarterly EPR Compliance Certificates in terms of the EPR Regulations is set up an EPR Scheme<br />
                    This may be achieved by:
                  </p>
                  <ol type="i">
                    <li class="list">Establishing and implementing an Extented Producer Responsibility Scheme</li>
                    <li class="list">List of Grievances</li>
                    <li class="list">Joining/appointing another Producer Responsibility Scheme to implement a scheme on your behalf</li>
                    <li class="list">Establishing and operating an individual Compliance Scheme</li>
                  </ol>
                  <p>Non-ERA Producers are welcome to request more detailed information on the EPR Regulations and the ERA EPR Scheme.</p>
                  <p>Below listed, please find the hyperlinks for:</p>
                  <ol>
                    <li class="list">The registration process to be followed to register as an EEE producer with DFFE (<a href="http://sawic.environment.gov.za/epr/">http://sawic.environment.gov.za/epr/)</a></li>
                    <li class="list">The registration process to be followed to become an ERA NPC Member</li>
                    <li class="list">Submission of Place on Market Sata for Monthly or Quartely reporting toward compliance</li>
                  </ol>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 head-container">
                  <h1 class="head-head-text">Invitation to become an ERA Producer Member</h1>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <p>
                    After you have registered as a EEE Producer with the DFFE using this hyperlink (<a href="http://sawic.environment.gov.za/epr/">http://sawic.environment.gov.za/epr/</a> ), ERA invites you to become a member
                    and to participate in its WEEE EPR Scheme by completing the following steps.
                  </p>
                </div>
              </div>

              <div class="row gap">
                <div class="col-sm-12 head-container">
                  <h1 class="head-text">Step 1 - Register on the SAWIC database</h1>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <p>
                    This will ensure that you receive your individual Producer number which is crucial for usage on the SAWIC reporting system in linking you as a Producer to <br />
                    either as Extended Producer Responsiblity Scheme, another Producer Responsibilty Scheme or to your Individual Compliance Scheme
                  </p>
                </div>
              </div>


              <div class="row gap">
                <div class="col-sm-12 head-container">
                  <h1 class="head-text">Step 2 - Register as a Producer Member with ERA NPC</h1>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <p>There are Six Modules (or screens) that need to be completed within the Registration form:</p>
                  <ol>
                    <li class="list">Company Details (with document uploads)</li>
                    <li class="list">Contact Persons</li>
                    <li class="list">Further Company Details</li>
                    <li class="list">Financial Information</li>
                    <li class="list">Current WEEE Recycling</li>
                    <li class="list">Documents Uploads and Warranties</li>
                  </ol>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12" style="text-align:center">
                  <div class="col-sm-12" style="display: flex;justify-content: space-evenly;">
                    <img src="/assets/home/person-plus-fill.svg" alt="Alternate Text" />
                  </div>

                  <div class="col-sm-12" style="display: flex;justify-content: space-evenly;"><button class="ram-button" type="button" (click)="openRegister(register);">Register as a Member</button></div>
                </div>
              </div>

              <div class="row gap">
                <div class="col-sm-12 head-container">
                  <h1 class="head-text">Step 3 - Conclude the Producer Membership Agreement</h1>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p>Post Registration ERA NPC will provide you with:</p>
                  <ol type="i">
                    <li class="list">The ERA NPC memorandum of Incorporation for your personal and record keeping.</li>
                    <li class="list">
                      The Master Membership Agreement for your perusal, engagement and sign off. Such agreement will embody all the material terms to he underlying
                      arrangement between ERA NPC and the Producer member.
                    </li>
                  </ol>
                </div>
              </div>

              <div class="row gap">
                <div class="col-sm-12 head-container">
                  <h1 class="head-text">Step 4 - Make Membership Fee Payment</h1>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <ul>
                    <li class="list">
                      To become a member of ERA NPC, you will need to register and pay a joining fee. Our fee structure is provided below and is outside of the EEE levies
                      payable by Producers.
                    </li>
                  </ul>
                </div>
              </div>


              <div class="row gap1">
                <div class="col-sm-12">
                  <table class="T1" style="width:40%;margin:0 auto;align-content:center">
                    <tr>
                      <th class="th1" style="width:20%"><span class="span1">ANNUAL IDENTIFIED PRODUCT<br />PLACED ON THE SOUTH AFRICAN <br />MARKET</span></th>
                      <th class="th1" style="width:20%"><span class="span1">JOINING FEE (ZAR)</span></th>
                    </tr>
                    <tr>
                      <td>Under 100 kgs</td>
                      <td>Exempt</td>
                    </tr>
                    <tr>
                      <td>100 - 1,000 kgs</td>
                      <td>2,500</td>
                    </tr>
                    <tr>
                      <td>1,001 - 5,000 kgs</td>
                      <td>5,000</td>
                    </tr>
                    <tr>
                      <td>5,001 - 10,000</td>
                      <td>7,500</td>
                    </tr>
                    <tr>
                      <td>Over 10,000 kgs</td>
                      <td>10,000</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="row gap1">
                <div class="col-sm-12" style="align-content:center">
                  <table style="border-collapse: collapse; border: none;margin:0 auto;align-content:center">
                    <tr style="border: none;">
                      <td style="border: none;">
                        <b>Bank Details</b>
                      </td>
                      <td style="border: none;">
                      </td>
                    </tr>
                    <tr style="border: none;">
                      <td style="border: none;">
                        <b>Bank:</b>
                      </td>
                      <td style="border: none;">
                        Standard Bank of South africa Ltd
                      </td>
                    </tr>
                    <tr style="border: none;">
                      <td style="border: none;">
                        <b>Account Name:</b>
                      </td>
                      <td style="border: none;">
                        ERA-Ewaste recycling Authority-NPC
                      </td>
                    </tr>
                    <tr style="border: none;">
                      <td style="border: none;">
                        <b>Account Number:</b>
                      </td>
                      <td style="border: none;">
                        071086056
                      </td>
                    </tr>
                    <tr style="border: none;">
                      <td style="border: none;">
                        <b>Branch Code:</b>
                      </td>
                      <td style="border: none;">
                        024109
                      </td>
                    </tr>
                    <tr style="border: none;">
                      <td style="border: none;">
                        <b>Swift Code:</b>
                      </td>
                      <td style="border: none;">
                        SBZAZAJJ
                      </td>
                    </tr>
                  </table>
                </div>
              </div>



              <div class="row gap">
                <div class="col-sm-12 head-container">
                  <h1 class="head-text">Step 5 - Place on Market Reporting</h1>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p>The fifth step in your journey to becoming fully compliant in terms of the EPR Regulations is to:</p>
                  <ul>
                    <li class="list">
                      Provide ERA NPC with average Place on Market data for 2 proceeding years - directly to the ERA NPC Executive Management team, upon joining ERA NPC,
                      for forecasting purposes.
                    </li>
                    <li class="list">
                      Provide ERA NPC with Place on Market data either quarterly or monthly - your preference as defined in your Producer Agreement. Such quarterly or
                      monthly reporting is achieved by accessing the Members data and Reporting Portal via the blow.
                    </li>
                    <li class="list">The Reporting timelines are as follows:</li>
                  </ul>
                  <ol type="i">
                    <li class="list">Monthly -  by the 15th of the month following the end of the reporting month</li>
                    <li class="list">Quartely, as per the below table:</li>
                  </ol>

                </div>
              </div>

              <div class="row gap1">
                <div class="col-sm-12">
                  <table class="T1" style="width:40%;margin:0 auto;align-content:center">
                    <tr>
                      <th class="th1" style="width:10%"><span class="span1">Quarter</span></th>
                      <th class="th1" style="width:15%"><span class="span1">Period</span></th>
                      <th class="th1" style="width:15%"><span class="span1">Report Due Date (2022/2023)</span></th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>1 January - 31 March</td>
                      <td>15th April</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>1 April - 30 June</td>
                      <td>15th July</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>1 July - 30 September</td>
                      <td>15th October</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>1 October - 31 December</td>
                      <td>15th January</td>
                    </tr>
                  </table>
                </div>
              </div>


              <div class="row gap">
                <div class="col-sm-12 head-container">
                  <h1 class="head-text"> Invitation to become an ERA Producer Member</h1>
                </div>
              </div>


              <div class="row">
                <div class="col-sm-12">
                  <span class="disclaimer">for use by registered ERA Producer Members only</span>
                  <h3 class="sub-heading">Steps</h3>
                  <ol>
                    <li class="list">Go to <a href="https://eranpc.co.za">https://eranpc.co.za</a> </li>
                    <li class="list">Click on the log-in button on the landing page.</li>
                    <li class="list">Click on "Forgot your password?", even if you have set a password previously.</li>
                    <li class="list">Enter your individual email address into the 'Username/Member no' field, as this has been linked as the relationship to your ERA System company number ID Test Company System ID</li>
                    <li class="list">Click Continue.</li>
                    <li class="list">Wait For One Time Pin (OTP) email to be received in your username mailbox -  here please note that the one time password comes from TEMO Digital our IT support, and they would have to be allowed access at your firewall for you to receive the OTP.</li>
                    <li class="list">Enter One Time Pin (OTP) into the One Time Pin (OTP) field.</li>
                    <li class="list">Enter your new password and confirm your password.</li>
                    <li class="list">Proceed to the Producer Portal.</li>
                    <li class="list">Click on the upload button.</li>
                    <li class="list">Find your pre completed excel spradsheet - in the format provided by ERA NPC to you in a separate email - on yout PC/Laptop and upload.</li>
                    <li class="list">If any problems on upload you will get a message to that effect - scroll over to the rights and it will indicate what the problem is.</li>
                    <li class="list">Verify the validity of the data.</li>
                    <li class="list">Confirm the aggregated data summary and the resultant Pro Forma Invoice</li>
                  </ol>
                  <div class="row gap1">
                    <div class="col-sm-12" style="text-align:center">
                      <div class="col-sm-12" style="display: flex;justify-content: space-evenly;padding:1%">
                        <img class="padding" src="/assets/home/Register as a Member icon.svg" alt="Alternate Text" />
                      </div>

                      <div class="col-sm-12" style="display: flex;justify-content: space-evenly;"><button class="ram-button" type="button" (click)="openLogin(login)">Access Member Portal</button></div>
                    </div>
                  </div>
                </div>
              </div>

    


              <div class="row gap">
                <div class="col-sm-12 head-container">
                  <h1 class="head-text">Step 6 - EPR Levy Payments</h1>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <p>The sixth step in your journey to becoming fully compliant in terms of the EPR Regulations is to:</p>
                  <ol>
                    <li class="list">Acknowledge receipt of your invoice as provided by the Independant Internal Auditors to ERA NPC for onward transmission to yourself.</li>
                    <li class="list">
                      Pay such invoice in to the ERA NPC bank account as follows:

                      <div style="align-content:center">
                        <table style="border-collapse: collapse; border: none;margin:0 auto;align-content:center">
                          <tr style="border: none;">
                            <td style="border: none;">
                              <b>Bank Details</b>
                            </td>
                            <td style="border: none;">
                            </td>
                          </tr>
                          <tr style="border: none;">
                            <td style="border: none;">
                              <b>Bank:</b>
                            </td>
                            <td style="border: none;">
                              Standard Bank of South africa Ltd
                            </td>
                          </tr>
                          <tr style="border: none;">
                            <td style="border: none;">
                              <b>Account Name:</b>
                            </td>
                            <td style="border: none;">
                              ERA-Ewaste recycling Authority-NPC
                            </td>
                          </tr>
                          <tr style="border: none;">
                            <td style="border: none;">
                              <b>Account Number:</b>
                            </td>
                            <td style="border: none;">
                              071086056
                            </td>
                          </tr>
                          <tr style="border: none;">
                            <td style="border: none;">
                              <b>Branch Code:</b>
                            </td>
                            <td style="border: none;">
                              024109
                            </td>
                          </tr>
                          <tr style="border: none;">
                            <td style="border: none;">
                              <b>Swift Code:</b>
                            </td>
                            <td style="border: none;">
                              SBZAZAJJ
                            </td>
                          </tr>
                        </table>
                      </div>

                    </li>

                    <li class="list">Provide the ERA Team with the Proof of Paymemt.</li>
                  </ol>
                </div>
              </div>

              <div class="row gap1">
                <div class="col-sm-12 head-container">
                  <h1 class="head-text">Step 7 - Quarterly Compliance Certificates</h1>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <p>
                    The final step in your journey to becoming fully compliant in terms of the ERA Regulations is to be in receipt of the Compliance Certificate as issued by the
                    ERA NPC team post receipt of your payment on a quarterly basis.
                  </p>
                </div>
              </div>
            </div>
              </div>
              <!--P5 Service Provider Portal-->
              <div *ngIf="get_recent() == 'fifth'">
                <ngx-slick-carousel #slickIpoModal="slick-carousel" [config]="ipoSlideConfig" class="carousel" *ngIf="images1">
                  <div ngxSlickItem *ngFor="let img of images1; index as i" class="grid-card">
                    <div class="container-carousel">
                      <img [src]="img" alt="" style="width:100%;height:350px;">
                    </div>
                    <div class="slick-text">
                      <h1 class="a-slick-text">Sign Up as a Service Provider</h1>
                    </div>
                  </div>
                </ngx-slick-carousel>

                <div class="container4 gap1">
                  <div class="row">
                    <div class="col-sm-12 head-container">
                      <h1 class="head-head-text">Welcome to the ERA Service Provider Portal</h1>
                    </div>
                  </div>
                  <div class="row p-bot">
                    <div class="col-sm-12 gap">

                      <p>
                        This page of the ERA website provides a high-level overview of what guidance the EPR Regulations provide to WEE collection and recycling service provision.
                        It also provides information and functional platforms for ERA-accredited Service Providers to engage with the ERA EPR Scheme.
                      </p>
                      <p>
                        Non-ERA Service Provides are welcome to request more detailed information on the ERA EPR Scheme, and on how they could be
                        absorbed into the ERA supply chain.
                      </p>
                      <p>
                        There is no registration costs associated with the application to become an ERA Service Provider. ERA charged with and is committed to promoting
                        transformation in the WEEE sector and thus participation from small and meduim sized SME's that are owned and/or operated by historically disadvantaged
                        women, youth and people living with disabilities are welcome.
                      </p>
                      <p>The page provides the links for:</p>
                      <ol>
                        <li class="list">The registration process to be followed to become an ERA-accredited Service Provider</li>
                        <li class="list">Service Provision administration, inclusive of</li>
                      </ol>
                      <ul>
                        <li class="list">Uploading Recycling Certificates</li>
                        <li class="list">Invoicing</li>
                        <li class="list">Report Submissions of aggregated recycled data for Monthly or Quarterly reporting to DFFE toward PRO complaince</li>
                      </ul>
                      <p>The process of becoming an ERA Service Provider entails the following:</p>
                      <ol type="i">
                        <li class="list">
                          Respond to the ERA 'Call for Proposals' by completing the registration modules and uploading the necessary supporting documentation provided in the
                          link below.
                        </li>
                        <li class="list">Allow ERA to conduct a due diligence exercise which includes both a physical site visit and desktop vverification.</li>
                        <li class="list">Sign a Service Provider Agreement with ERA.</li>
                        <li class="list">Provde ERA with the necessary data required for the PRO to report effectively to DFFE.</li>
                        <li class="list">Utilise the ERA system for invoice submission.</li>
                      </ol>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 head-container">
                      <h1 class="head-head-text">Call for Proposals Invitation to become an ERA Service Provider</h1>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <p>Response to the ERA 'Call for Proposals must be completed through the link provided below.'</p>
                      <p>
                        ERA invites you to become a Service Provider and to participate in its WEEE EPR Scheme by completing the (i) General Information and the (ii) Technical
                        Information modules perinent to yourself. Submissions can be made for one or all of the service catergories.
                      </p>
                      <p>The system will ask for the following:</p>
                      <ol type="i">
                        <li class="list">General Service Provider Information and details of how the Service Provider operates.</li>
                        <li class="list">The Technical requirements for the following 3 catergories of service provision:</li>
                      </ol>
                      <p>- Collection</p>
                      <p>- Transport, and</p>
                      <p>- Recycling activities</p>
                      <p>Important dates as to Proposal submission:</p>

                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12 gap1">

                      <table class="T1" style="width:40%;margin:0 auto;align-content:center">
                        <tr>
                          <th class="th1" style="width:20%"><span class="span1">Submission</span></th>
                          <th class="th1" style="width:20%"><span class="span1">Due Date</span></th>
                        </tr>
                        <tr>
                          <td>General Service Provider Information</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            Technical Information, inclusive of Pricing <br />
                            Module and supporting Certificates
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>3 Page Motivation</td>
                          <td></td>
                        </tr>
                      </table>
                    </div>
                  </div>


                  <div class="row gap">
                    <div class="col-sm-12 head-container">
                      <h1 class="head-text">Step 1 - Register as a Prospective Service Provider</h1>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <p>There are ... Modules (or screens) that need to be completed within the General Information section:</p>
                      <ol>
                        <li class="list">Company Details (with document uploads)</li>
                        <li class="list">Contact Persons</li>
                        <li class="list">Financial Information</li>
                        <li class="list">Infrastructure Footprint</li>
                        <li class="list">Personal spread</li>
                        <li class="list">Document Uploads inclusive of specific certificates</li>
                      </ol>
                      <div class="col-sm-12" style="text-align:center">
                        <img src="/assets/home/person-plus-fill.svg" alt="Alternate Text" />
                        <p style=" display: flex; justify-content: space-evenly;"><button class="rasp-button" type="button" [routerLinkActive]="['link-active']" [routerLink]="['service-portal/service-portal/company-details']">Register as a Service Provider</button></p>
                      </div>
                    </div>
                  </div>


                  <div class="row gap">
                    <div class="col-sm-12 head-container">
                      <h1 class="head-text">Step 2 - Complete the Technical Questionnaire/s:</h1>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <p>
                        The technical questionnaires are designed to provide the ERA bid committee with an idea as to the footprint of the business, inherent competencies
                        within the applicant business, the capacity constraints within the applicant business, the areas of specification within the applicant business, and
                        the ability to the into the ERA systems.
                      </p>
                      <p>Based on the above, you are invited to consider completing the questionnaire for either:</p>
                      <ol type="i">
                        <li class="list">specific areas of expertise - by clicking on the salient link whick will open up the necessary pop-up questionnaire, or</li>
                        <li class="list">a combination of areas of expertise dependant on your business model, or</li>
                        <li class="list">completing all the questionnaires.</li>
                      </ol>
                    </div>
                  </div>

                  <div class="row gap">
                    <div class="col-sm-12 head-container">
                      <h1 class="head-text">Step 3 - Complete the Pricing Module</h1>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">

                      <p>
                        Importantly, as ERA NPC is a Producer-managed PRO, central to our onboarding process would be pricing. The completion of the pricing module (which will
                        pop out post-completion of the Technical module/s) will be compulsory in allowing an adequate assessment of the prospective Service Provider.
                      </p>
                    </div>
                  </div>

                  <div class="row gap">
                    <div class="col-sm-12 head-container">
                      <h1 class="head-text">Step - 4 Uploading Supporting Documentation and Compliance Certificates</h1>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <p>
                        Finally, to complete the process, and for ERA to gage your fit for purpose, you would need to upload the necessary documentation which is prescribed in the
                        Certification module.
                      </p>
                      <p>
                        Please note that failure to have the prescribed documentation does not automatically disqualify the prospective service provider as it is an indicator as to
                        possible weakness in the business which ERA can work towards addressing with the applicant, should it be required and be deemed viable.
                      </p>
                    </div>
                  </div>

                  <div class="row gap">
                    <div class="col-sm-12 head-container">
                      <h3 class="head-text">Step - 5 Uploading a short Motivation as to why ERA NPC should take you on as a Service Provider</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <p>
                        The basis of this motivation - maximum 3 pages - should speak to your present competencies, company resources and future plans and may include
                        reference lists and/or client base.
                      </p>
                    </div>
                  </div>

                  <div class="row gap">
                    <div class="col-sm-12 head-container">
                      <h1 class="head-head-text">Service Provides: Data and Reporting Portal</h1>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <span class="disclaimer">For use by registered ERA Service Providers only</span>
                      <h3 class="sub-heading ml-1">Steps</h3>
                      <ol>
                        <li class="list">Confirm your confidential username and unique password in the manner prescribed</li>
                        <li class="list">Complete or upload your Monthly Aggregated Data Return sheet in the format that was mailed to ERA Service Providers</li>
                        <li class="list">Upload individual ERA Producer Member invoices for work completed</li>
                        <li class="list">Upload Recycling Certificates supporting individual invoices</li>
                        <li class="list">Upload Summarised Invoice Totalling amount due to you.</li>
                      </ol>
                    </div>
                  </div>

                </div>
              </div>
              <!--P6 Contact details for the E-Waste Recycling Authority-->
              <div *ngIf="get_recent() == 'sixth'">
                <div class="container4">
                  <div class="row">
                    <div class="col-sm-12 ">
                      <span>You are here: </span> <i (click)="p1('first')" class="fa fa-home" aria-hidden="true"></i> > Conatct
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <h1 class="sow-headline font-headlines">
                        Contact details for the E-Waste Recycling Authority
                      </h1>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 box">
                      <h4 class="font-headlines"><strong>Email</strong></h4>

                      <p><span class="s1"><i style="color: #69a84f" class="fa fa-envelope" aria-hidden="true"></i> <a style="color: #69a84f" href="mailto:joseph@eranpc.co.za">joseph@eranpc.co.za</a></span><span class="s2"> c/o Joseph Jordaan (acting COO)&nbsp;</span></p>
                      <p><span class="s1"><i style="color: #69a84f" class="fa fa-envelope" aria-hidden="true"></i> <a style="color: #69a84f" href="mailto:ashley@eranpc.co.za">ashley@eranpc.co.za</a></span><span class="s2"> c/o Ashley du Plooy (acting CEO)&nbsp;</span></p>

                      <h4 class="font-headlines mt-1"><strong>Phone</strong></h4>

                      <p>Ashley du Plooy (acting CEO) +27785332286</p>
                      <p>Joseph Jordaan (acting COO) +27747515968</p>

                      <h4 class="font-headlines mt-1"><strong>Website</strong></h4>

                      <p><span class="s2"><a style="color: #69a84f" href="http://www.eranpc.co.za">http://www.eranpc.co.za</a></span></p>
                    </div>
                  </div>
                </div>
              </div>
              <!--P7 Terminology-->
              <div *ngIf="get_recent() == 'seventh'">

                <ngx-slick-carousel #slickIpoModal="slick-carousel" [config]="ipoSlideConfig" class="carousel" *ngIf="images3">
                  <div ngxSlickItem *ngFor="let img of images3; index as i" class="grid-card">
                    <div class="container-carousel">
                      <img [src]="img" alt="" style="width:100%;height:350px;">
                    </div>
                    <div class="slick-text">
                      <h1 class="a-slick-text">Terminology</h1>
                    </div>
                  </div>
                </ngx-slick-carousel>

                <div class="container4">
                  <div class="row">
                    <div class="col-sm-12 head-container">
                      <h1 class="head-text">Terminology</h1>
                    </div>
                  </div>
                  <div class="row gap">
                    <div class="col-sm-12 gap1">
                      <h3 class="sub-heading">Black Box 'function'</h3>
                      <p>
                        An E-WASTE PRO function that is out-sourced to an appropriate organisation as it requires confidentiality in working with sensitive industry trade data.
                        The subcontracted operator of the “black box” function is expressly prohibited from releasing any information in respect of any producer market share or data to
                        any person including the E-WASTE PRO and the EEE producers themselves.
                      </p>
                    </div>
                  </div>
                  <div class="row gap1">
                    <div class="col-sm-12">

                      <h3 class="sub-heading">3 x “R’s” or E-WASTE Management Hierarchy</h3>
                      <p>Reduce, Re-Use, Recycle, Disposal</p>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">BBBEE</h3>
                      <p>Broad Based Black Economic Empowerment</p>
                    </div>
                  </div>
                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">Brand Owner</h3>
                      <p>A “brand owner” is defined as a person or category of persons that owns a specific range of products.</p>
                    </div>
                  </div>
                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">Collection</h3>
                      <p>
                        Gathering of waste, including the preliminary sorting and preliminary storage of waste, for the purposes of transport to storage, manual or mechanical
                        processing, and metallurgical processing facilities.
                      </p>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">

                      <h3 class="sub-heading">Electronic and Electrical Equipment “EEE”</h3>

                      <h3 class="sub-sub-heading">Global definition”</h3>
                      <p>
                        “EEE” means electrical and electronic equipment which is dependent on electric currents or electromagnetic fields in order to work properly and designed for
                        use with a voltage rating not exceeding 1000 volts for alternating current and 1500 volts for direct current.
                        “dependent” means needing electric currents or electromagnetic fields to fulfil at least one intended function.
                      </p>

                      <h1 class="sub-sub-heading gap1">DEA Definition (according to Section 28(1) Notice):</h1>
                      <p>
                        “Electrical and electronic equipment” means equipment which is dependent on electric currents or electromagnetic fields in order to work properly and
                        equipment for the generation, transfer and measurements of such currents and fields
                      </p>
                    </div>
                  </div>
                  <div class="row gap1">
                    <div class="col-sm-12">

                      <h3 class="sub-heading">E-WASTE Category Classification</h3>

                      <p>As contained in Schedule 3 of the National Environmental Management: Waste Amendment Act, 2014 (Act No. 26 of 2014).</p>
                      <p>
                        E-WASTE is classified both as hazardous and non-hazardous (general) waste. From a cautionary principle point of view the global default classification of
                        “hazardous” is applied throughout the Plan.
                      </p>
                    </div>
                  </div>
                  <div class="row gap1">
                    <div class="col-sm-12">
                      <span style="color: #626567; font-size: 20px;"> <b> </b> </span>
                      <h3 class="sub-heading">Product Responsible Organisation (PRO)</h3>
                      <p>
                        An independent, non-profit industry management body that manages the E-WASTE Industry Waste Management Plan and that, among other things: designs
                        and defines the E-WASTE management regime applicable to South Africa; requires the registration of all EEE producers and recyclers; monitors the EEE
                        products placed on the South African market and the safe and beneficial collection and recycling of that EEE once it reaches its end-of-life; the setting and
                        management of standards that all participants in the E-WASTE management system need to comply with; the Planning and disbursement of E-WASTE
                        subsidies to support the Plan and to monitor and audit the entire system for adherence and compliance to the Plan.
                      </p>
                    </div>
                  </div>
                  <div class="row gap1">
                    <div class="col-sm-12">

                      <h3 class="sub-heading">IndWMP</h3>
                      <p>Industry Waste Management Plan as contemplated in Section 28 of the NEMWA</p>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">Informal Waste Collector</h3>
                      <p>Person/s collecting materials of potential value (e.g. packaging waste and E-WASTE) from the streets, other public spaces, households, waste dumps and landfill sites. They do not do this collection as waged or salaried persons and often operate at subsistence level. Also referred to as “Pickers”.</p>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">Informal E-WASTE Processing</h3>
                      <p>
                        An attempt to maximise sales profits by extracting fractions of value from E-WASTE in the absence of financial (dis) incentives for proper treatment. This is
                        typically characterised by using inappropriate and unsafe (both for the workers and the environment) methods including breaking and smashing of valuable
                        fraction bearing components and burning off undesirable materials and encasings of such value fractions (e.g. copper in cable).
                      </p>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">Local and Global Market</h3>
                      <p>
                        These are markets where there is demand for the materials recovered from the processing/recycling of E-WASTE, be they local or international. All materials
                        recovered from E-WASTE processing/recycling, hazardous and non-hazardous, in this Plan must comply with international and national policy and legislation.
                        Trade in these recovered materials that disregard these policies and legislations are deemed to be illegal trade.
                      </p>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">Manual Processing </h3>
                      <p>
                        Process to separate and concentrate E-WASTE fractions manually (e.g. by means of sorting, separating, cleaning, emptying, dismantling, de-pollution and
                        segregation).
                      </p>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">Non-valuable fractions</h3>
                      <p>
                        Fractions of E-WASTE where there is no demand for the fraction, and/or where the fraction represents an unwanted cost that is generally avoided by the
                        market.
                      </p>
                    </div>
                  </div>


                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">NPSWM</h3>
                      <p>National Pricing Strategy for Waste Management</p>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">OEM</h3>
                      <p>
                        The Original Equipment Manufacturer is the company that manufactures or assembles the original product under its own brand name. The OEM is also
                        known as a “Manufacturer”.
                      </p>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">

                      <h3 class="sub-heading">Producer</h3>
                      <p>
                        “Producer” means any person or category of persons or a brand-owner who is engaged in the commercial manufacture, conversion, refurbishment or import
                        of new and/or used-
                      </p>
                      <ul>
                        <li class="list">paper and packaging material;</li>
                        <li class="list">lighting equipment;</li>
                        <li class="list">electrical and electronic equipment; or</li>
                        <li class="list">goods wrapped in primary or secondary packaging material,</li>
                        <li class="list">
                          goods wrapped in primary or secondary packaging material,
                          ... which are intended for distribution in the Republic of South Africa.The local manufacturer or importer (or their assigned agents) of new and/or used EEE
                          placed on the South African market at the point of manufacture or import.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">Recycling</h3>
                      <p>
                        Any recovery operation by which waste materials are reprocessed into products, materials or substances, whether for the original or other purposes. It does
                        not include energy recovery and the reprocessing into materials to be used as fuels or for backfilling operations. [SOURCE: European Commission, 2008]
                      </p>
                      <p>
                        E-WASTE Recycling includes the following pre-processing and end processing steps in a basic simplified value chain:
                      </p>
                      <ul>
                        <li class="list">Manual Dismantling/Disassembly, Hand Sorting and Decontamination</li>
                        <li class="list">Mechanical Size Reduction (Shredding, milling, granulating, crushing</li>
                        <li class="list">Physical/Mechanical Separation (Gravity, magnetic, air, electrostatic, density, eddy-current separation etc.)</li>
                        <li class="list">Hydrometallurgy (Leaching and solution purification) and Pyrometallurgy (Smelting)</li>
                        <li class="list">Electrometallurgy (Electro-winning, Refining)</li>
                      </ul>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">Refurbishment</h3>
                      <p>
                        Recovery of function of viable assets – subject to certain specifications benchmarked against global industry standards (exercising the re-use priority in the
                        recycling hierarchy)
                      </p>
                    </div>
                  </div>


                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">The Act / NEMWA</h3>
                      <p>
                        The National Environmental Management: Waste Act, 2008 (Act No. 59 of 2008) and the National Environmental Management: Waste Amendment Act, 2014
                        (Act No. 26 of 2014)
                      </p>
                    </div>
                  </div>
                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">Valuable fractions</h3>
                      <p>Fractions of E-WASTE where there is demand for the fraction and the market is prepared to pay for acquiring it.</p>

                    </div>
                  </div>



                  <div class="row gap1">
                    <div class="col-sm-12">

                      <h3 class="sub-heading">Value Chain</h3>
                      <p>The four stages of the E-WASTE value chain are:</p>
                      <ul>
                        <li class="list">Collection</li>
                        <li class="list">Dismantling</li>
                        <li class="list">Pre-Processing</li>
                        <li class="list">Processing</li>
                      </ul>



                    </div>
                  </div>
                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">WEEE (Waste Electrical and Electronic Equipment)</h3>
                      <p>
                        Electronic waste, or WEEE, refers to all items of electrical and electronic equipment (EEE) and its parts that have been discarded by its owner as waste without
                        the intent of re-us
                      </p>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">
                      <h3 class="sub-heading">E-WASTE Dismantler</h3>
                      <p>Service provider who manually recovers certain fractions and components of value in E-WASTE</p>
                    </div>
                  </div>

                  <div class="row gap1">
                    <div class="col-sm-12">
                      <span style="color: #626567; font-size: 20px;"> <b>E-WASTE </b> </span>
                      <p>
                        Processors Service provider who uses either mechanical, chemical, biological or thermal processes to treat E-WASTE fractions received from recyclers to
                        recover secondary resource materials.
                      </p>
                    </div>
                  </div>
                </div>
                  </div>
                  <!--P8 Articles and Events-->
                  <div *ngIf="get_recent() == 'eighth'">

                    <ngx-slick-carousel #slickIpoModal="slick-carousel" [config]="ipoSlideConfig" class="carousel" *ngIf="images">
                      <div ngxSlickItem *ngFor="let img of images; index as i" class="grid-card">
                        <div class="container-carousel">
                          <img [src]="img" alt="" style="width:100%;height:350px;">
                        </div>
                        <div class="slick-text">
                          <h1 class="a-slick-text">ERA Articles and Events</h1>
                        </div>
                      </div>
                    </ngx-slick-carousel>

                    <div class="container4">
                      <div class="gap3">
                        <div class="row">
                          <div class="col-sm-12 head-container">
                            <h1 class="head-text">Articles</h1>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12 ">
                            <p>No Articles found</p>
                          </div>
                        </div>
                      </div>
                      <div class="gap3">
                        <div class="row">
                          <div class="col-sm-12 head-container">
                            <h1 class="head-text">Events</h1>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12 ">
                            <p>No Events found</p>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>
                  <!--P9 Privacy Policy-->
                  <div *ngIf="get_recent() == 'ninth'">
                    <div class="container4">
                      <div class="row">
                        <div class="col-sm-12 head-container">
                          <h1 class="head-head-text">ERA PRIVACY NOTICE</h1>
                          <p>
                            At E-waste Recycling Authority (ERA) we are committed to protecting your privacy and ensuring that your personal information is collected and used properly, lawfullly and transparently in compliance with the Protection of Personal Information Act(POPIA).
                            This Notice explains how we obtain, use and disclose your personal information and aims to inform you of your rights and how to exercise them.
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12 gap2">
                          <h2 class="head-text">WHO WE ARE</h2>
                          <p>In this notice, "we" refers to E-waste Recycling Authority (ERA) in partnership with our technology service provider.</p>
                        </div>
                        <div class="col-sm-12 gap2">
                          <h2 class="head-text">THE INFORMATION WE COLLECT</h2>
                          <p>
                            We collect and process your personal information to provide you with access to our services to help us improve our offerings to you and for other purposes explained below. In order for us to do this, you must provide us with the information that we request, failing which we cannot enter into a relationship with you or assist you.<br /><br />We will process personal information for the purposes set out in this notice. You warrant that when you give us personal information about third parties, this information is accurate and correct and you have received their permission to share their personal information with us for the purposes set out in this Privacy Notice or any other related purposes. If you are giving consent on behalf of a person under 18 (a minor), you confirm that you are a competent person and that you have the authority to give their consent on their behalf.<br /><br />
                            The type of information we collect will depend on the purpose for which it is collected and used. We will only collect information required for that purpose.
                            We collect information directly from you when you provide us with your personal details, for example when you register as a producer or when you submit enquiries to us or contact us.<br /><br />We have a duty to take all reasonably practicable steps to ensure your personal information is complete, accurate, not misleading, and updated on a regular basis. To enable this, we will always try to obtain personal information from you directly, and we would appreciate that your personal information is kept up to date and accurate.<br /><br />Website usage information is collected using “cookies” which allows us to collect standard internet visitor usage information. This includes information about how you logged on and from our website, including your IP address, information about your visit, your device information and how you use our website.
                          </p>
                        </div>
                        <div class="col-sm-12 gap2">
                          <h2 class="head-text">PURPOSES FOR WHICH WE USE YOUR INFORMATION</h2>
                          <p>
                            We will use your personal information only for the following purposes:<br /><br />
                            •	To provide our services to you and to maintain our relationship<br />
                            •	To register you as a service provider or producer of electronic waste<br />
                            •	To confirm and verify your identity<br />
                            •	For maintaining the accuracy of your personal information<br />
                            •	To conduct research or, for statistical analysis<br />
                            •	For audit and record keeping purposes<br />
                            •	Social responsibility<br /><br />
                            We will also use your personal information to comply with legal and regulatory requirements or industry codes to which we subscribe, or which apply to us, or when it is otherwise allowed by law.
                          </p>
                        </div>
                        <div class="col-sm-12 gap2">
                          <h2 class="head-text">ONGOING SERVICES</h2>
                          <p>Given our responsibility to disseminate South Africa’s Waste Electronic and Electrical Equipment (WEEE) and Extended Producer Responsibility (EPR) Regulations and related developments, we will also be introducing various initiatives over the next few months. We would like to use your information to keep you informed about these services as well as share notices and engage on pertinent topics with you as they arise. Personal information will therefore be used for communications purposes as well.</p>
                        </div>
                        <div class="col-sm-12 gap2">
                          <h2 class="head-text">DISCLOSURE OF INFORMATION</h2>
                          <p>
                            We may disclose your personal information to our service providers who are involved in the delivery of services to you as well as government bodies who require this information for compliance purposes. We have agreements in place to ensure that they comply with these privacy terms.<br />We may share your personal information with:<br /><br />
                            •	Third parties for the purposes listed above<br />
                            •	For registering you with any government bodies in electronic waste for compliance purposes<br />
                            •	Our technology service providers for purposes of ensuring that all information related to you is captured on our system<br /><br />
                            We may also disclose your information:<br />
                            •	Where we have a duty or a right to disclose in terms of law or industry codes; or<br />
                            •	Where we believe it is necessary to protect our rights<br />
                          </p>
                        </div>
                        <div class="col-sm-12 gap2">
                          <h2 class="head-text">INFORMATION SECURITY</h2>
                          <p>
                            We are legally obliged to provide adequate protection for the personal information we hold and to stop unauthorised access and use of personal information. We will, on an ongoing basis, continue to review our security controls and related processes to ensure that your personal information is secure. Our security procedures cover:<br /><br />
                            •	Physical security<br />
                            •	Computer and network security<br />
                            •	Access to personal information<br />
                            •	Secure communications<br />
                            •	Acceptable usage of personal information<br />
                            •	Governance and regulatory issues<br />
                            •	Monitoring access and usage of personal information<br />
                            •	Investigating and reacting to security incidents<br /><br />
                            When we contract with third parties, we impose appropriate security, privacy and confidentiality obligations on them to ensure that the personal information that we are responsible for is kept secure.
                          </p>
                        </div>
                        <div class="col-sm-12 gap2">
                          <h2 class="head-text">YOUR RIGHTS</h2>
                          <p>We will take all reasonable steps to confirm your identity when you exercise your rights.</p>
                        </div>
                      </div>
                      <div class="col-sm-12 gap2">
                        <h3 class="sub-heading">Access to information</h3>
                        <p>You have the right to request a copy of the personal information we hold about you. To do this, you can contact erainfo@eranpc.co.za</p>
                      </div>
                      <div class="col-sm-12 gap2">
                        <h3 class="sub-heading">Objection to processing</h3>
                        <p>In certain cases, you have the right to object to the processing of your personal information. If you want to object, please contact us at erainfo@eranpc.co.za</p>
                      </div>
                      <div class="col-sm-12 gap2">
                        <h3 class="sub-heading">Correction of your information</h3>
                        <p>You have the right to ask us to update or correct your personal information. You may do this by contacting us at erainfo@eranpc.co.za</p>
                      </div>
                      <div class="col-sm-12 gap2">
                        <h3 class="sub-heading">Deletion of your information</h3>
                        <p>
                          You agree that we may keep your personal information until we destroy your information based on ERA’s destruction standards. You have the right to ask for deletion or destruction of your personal information – we will do that unless the law requires us to keep it or if we need it for legitimate business purposes. You may do this by contacting us at erainfo@eranpc.co.za
                        </p>
                      </div>
                      <div class="col-sm-12 gap2">
                        <h3 class="sub-heading">Automated decision making</h3>
                        <p>
                          You agree that we may process your personal information by using automated means (without human intervention in the decision-making process) to make a decision about you or your registration as a service provider or producer and you may query the decision made about you.
                        </p>
                      </div>
                      <div class="col-sm-12 gap2">
                        <h2 class="head-text">CHANGES TO THIS NOTICE</h2>
                        <p>Please note that we may amend this notice from time to time. Please check this website periodically to inform yourself of any changes.</p>
                      </div>
                      <div class="col-sm-12 gap2">
                        <h2 class="head-text">HOW TO CONTACT US</h2>
                        <p>If you have questions about this notice or believe we have not adhered to it, or need further information about our privacy practices, please contact us at erainfo@eranpc.co.za</p>
                      </div>
                      <div class="col-sm-12 gap2">
                        <h2 class="head-text">INFORMATION REGULATOR</h2>
                        <p>You have the right to complain to the Information Regulator, whose contact details are:</p>
                        <p>
                          http://www.justice.gov.za/inforeg/index.html <br />
                          General enquiries: enquiries@inforegulator.org.za <br />
                          Complaints: POPIAComplaints@inforegulator.org.za
                        </p>
                      </div>
                      </div>
                  </div>
                </div>

    <!-- .container -->
  </div><!-- #main -->

</div>

<div class="footer">
  <div class="footer-cont">
    <div class="footer-cont1">
      <img src="assets/nav-menu/Logos/a6bp9-vk0yp.svg" alt="Responsive image" class="img-fluid-logo" [routerLink]="['/']" />
    </div>
    <div class="footer-cont2">
      <div class="footer-tab">
        <div class="sect-1">
          <div class="sec">
            <button class="tab-button" (click)="p1('first','')">Home</button>
            <div class="dropdown">
              <button class="btn tab-button" (click)="p1('second','')">
                About ERA
              </button>
              <div class="dropdown-menu1">
                <a class="dropdown-item" (click)="p1('second','.governance')">Governance</a>
                <a class="dropdown-item" (click)="p1('second','.external-governance')">External Governance</a>
                <a class="dropdown-item" (click)="p1('second','.current-membership')">Current Membership Base</a>
                <a class="dropdown-item" (click)="p1('second','.epr-stakeholders')">EPR Stakeholders</a>
                <a class="dropdown-item" (click)="p1('second','.associations')">Associations</a>
              </div>
            </div>
          </div>
          <div class="sec">
            <div class="dropdown">
              <button class="btn tab-button" (click)="p1('third','')">
                About ERA's WEEE EPR Scheme
              </button>
              <div class="dropdown-menu1">
                <a class="dropdown-item" (click)="p1('third','.epr-scheme')">ERA WEEE EPR Scheme</a>
              </div>
            </div>
            <button class="tablinks" (click)="p1('fourth','')">Producer Portal</button>
          </div>
        </div>
        <div class="sect-1">
          <div class="sec">
            <button class="tablinks" (click)="p1('fifth','')">Service Provider Portal</button>
          </div>
          <div class="sec">
            <button class="tablinks" (click)="p1('seventh','')">Terminology</button>
            <button class="tablinks" (click)="p1('eighth','')">Articles and Events </button>
          </div>
        </div>
      </div>

    </div>
    <div class="footer-cont3">
      <div class="footer-cont3-con-n">
        <div class="footer-cont3-con-bt">
          <button class="footer-btn">
            <img src="assets/nav-menu/Logos/arz4i-m160o.svg" alt="Responsive image" class="img-fluid-logo1" [routerLink]="['/']" />
          </button>
        </div>
        <div class="footer-cont3-con-con">
          <button (click)="scrollTop('.main-content-section')" class="footer-btn">
            <img src="assets/nav-menu/Logos/a2m6t-9c3vl.svg" alt="Responsive image" class="img-fluid-logo2" [routerLink]="['/']" />
          </button>
        </div>
      </div>
      <div class="footer-cont3-con">
        <div class="dropdown">
          <button class="btn  me-2 ">
            Register
          </button>
          <div class="dropdown-menu1">
            <a class="dropdown-item" (click)="openRegister(register)">Producer Portal</a>
            <a class="dropdown-item" [routerLinkActive]="['link-active']" [routerLink]="['service-portal/service-portal/company-details']">Service Provider Portal</a>
          </div>
        </div>
        <div class="dropdown">
          <button type="button" class="btn  me-3" (click)="openLogin(login)">Sign In</button>
        </div>

      </div>
    </div>
  </div>
  <div class="footer-cont">
    <div class="footer-cont1">
      <button class="footer-spann2 footer-btn" (click)="p1('ninth','')">PRIVACY POLICY</button>
    </div>
    <div class="footer-cont2">
      <span class="footer-spann2">Copy right 2022 ERA</span>
    </div>
    <div class="footer-cont3">
      <span class="footer-spann2">All rights reserved</span>
    </div>
  </div>
</div><!-- #container -->
