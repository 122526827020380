<div *ngIf="options.complete else notComplete">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Registration Complete</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="close('cross clicked')"></button>
  </div>
  <div class="modal-body center">
    <p>Thank you for completing the registration process.</p>
    <p>An Email will be sent to the following email address with the next steps in this process.</p>
    <p><strong>{{entity.entityEmailAddress}}</strong></p>
  </div>
  <div class="form-actions row">
    <button class="button btn-primary col-11" type="button" value="Cancel" (click)="close('cancel clicked')">
      Close
    </button>
  </div>
</div>

<ng-template #notComplete>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Registration</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="close('cross clicked')"></button>
  </div>
  <div class="modal-body">
    <form class="form-group" [formGroup]="CompanyDetailsForm">
      <div class="col">
        <div class="row">
          <h2 class="sub-header">
            1. Company Information
          </h2>
        </div>
        <div class="row col-sm">
          <div class="col-6">
            <label class="required">Company - Registered Name</label>
            <input class="form-control" name="name" formControlName="name" type="text" required />
            <div class="input-error" *ngIf="CompanyDetailsForm.controls.name?.invalid && (CompanyDetailsForm.controls.name?.dirty || CompanyDetailsForm.controls.name?.touched)">
              <div *ngIf="CompanyDetailsForm.controls.name?.errors?.required">
                Company Registered Name is required.
              </div>
            </div>
          </div>
          <div class="col-6">
            <label>Company - Trade Name</label>
            <input class="form-control" name="surname" formControlName="surname" type="text" />
          </div>
        </div>
        <div class="row col-sm">
          <div class="col-6">
            <label>Company DFFE Producer Registration Number</label>
            <input class="form-control" name="passport" formControlName="passport" type="text" />
          </div>
          <div class="col-6">
            <label>Company - Registration Number</label>
            <input class="form-control" name="identity" formControlName="identity" type="text" />
          </div>
        </div>
        <div class="row col-sm">
          <div class="col-6">
            <label>Company - Country of Registration</label>
            <select class="form-control" name="countryOfRegistration" formControlName="countryOfRegistration">
              <option *ngFor="let c of countries" [ngValue]="c.value">
                {{c.value}}
              </option>
            </select>
          </div>
          <div class="col-6">
            <label>Company - Year Established</label>
            <select class="form-control" name="yearEstablished" formControlName="yearEstablished">
              <option *ngFor="let y of years; let i = index;" [ngValue]="y">
                {{y}}
              </option>
            </select>
          </div>
        </div>
        <div class="row col-sm">
          <div class="col-6">
            <label>Company - Physical Address</label>
            <input class="form-control" name="physicalAddress" formControlName="physicalAddress" type="text" />
          </div>
          <div class="col-6">
            <label>Company - Postal Address</label>
            <input class="form-control" name="postalAddress" formControlName="postalAddress" type="text" />
          </div>
        </div>
        <div class="row col-sm">
          <div class="col-6">
            <label>Company - Telephone Number</label>
            <input class="form-control" name="telephoneNumber" formControlName="telephoneNumber" type="text" />
            <div class="input-error" *ngIf="CompanyDetailsForm.controls.telephoneNumber?.invalid && (CompanyDetailsForm.controls.telephoneNumber?.dirty || CompanyDetailsForm.controls.telephoneNumber?.touched)">
              <div *ngIf="CompanyDetailsForm.controls.telephoneNumber?.errors?.pattern">
                Telephone Number can only be 10 digits.
              </div>
            </div>
          </div>
          <div class="col-6">
            <label>Company - Website Address</label>
            <input class="form-control" name="websiteAddress" formControlName="websiteAddress" type="text" />

            <div class="input-error" *ngIf="CompanyDetailsForm.controls.websiteAddress?.invalid && (CompanyDetailsForm.controls.websiteAddress?.dirty || CompanyDetailsForm.controls.websiteAddress?.touched)" >
              <div *ngIf="CompanyDetailsForm.controls.websiteAddress?.errors?.required">Please provide URl</div>
              <div *ngIf="CompanyDetailsForm.controls.websiteAddress?.errors?.pattern">Please provide valid URl</div>
            </div>

           
          </div>
        </div>
      </div>
    </form>

    <br />
    <br />
    <div class="col">
      <div class="row header-with-add">
        <h2 class="sub-header header-add">
          2. Contact Person/s
        </h2>
        <button class="btn-primary button add-button" aria-label="Add Contact Person" (click)="add_person();">
          Add Contact Person
        </button>
      </div>
      <div class="row content-container">
        <div class="table-container">
          <table class="table">
            <thead>
              <tr *ngIf="entity?.contactPersons?.length else noContactPersons">
                <th>Name</th>
                <th>Surname</th>
                <th>Designation</th>
                <th>Contact Number</th>
                <th>Email Address</th>
                <th></th>
              </tr>
              <ng-template #noContactPersons>
                <tr class="col-sm-12 center" colspan="6">No Contact Persons Found</tr>
              </ng-template>
            </thead>
            <tbody md-body>
              <tr *ngFor="let p of entity?.contactPersons; let i = index;">
                <td md-cell>
                  <input type="text" name="{{i}}_name" [value]="p.name" />
                </td>
                <td md-cell>
                  <input type="text" name="{{i}}_surname" [value]="p.surname" />
                </td>
                <td md-cell>
                  <input type="text" name="{{i}}_note" [value]="p.note" />
                </td>
                <td md-cell>
    
                  <input type="text" name="{{i}}_contactNumber" [value]="p.contactNumber"/>
                </td>
                <td md-cell>
                  <input type="text" name="{{i}}_emailAddress" flex [value]="p.emailAddress" />
                </td>
                <td md-cell>
                  <button class="icon btn-round" aria-label="Remove Contact Person" (click)="del_person(p, i);">
                    <img src="assets/icons/close_black_24dp.svg" class="icon" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <br />
    <br />
    <form [formGroup]="FurtherDetailsForm" class="form-group">
      <div class="col">
        <h2 class="sub-header row">
          3. Further Details
        </h2>
        <div class="row col-sm">
          <div class="col-6">
            <label>Name of Legal Compliance Manager</label>
            <input class="form-control" name="complianceManager" formControlName="complianceManager" type="text" />
          </div>
          <div class="col-6">
            <label>Contact Number for Legal Compliance Manager</label>
            <input class="form-control" name="complianceManagerNumber" formControlName="complianceManagerNumber" type="text" />
            <div class="input-error" *ngIf="FurtherDetailsForm.controls.complianceManagerNumber?.invalid && (FurtherDetailsForm.controls.complianceManagerNumber?.dirty || FurtherDetailsForm.controls.complianceManagerNumber?.touched)">
              <div *ngIf="FurtherDetailsForm.controls.complianceManagerNumber?.errors?.pattern">
                Number can only be 10 digits.
              </div>
            </div>
          </div>
        </div>
        <div class="row col-sm">
          <div class="col-6">
            <label>Email Address for Legal Compliance Manager</label>
            <input class="form-control" name="complianceManagerEmail" formControlName="complianceManagerEmail" type="text" />
            <div class="input-error" *ngIf="FurtherDetailsForm.controls.complianceManagerEmail?.invalid && (FurtherDetailsForm.controls.complianceManagerEmail?.dirty || FurtherDetailsForm.controls.complianceManagerEmail?.touched)">
              <div *ngIf="FurtherDetailsForm.controls.complianceManagerEmail?.errors?.pattern">
                Email in the incorrect format. eg(example@gmail.com)
              </div>
            </div>
          </div>
          <div class="col-6">
            <label>Name of Executive Director</label>
            <input class="form-control" name="executiveDirector" formControlName="executiveDirector" type="text" />
          </div>
        </div>
        <div class="row col-sm">
          <div class="col-6">
            <label>Location of further offices in South Africa</label>
            <select class="form-control" name="officeLocation" formControlName="officeLocation">
              <option *ngFor="let p of provinces" [ngValue]="p.value">
                {{p.value}}
              </option>
            </select>
          </div>
          <div class="col-6">
            <label>Places of Trade / Manufacture</label>
            <select class="form-control" name="tradeLocation" formControlName="tradeLocation">
              <option *ngFor="let p of provinces" [ngValue]="p.value">
                {{p.value}}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <h2 class="small-header row">
            Type of EEE Producer
          </h2>
        </div>
        <div class="row">
          <p class="disclaimer">Tick all the applicable boxes</p>
        </div>
        <div class="row col-sm">
          <div class="form-check col-2">
            <input class="form-check-input" type="checkbox" aria-label="Import / Retail / Distribute" name="brandOwner" formControlName="brandOwner" id="brandOwner">
            <label class="form-check-label" for="brandOwner">
              Brand Owner
            </label>
          </div>
          <div class="form-check col-2">
            <input class="form-check-input" type="checkbox" aria-label="Export" name="importer" formControlName="importer" id="importer">
            <label class="form-check-label" for="importer">
              Importer
            </label>
          </div>
          <div class="form-check col-2">
            <input class="form-check-input" type="checkbox" aria-label="Create?" name="licencedAgent" formControlName="licencedAgent" id="licencedAgent">
            <label class="form-check-label" for="licencedAgent">
              Licenced Agent
            </label>
          </div>
          <div class="form-check col-2">
            <input class="form-check-input" type="checkbox" aria-label="Manufacturer" name="manufacturer" formControlName="manufacturer" id="manufacturer">
            <label class="form-check-label" for="manufacturer">
              Manufacturer
            </label>
          </div>
          <div class="form-check col-2">
            <input class="form-check-input" type="checkbox" aria-label="reseller" name="reseller" formControlName="reseller" id="reseller">
            <label class="form-check-label" for="reseller">
              Refurbisher / Reseller
            </label>
          </div>
          <div class="form-check col-2">
            <input class="form-check-input" type="checkbox" aria-label="retailer" name="retailer" formControlName="retailer" id="retailer">
            <label class="form-check-label" for="retailer">
              Retailer
            </label>
          </div>
        </div>
      </div>
    </form>

    <br />
    <br />

    <form [formGroup]="FinancialInformationForm" class="form-group">
      <div class="col">
        <div class="row">
          <h2 class="sub-header">
            4. Financial Compliance Information
          </h2>
        </div>
        <div class="row col-sm">
          <div class="col-6">
            <label>Vat Number</label>
            <input class="form-control" name="vatNumber" formControlName="vatNumber" type="text" />
          </div>
          <div class="col-6">
            <label>SDL Number</label>
            <input class="form-control" name="sDLNumber" formControlName="sDLNumber" ng-pattern="'[0-9]*'" type="text" />
          </div>
        </div>
        <div class="row col-sm">
          <div class="col-6">
            <label>Tax Number</label>
            <input class="form-control" name="taxNumber" formControlName="taxNumber" type="text" />
          </div>
          <div class="col-6">
            <label>Financial Year End (month)</label>
            <select class="form-control" name="financialYearEnd" formControlName="financialYearEnd">
              <option *ngFor="let m of months" [ngValue]="m">
                {{m}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>

    <br />
    <br />
    <form [formGroup]="CurrentWEEERecyclingForm" class="form-group">
      <div class="col">
        <div class="row">
          <h2 class="sub-header">
            5. Current WEEE Recycling
          </h2>
        </div>
        <div class="row">
          <p class="disclaimer">Can you please provide a brief summary of how you currently manage your WEEE</p>
        </div>
        <div class="row col-sm">
          <div class="col">
            <label>Describe WEEE Management Method(s)</label>
            <textarea class="form-control" name="wEEEManagementMethod" formControlName="wEEEManagementMethod" maxlength="150" rows="3"></textarea>
            <div class="input-error" *ngIf="CurrentWEEERecyclingForm.controls.wEEEManagementMethod?.invalid && (CurrentWEEERecyclingForm.controls.wEEEManagementMethod?.dirty || CurrentWEEERecyclingForm.controls.wEEEManagementMethod?.touched)">
              <div *ngIf="CurrentWEEERecyclingForm.controls.wEEEManagementMethod?.errors?.required">
                Max length is 150 characters.
              </div>
            </div>
          </div>
        </div>
        <div class="row col-sm">
          <div class="col">
            <label>WEEE Management Service Provider Name</label>
            <input class="form-control" name="serviceProvider" formControlName="serviceProvider" type="text" />
          </div>
        </div>

        <div class="row">
          <h2 class="small-header">
            Estimated Annual WEEE Tonnage Processed
          </h2>
        </div>
        <div class="row clol-sm">
          <div class="col-6">
            <label>Directly</label>
            <input class="form-control" name="wEEETonnageDirectlyProcessed" formControlName="wEEETonnageDirectlyProcessed" step="0.01" type="number" />
          </div>
          <div class="col-6">
            <label>Via Service Provider/s</label>
            <input class="form-control" name="wEEETonnageViaServiceProvider" formControlName="wEEETonnageViaServiceProvider" step="0.01" type="number" />
          </div>
        </div>
      </div>
    </form>
    <br />
    <br />


    <div class="col">
      <div class="row">
        <h2 class="sub-header">
          6. Document Uploads
        </h2>
      </div>
      <input type="file" class="upload-input" #add_requirement_attachment [hidden]="true" (change)="addRequirementFiles($event)" multiple />
      <div class="table-container row" >
        <table class="table" >
          <thead >
            <tr *ngIf="entity?.requirements?.length else noRequirements">
              <th></th>
              <th>Requirement Name</th>
              <th>Uploaded On</th>
            </tr>
            <ng-template #noRequirements>
              <tr class="col-sm-12 center" colspan="3">No requirements Found</tr>
            </ng-template>
          </thead>
          <tbody>
            <tr *ngFor="let s of entity?.requirements | slice : 0 : 4; let i = index;" ng-cloak>
              <td class="requirement-buttons">
                <button class="requirement-button icon btn-round" aria-label="Upload Attachment" [disabled]="options.uploading" (click)="options.requirementClicked = s; add_requirement_attachment.click()">
                  <img src="assets/icons/file_upload_black_24dp.svg" alt="search">
                </button>
                <div class="dropdown" *ngIf="s.attachments?.length">
                  <button class="requirement-button icon btn-round dropdown-toggle" id="navbarAbout" role="button" data-bs-toggle="dropdown" aria-label="Download Attachments" [attr.aria-expanded]="isAboutExpanded">
                    <img src="assets/icons/file_download_black_24dp.svg" alt="download">
                  </button>
                  <ul class="dropdown-menu" style="margin-left: -60px;" aria-labelledby="navbarAbout">
                    <li *ngFor="let item of s.attachments; let index = index; " style="width: 300px">
                      <button style="width: 80%">
                        {{item.name}}
                      </button>
                      <button style="float: right; margin-right: 5px;" class="icon" aria-label="Delete Attachment" (click)="attachments_delete(s, index)">
                        <img src="assets/icons/close_black_24dp.svg" class="icon" />
                      </button>
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                {{s.requirementObject.name}}
              </td>
              <td md-cell>
                {{(s.attachments?.length)? (s.attachments[0].CreatedOn | date: 'dd/MM/yyyy') : '' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <form [formGroup]="EntityDetails" class="form-group">
      <div class="col">
        <div class="row">
          <h2 class="sub-header extra-margin-top">
            7. Person Completing the Form
          </h2>
        </div>
        <div class="row clol-sm">
          <div class="col-6">
            <label class="required">Name</label>
            <input class="form-control" name="name" formControlName="name" required type="text" />
            <div class="input-error" *ngIf="EntityDetails.controls.name?.invalid && (EntityDetails.controls.name?.dirty || EntityDetails.controls.name?.touched)">
              <div *ngIf="EntityDetails.controls.name?.errors?.required">
                Name is required.
              </div>
            </div>
          </div>
          <div class="col-6">
            <label>Surname</label>
            <input class="form-control" name="surname" formControlName="surname" type="text" />
          </div>
        </div>
        <div class="row clol-sm">
          <div class="col">
            <label>Designation</label>
            <input class="form-control" name="note" formControlName="note" type="text" />
          </div>
        </div>
        <div class="row clol-sm">
          <div class="col-6">
            <label class="required">Contact Number</label>
            <input class="form-control" name="entityContactNumber" formControlName="entityContactNumber" required type="text" />
            <div class="input-error" *ngIf="EntityDetails.controls.entityContactNumber?.invalid && (EntityDetails.controls.entityContactNumber?.dirty || EntityDetails.controls.entityContactNumber?.touched)">
              <div *ngIf="EntityDetails.controls.entityContactNumber?.errors?.pattern">
                Contact Number can only be 10 digits.
              </div>
            </div>
          </div>
          <div class="col-6">
            <label>Email Address</label>
            <input class="form-control" name="entityEmailAddress" formControlName="entityEmailAddress" (change)="check_duplicate()" type="text" />
            <div class="input-error" *ngIf="(EntityDetails.controls.entityEmailAddress?.invalid && (EntityDetails.controls.entityEmailAddress?.dirty || EntityDetails.controls.entityEmailAddress?.touched)) || options.duplicate">
              <div *ngIf="EntityDetails.controls.entityEmailAddress?.errors?.required">
                Email Address is required.
              </div>
              <div *ngIf="EntityDetails.controls.entityEmailAddress?.errors?.pattern">
                Email Address is incorrectly formated (eg. example@gmail.com)
              </div>
              <div *ngIf="options.duplicate">
                Email Address already exists as user.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <p class="disclaimer">Who warrants that s/he is an authorised representative of the above company and confirm that the information provided on this form is true and correct at date of submition.</p>
        </div>
      </div>
    </form>
    <div class="form-actions row">
      <button flex class="col-6 button btn-primary" (click)="save();" type="button" [disabled]="options.saving || !FurtherDetailsForm.valid || !CompanyDetailsForm.valid|| !FinancialInformationForm.valid || !CurrentWEEERecyclingForm.valid|| !EntityDetails.valid">
        Submit
      </button>
      <button class="col-6 button" type="button" value="Cancel" (click)="close('cancel clicked')">
        Cancel
      </button>
    </div>
  </div>
</ng-template>
