<div class="page-cont">
  <div class="site-content">
    <div class="page-body">
      <div class="section-header">
        Section 6: WEEE Dismantling and Consolidation
      </div>
      <form [formGroup]="DismantlingForm" class="form-group" autocomplete="off">
        <div class="top-header">
          <h5 class="pb">Please note: ONLY If you are involved in the dismantling and consolidation of WEEE, complete this section:</h5>
          <span>1. Enter the relevant information into the empty fields in the table below:</span>
        </div>
         <div class="form-table">
          <div class="header center">
            Number of Employees: Main Site
          </div>
          <div *ngFor="let i of sites; let index = index;">
            <table class="table" name="myTable">
              <tr class="first-col">
                <th class="first-row">Personal Company</th>
                <th class="center">Total</th>
                <th class="center">Full time</th>
                <th class="center">Part time</th>
                <th class="center">Male</th>
                <th class="center">Female</th>
                <th class="center">Youth</th>
                <th class="center">Living with Disability</th>
              </tr>
              <tr>
                <th>Director</th>
                <td><input type="number" name="totalNoDirectors{{i}}" formControlName="totalNoDirectors{{i}}" /></td>
                <td><input type="number" name="fullTimeDirectors{{i}}" formControlName="fullTimeDirectors{{i}}" /></td>
                <td><input type="number" name="partTimeDirectors{{i}}" formControlName="partTimeDirectors{{i}}" /></td>
                <td><input type="number" name="maleDirectors{{i}}" formControlName="maleDirectors{{i}}" /></td>
                <td><input type="number" name="femaleDirectors{{i}}" formControlName="femaleDirectors{{i}}" /></td>
                <td><input type="number" name="youthDirectors{{i}}" formControlName="youthDirectors{{i}}" /></td>
                <td><input type="number" name="disabledDirectors{{i}}" formControlName="disabledDirectors{{i}}" /></td>
              </tr>
              <tr>
                <th>Management (excl.directors)</th>
                <td><input type="number" name="totalNoManagers{{i}}" formControlName="totalNoManagers{{i}}" /></td>
                <td><input type="number" name="fullTimeManagers{{i}}" formControlName="fullTimeManagers{{i}}" /></td>
                <td><input type="number" name="partTimeManagers{{i}}" formControlName="partTimeManagers{{i}}" /></td>
                <td><input type="number" name="maleManagers{{i}}" formControlName="maleManagers{{i}}" /></td>
                <td><input type="number" name="femaleManagers{{i}}" formControlName="femaleManagers{{i}}" /></td>
                <td><input type="number" name="youthManagers{{i}}" formControlName="youthManagers{{i}}" /></td>
                <td><input type="number" name="disabledManagers{{i}}" formControlName="disabledManagers{{i}}" /></td>
              </tr>
              <tr>
                <th>Skilled Stuff</th>
                <td><input type="number" name="totalNoSkilledStuff{{i}}" formControlName="totalNoSkilledStuff{{i}}" /></td>
                <td><input type="number" name="fullTimeSkilledStuff{{i}}" formControlName="fullTimeSkilledStuff{{i}}" /></td>
                <td><input type="number" name="partTimeSkilledStuff{{i}}" formControlName="partTimeSkilledStuff{{i}}" /></td>
                <td><input type="number" name="maleSkilledStuff{{i}}" formControlName="maleSkilledStuff{{i}}" /></td>
                <td><input type="number" name="femaleSkilledStuff{{i}}" formControlName="femaleSkilledStuff{{i}}" /></td>
                <td><input type="number" name="youthSkilledStuff{{i}}" formControlName="youthSkilledStuff{{i}}" /></td>
                <td><input type="number" name="disabledSkilledStuff{{i}}" formControlName="disabledSkilledStuff{{i}}" /></td>
              </tr>
              <tr>
                <th>General Workers</th>
                <td><input type="number" name="totalNoGeneralWorkers{{i}}" formControlName="totalNoGeneralWorkers{{i}}" /></td>
                <td><input type="number" name="fullTimeGeneralWorkers{{i}}" formControlName="fullTimeGeneralWorkers{{i}}" /></td>
                <td><input type="number" name="partTimeGeneralWorkers{{i}}" formControlName="partTimeGeneralWorkers{{i}}" /></td>
                <td><input type="number" name="maleGeneralWorkers{{i}}" formControlName="maleGeneralWorkers{{i}}" /></td>
                <td><input type="number" name="femaleGeneralWorkers{{i}}" formControlName="femaleGeneralWorkers{{i}}" /></td>
                <td><input type="number" name="youthGeneralWorkers{{i}}" formControlName="youthGeneralWorkers{{i}}" /></td>
                <td><input type="number" name="disabledGeneralWorkers{{i}}" formControlName="disabledGeneralWorkers{{i}}" /></td>
              </tr>
            </table>
          </div>
          <div class="center">
            <i (click)="add_site()" class="fa-solid fa-circle-plus" aria-hidden="true">Add Operation Site</i><br /><br />
          </div>
        </div>
      </form>
      <div class="footer-sec">
        <div class="page-scroll">
          <button class="prev" (click)="pt1();save()">PREVIOUS</button>
          <span>6/8</span>
          <button class="next" (click)="pt2();save()">NEXT</button>
          <progress value="75" max="100"></progress>
        </div>
      </div>
    </div>
  </div>
</div>
