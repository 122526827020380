
<form *ngIf="request" [formGroup]="resetpasswordForm" (ngSubmit)="req_change_password()" class="ng-pristine ng-invalid ng-touched">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Reset Password Request</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="close_modal('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="changepassword-box__form">
      <p>To reset your password, please enter your username:</p>
      <fieldset>
        <div class="hs-form-field">
          <label class="form-group__label requred">
            Username/Member No
          </label>
          <input autofocus="" id="Username" style="width: 100%;" class="form-group__control ng-pristine ng-invalid ng-touched" formControlName="username" required type="text">
        </div>
        <br />
        <p>Select method of sending OTP below:</p>
        <br />
        <div class="form-group" style="display: inline-flex;">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="method" formControlName="method" id="Email" value="Email">
            <label class="form-check-label" for="Email">Email</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="method" formControlName="method" id="SMS" value="SMS">
            <label class="form-check-label" for="SMS">SMS</label>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" style="float: right;" [disabled]="busy || !resetpasswordForm.valid" class="button button--dark-blue">CONTINUE</button>
  </div>
</form>

<form *ngIf="!request" [formGroup]="changepasswordForm" (ngSubmit)="reset_password()" class="ng-pristine ng-invalid ng-touched">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Change Password</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="close_modal('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="changepassword-box__form">
      <h2>Hi</h2>
      <p>We have sent an OTP to your preferred email address</p>
      <p>Please enter the OTP you have received below:</p>
      <fieldset class="form-columns-2">
        <div class="hs-form-field">
          <label class="form-group__label required">
            OTP
          </label>
          <input autofocus="" autocomplete="new-password" id="otp" class="form-group__control ng-pristine ng-invalid ng-touched" formControlName="otp" required type="text">
        </div>
      </fieldset>
      <p><strong>Create a secure password</strong> with at least 8 characters, including at least 1 number, 1 upper and 1 lower case letter.</p>
      <fieldset class="form-columns-2">
        <div class="hs-form-field">
          <label class="required" for="password1">Password</label>
          <input autocomplete="new-password" id="newpassword" class="form-group__control ng-pristine ng-invalid ng-touched" type="password" formControlName="newpassword" pattern="^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$" required />
          <div class="input-error" *ngIf="changepasswordForm.controls.newpassword?.invalid && (changepasswordForm.controls.newpassword?.dirty || changepasswordForm.controls.newpassword?.touched)">
            <div *ngIf="changepasswordForm.controls.newpassword?.errors?.pattern">
              Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
              letter and 1 number.
            </div>
          </div>
        </div>
        <div class="hs-form-field">
          <label class="required" for="password2">Confirm Password</label>
          <input id="confirmpassword" class="form-group__control ng-pristine ng-invalid ng-touched" type="password" formControlName="confirmpassword" required />
          <div class="input-error" *ngIf="changepasswordForm.controls.confirmpassword?.invalid && (changepasswordForm.controls.confirmpassword?.dirty || changepasswordForm.controls.confirmpassword?.touched)">
            <div *ngIf="changepasswordForm.controls.confirmpassword?.errors?.pattern">
              Password is required.
            </div>
            <div *ngIf="changepasswordForm.controls.confirmpassword?.errors?.confirmedValidator">
              Password and confirm password must be match.
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" style="float: right;" [disabled]="busy" class="button button--dark-blue">RESET PASSWORD</button>
  </div>
</form>
