<div *ngIf="property$ | async as property else loading">
  <div class="px-4 py-5 text-center page-hero flex-column justify-content-center">
    <h1 class="display-5 fw-bold">{{property.entityFullName}} {{property.instance}}</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">{{property.address}}</p>
      <!--<div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <button type="button" class="btn btn-primary btn-lg px-4 gap-3">Primary button</button>
        <button type="button" class="btn btn-outline-secondary btn-lg px-4">Secondary</button>
      </div>-->
    </div>
  </div>
  <div class="page-wrapper text-center">
    <div class="page-content">
      <form class="from-body" [formGroup]="paymentForm">
        <div class="content-body">
          <div class="property-tile">
            <div class="tile-content">
              <h5>{{property.entityFullName}}</h5>
              <div class="space-between">
                <p>Funding Target:</p>
                <p>{{property.target | currency: 'ZAR': 'symbol-narrow': '1.0-0'}}</p>
              </div>
              <div class="progress-bar-container">
                <div class="space-between">
                  <p class="balance-text bold" [ngStyle]="{color: progress(property.balance, property.target) < 1 ? '#005bff' : '#31ff00'}">{{progress(property.balance, property.target) | percent: '1.0-2'}} Funded</p>
                  <a class="small-text" href="">What's this?</a>
                </div>
                <div class="progress-bar">
                  <div class="progress" [ngStyle]="setProgressStyle(progress(property.balance, property.target))"></div>
                </div>
              </div>
              <div class="space-between">
                <span class="small-text">
                  Internal Rate of Return (IRR)
                  <i class="fa-solid fa-info-circle"><span class="tooltiptext">Combination of expected yield and assumed capital growth per year (after all costs). Please refer to the prospectus for details on the assumptions used in the calculation.</span></i>
                </span>
                <p class="small-text bold">{{property.rateOfReturn | percent: '1.0-2'}}</p>
              </div>
              <div class="space-between">
                <span class="small-text">
                  Est Net Rental Yield
                  <i class="fa-solid fa-info-circle"><span class="tooltiptext">Estimated annual rental received, less estimated expenses divided by purchase price</span></i>
                </span>
                <p class="small-text bold">{{property.netRentalYield | percent: '1.0-2'}}</p>
              </div>
              <div class="space-between">
                <span class="small-text">
                  Market Valuation
                  <i class="fa-solid fa-info-circle"><span class="tooltiptext">Determined by the most recent sales value of equivalent units in the property or by recent independent valuations of the units.</span></i>
                </span>
                <p class="small-text bold">{{property.marketValuation | currency: 'ZAR': 'symbol-narrow': '1.0-0'}}</p>
              </div>
              <div class="space-between">
                <span class="small-text">
                  Discount
                  <i class="fa-solid fa-info-circle"><span class="tooltiptext">Percentage of bulk purchase discount  achieved vs market valuation</span></i>
                </span>
                <p class="small-text bold">{{property.discount | percent: '1.0-2'}}</p>
              </div>
              <div class="mb-3">
                <label for="amountInput" class="form-label">Investment Amount:</label>
                <input id="amountInput" type="text" class="form-control" currencyMask formControlName="amount" [options]="{ prefix: 'R ', thousands: ' ', decimal: '.' }" />
              </div>
              <div class="center">
                <p class="small-text">{{property.note}}</p>
              </div>
            </div>
          </div>
          <div class="payment-tile">
            <div class="tile-content">
              <h5>Payment Options</h5>
              <div class="payment-options">
                <div *ngFor="let option of paymentMethods">
                  <input id="payment_method_{{option.method}}_{{option.type}}" class="btn-check" type="radio" [value]="option" formControlName="method">
                  <label class="btn btn-outline-primary" for="payment_method_{{option.method}}_{{option.type}}">{{option.text}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-primary" type="button" [disabled]="!paymentForm.valid" (click)="makePayment(property.instance)">
            Proceed to Payment
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #loading>
  Loading...
</ng-template>
