<div class="page-wrapper text-center">
  <div class="page-head">
    <h1 class="head">
      Browse Our Properties
    </h1>
    <p>
      Take your time to browse through this selection of property investments. Each of our properties have been carefully chosen. You can invest as little or as much as you like. Don’t miss your chance to start creating long term wealth through investing in property!
    </p>
  </div>
  <div class="button-container">
    <div class="toggle-links">
      <a class="active">New Listings</a>
      <a>Auctions</a>
    </div>
  </div>
  <div class="page-content">
    <div class="content-head">
      <h6>Properties</h6>
    </div>
    <div class="content-filter">
      <div class="filter">
        <label for="sort-by" class="space-right-10">Sort by:</label>
        <select id="sort-by" name="sort-by" class="">
          <option>Sort by</option>
          <option>Market Valuation</option>
          <option>Internal Rate of Return</option>
          <option>Est Net Rental Yield</option>
        </select>
      </div>
      <div class="filter">
        <label for="region" class="space-right-10">Filter by Region:</label>
        <select id="region" name="region" class="">
          <option>All Regions</option>
          <option>Boksburg</option>
          <option>Cape Town</option>
          <option>Foreshore</option>
        </select>
      </div>
      <div class="filter">
        <label for="type" class="space-right-10">Type:</label>
        <select id="type" name="type" class="">
          <option>All Types</option>
          <option>Coastal Investment Appartments</option>
          <option>Multi-Family</option>
          <option>Single Unit</option>
        </select>
      </div>
      <div class="filter">
        <label for="status" class="space-right-10">Status:</label>
        <select id="status" name="status" class="">
          <option>All</option>
          <option>New Listing</option>
          <option>Recently Funded</option>
        </select>
      </div>
    </div>
    <ng-template #noProperties class="text-end">
    <div class="content-body">
        <p>No properties found.</p>
      </div>
    </ng-template>
    <div class="content-body" *ngIf="properties$ | async as properties else noProperties">
      <div class="property-tile" *ngFor="let property of properties">
        <div class="property-tile-img">
          <img class="property-img" [src]="property.photoUri" />
        </div>
        <div class="tile-content">
          <h5>{{property.entityFullName}}</h5>
          <div class="space-between">
            <p>Funding Target:</p>
            <p>{{property.target | currency: 'ZAR': 'symbol-narrow': '1.0-0'}}</p>
          </div>
          <div class="progress-bar-container">
            <div class="space-between">
              <p class="balance-text bold" [ngStyle]="{color: progress(property.Balance, property.Target) < 1 ? '#005bff' : '#31ff00'}">{{progress(property.balance, property.target) | percent: '1.0-2'}} Funded</p>
              <a class="small-text" href="">What's this?</a>
            </div>
            <div class="progress-bar">
              <div class="progress" [ngStyle]="setProgressStyle(progress(property.Balance, property.Target))"></div>
            </div>
          </div>
          <div class="space-between">
            <span class="small-text">
              Internal Rate of Return (IRR)
              <i class="fa-solid fa-info-circle"><span class="tooltiptext">Combination of expected yield and assumed capital growth per year (after all costs). Please refer to the prospectus for details on the assumptions used in the calculation.</span></i>
            </span>
            <p class="small-text bold">{{property.rateOfReturn | percent: '1.0-2'}}</p>
          </div>
          <div class="space-between">
            <span class="small-text">
              Est Net Rental Yield
              <i class="fa-solid fa-info-circle"><span class="tooltiptext">Estimated annual rental received, less estimated expenses divided by purchase price</span></i>
            </span>
            <p class="small-text bold">{{property.netRentalYield | percent: '1.0-2'}}</p>
          </div>
          <div class="space-between">
            <span class="small-text">
              Market Valuation
              <i class="fa-solid fa-info-circle"><span class="tooltiptext">Determined by the most recent sales value of equivalent units in the property or by recent independent valuations of the units.</span></i>
            </span>
            <p class="small-text bold">{{property.marketValuation | currency: 'ZAR': 'symbol-narrow': '1.0-0'}}</p>
          </div>
          <div class="space-between">
            <span class="small-text">
              Discount
              <i class="fa-solid fa-info-circle"><span class="tooltiptext">Percentage of bulk purchase discount  achieved vs market valuation</span></i>
            </span>
            <p class="small-text bold">{{property.discount | percent: '1.0-2'}}</p>
          </div>
          <div class="button-group">
            <button class="btn btn-primary" [routerLink]="['.', property.instance]" [state]="property">View Property</button>
          </div>
          <div class="center">
            <p class="small-text">{{property.note}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-foot">
    <div>
      <span><b>Please note:</b></span>
      <p> The images used are for illustrative purposes only and may include artist's impressions. They may be used to illustrate the property type and may not represent the actual property/ies. No reliance must be placed on any images displayed. </p>
    </div>
  </div>
</div>



