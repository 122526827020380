import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';
import { ApiAccessService } from '../../api-access.service';
import { Attachment } from '../../models/attachment.model';
import { Entity } from '../../models/entity.model';
import { Registration } from '../../models/registration.model';
import { RequirementDetail } from '../../models/requirement-detail.model';
import { Requirement } from '../../models/requirement.model';
import { SystemCode, SystemCodeModel } from '../../models/system-code.model';
import { navbarData } from '../sidenav/nav-data';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { forEachChild } from 'typescript';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.css']
})
export class UploadDocumentsComponent implements OnInit {
  @Output()
  close_model = new EventEmitter<string>();

  UploadDocumentsForm: FormGroup;
  EntityDetails: FormGroup;
  entity: Registration;
  navData = navbarData;

  provinces: SystemCode[];
  countries: SystemCode[];
  months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  years: number[];
  options: {
    saving: boolean,
    duplicate: boolean,
    got_system_codes: boolean,
    uploading: boolean,
    complete: boolean,
    requirementClicked: RequirementDetail,
  }

  constructor(private router: Router, private apiService: ApiAccessService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log("route changed");
        this.save();
      }
    });
}

  ngOnInit(): void {
    this.reset_forms();
    this.get_system_codes();
  }

  get_system_codes() {
    if (!this.options.got_system_codes) {
      let s: SystemCodeModel[] = [
        { name: "provinces", context: "Address", field: "Province", active: true },
        { name: "countries", field: "Country", active: true }
      ];
      let temp = this.apiService.get_system_codes(s).subscribe(x => {
        this.apiService.get_requirements(0, { service: "entity", category: "6. Document Uploads" }).subscribe(x => {
          var obj = sessionStorage.getItem('documents');
          if (obj) {
            var ob = JSON.parse(obj);
            this.entity.requirements = ob;
          }
          else {
            this.entity.requirements = x;
          }
        });
        temp.unsubscribe();
      });
    }
  }

  reset_forms() {
    this.entity = {
      attachments: [],
      company: {},
      companyEntity: {},
      contactPersons: [],
      entity: {},
      requirements: []
    };
    this.options = {
      saving: false,
      duplicate: false,
      got_system_codes: false,
      requirementClicked: null,
      uploading: false,
      complete: false
    };
    
  }

  addRequirementFiles(event: any) {
    if (event.target.files.length > 0) {

      this.options.uploading = true;
      for (var i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        this.apiService.upload_temp_file(file).subscribe(temp => {
          event.target.value = null;
          //update message to say file loadded now unpacking
          let attachment: Attachment = {
            name: file.name,
            contentType: file.type,
            relativeName: "requirement",
            tempFile: temp.tempFileName,
            path: "a place",
            id: 0
          };
          var index = 0;
          this.options.requirementClicked.attachments.push(attachment);
          sessionStorage.setItem('documents', JSON.stringify(this.entity.requirements));
          this.options.uploading = false;
        }, error => {
          this.options.uploading = false;
        });
      }
    } else {
      this.options.uploading = false;
    }
  }

  attachments_zip(attach: Attachment, type: string) {
    this.apiService.get_file("attachment/zip/" + type + "/" + attach.id);
  }


  pt2() {
    this.router.navigate(['service-portal/service-portal/management'])
  }

  pt1() {
    this.router.navigate(['service-portal/service-portal/company-details'])
  }

  save() {

    if (this.options.requirementClicked ) {
          sessionStorage.setItem('UploadDocuments', "true");
        }
  }

}
