<div class="page-cont">
  <div class="site-content">
    <div class="page-body">
      <div class="section-header">
        Section 5: Waste Collector
      </div>
      <form [formGroup]="WasteCollectorForm" class="form-group" autocomplete="off">
        <h5 class="pb">Please note: Complete this section ONLY if you are a waste collector</h5>
        <h6>1. Which of the following Waste Collectors Associations are you registered with?</h6>
        <div class="sect4 pb">
          <div class="name"><select class="assos" name="wasteCollector" formControlName="wasteCollectorAssos">
          <option value="" disabled selected>-Select-</option>
          <option value="African Reclaimers Organisation">African Reclaimers Organisation</option>
          <option value="SA Waste Picker's Association">SA Waste Picker's Association</option>
          <option value="SAWIC SA Waste Information Centre">SAWIC SA Waste Information Centre</option>
          </select></div>
          <div class="name"><input type="text" name="wasteCollector" formControlName="wasteCollectorAssos" placeholder="Other(name)" /></div>
        </div>
        <h6>2. If you have selected an association above, please provide the following:</h6>
        <div class="second-sec">
          <label class="form-label2">2.1 Registration/Reference Number with Association</label><input type="text" name="wasteCollectorReferenceNo" formControlName="wasteCollectorReferenceNo" placeholder="Registration/Reference Number" /><br /><br />
          <label class="form-label2">2.2 Contact Name of Association</label><input type="text" name="wasteCollectorContactName" formControlName="wasteCollectorContactName" placeholder="Contact Name" /><br /><br />
          <label class="form-label2">2.3 Telephone Number of the Association</label><input type="text" name="wasteCollectorNumber" formControlName="wasteCollectorNumber" placeholder="Telephone Number" /><br /><br />
          <label class="form-label2">2.4 Email Address of the Association</label><input type="text" name="wasteCollectorEmail" formControlName="wasteCollectorEmail" placeholder="Email Address" /><br /><br />
        </div>
        <h6>3. How many Kg's of WEEE do you collect:</h6>
        <div class="third-sec">
          <label class="form-label2">3.1 Daily</label><input class="pd" type="number" name="dailyWasteCollection" formControlName="dailyWasteCollection" /> Kg<br /><br />
          <label class="form-label2">3.2 Weekly</label><input class="pd" type="number" name="weeklyWasteCollection" formControlName="weeklyWasteCollection" /> Kg<br /><br />
          <label class="form-label2">3.3 Monthly</label><input class="pd" type="number" name="monthlyWasteCollection" formControlName="monthlyWasteCollection" /> Kg<br /><br />
        </div>
        <h5 class="center pb">Transport and Logistics</h5>
        <div class="third-sec">
          <label class="form-label">4. Do you have your own transport</label><select type="text" name="personalTransport" formControlName="personalTransport"><option value="" disabled>Please select</option><option value="yes">Yes</option><option value="no">No</option></select><br /><br />
          <div>
            <label class="form-label2">4.1 If yes, what is your vehicle tonnage?</label><input class="pd" type="number" name="personalTransportTonnage" formControlName="personalTransportTonnage" /> Kg<br /><br />
          </div>
        </div>
        <div class="second-sec">
          <label class="form-label">5. What do you do with the WEEE that you collect?</label><input type="text" style="height:60px" name="wasteCollectionAction" formControlName="wasteCollectionAction" placeholder="Enter text here" /><br /><br />
          <label class="form-label">6. Where do you source your WEEE from?</label><input type="text" name="wasteCollectionSource" formControlName="wasteCollectionSource" placeholder="Enter text here" /><br /><br />
        </div>
        <h6>7. If you sell your WEEE, whom do you sell it to?</h6>
        <div class="second-sec">
          <label class="form-label2">7.1 Name of the Buying Company</label><input type="text" name="wasteBuyer" formControlName="wasteBuyer" placeholder="Enter text here" /><br /><br />
          <label class="form-label2">7.2 Name of the contact person at the Buying Company</label><input type="text" name="wasteBuyerName" formControlName="wasteBuyerName" placeholder="Enter text here" /><br /><br />
          <label class="form-label2">7.3 Telephone Number of the person at the Buying Company</label><input type="text" name="wasteBuyerNumber" formControlName="wasteBuyerNumber" placeholder="Enter number here" /><br /><br />
          <label class="form-label2">7.4 Email Address of the person at the Buying Company</label><input type="text" name="wasteBuyerEmail" formControlName="wasteBuyerEmail" placeholder="Enter text here" /><br /><br />
        </div>
        <div class="second-sec">
          <label class="form-label">8. Why do you sell your WEEE to this company?<br />Please provide a short statement.</label><input type="text" name="wasteBuyerReason" formControlName="wasteBuyerReason" placeholder="Enter text here" /><br /><br />
        </div>
        <div class="third-sec">
          <label class="form-label">9. Are you prepared to undertake training with ERA?</label><select type="text" name="training" formControlName="training"><option disabled>Please select</option><option placeholder="Please select" value="yes">Yes</option><option value="no">No</option></select><br /><br />
          <label class="form-label">10. Are you prepared to sign a Service Level Agreement with ERA?</label><select type="text" name="serviceAgreement" formControlName="serviceAgreement"><option disabled>Please select</option><option placeholder="Please select" value="yes">Yes</option><option value="no">No</option></select><br /><br />
          <label class="form-label">11. Are you prepared to wear ERA branded PPE?</label><select type="text" name="wearBrandedPPE" formControlName="wearBrandedPPE"><option disabled>Please select</option><option placeholder="Please select" value="yes">Yes</option><option value="no">No</option></select><br /><br />
        </div>
        <div class="footer-sec">
          <div class="page-scroll">
            <button class="prev" (click)="pt1();save()">PREVIOUS</button>
            <span>5/8</span>
            <button class="next" (click)="pt2();save()">NEXT</button>
            <progress value="62.5" max="100"></progress>
          </div>
          
        </div>
      </form>
    </div>
  </div>
</div>
