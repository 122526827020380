import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, AbstractControl, FormArray } from '@angular/forms';
import { ApiAccessService } from '../../api-access.service';
import { Attachment } from '../../models/attachment.model';
import { Entity } from '../../models/entity.model';
import { Registration } from '../../models/registration.model';
import { RequirementDetail } from '../../models/requirement-detail.model';
import { Requirement } from '../../models/requirement.model';
import { SystemCode, SystemCodeModel } from '../../models/system-code.model';
import { navbarData } from '../sidenav/nav-data';
import { Router, Event, NavigationStart,NavigationEnd } from '@angular/router';
import { forEachChild } from 'typescript';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})

export class CompanyDetailsComponent implements OnInit {
  @Output()
  close_model = new EventEmitter<string>();

  CompanyDetailsForm: FormGroup;
  EntityDetails: FormGroup;
  entity: Registration;
  navData = navbarData;
  tradeName: string;
  legalEntity: string;
  locations: string = "";
  public ob;

  provinces: SystemCode[];
  countries: SystemCode[];
  months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  years: number[];
  options: {
    saving: boolean,
    duplicate: boolean,
    got_system_codes: boolean,
    uploading: boolean,
    complete: boolean,
    requirementClicked: RequirementDetail,
  }

  constructor(private router: Router, private apiService: ApiAccessService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log("route changed");
        this.save();
      }
    });
  }

  ngOnInit(): void {
    this.CompanyDetailsForm = new FormGroup({
      wasteCollector: new FormControl(),
      recycler: new FormControl(),
      transporter: new FormControl(),
      legalEntity: new FormControl('', Validators.required),
      tradeName: new FormControl('', Validators.required),
      providerRegistration: new FormControl(),
      registeredAddress: new FormControl(),
      taxNumber: new FormControl(),
      vatNumber: new FormControl(),
      companyEmail: new FormControl('', [Validators.pattern("^[a-z0-9A-Z._-]+@[a-zA-Z0-9_-]+[.]{1,1}[a-zA-Z0-9._-]{2,10}$")]),
      financeEmail: new FormControl(),
      surname: new FormControl(),
      passport: new FormControl(),
      identity: new FormControl(),
      countryOfRegistration: new FormControl(),
      yearEstablished: new FormControl(0),
      yearsAsProvider: new FormControl(0),
      physicalAddress: new FormControl(),
      WEEEServicesLocations: new FormControl(""),
      websiteAddress: new FormControl('', [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
      ]),
    });
    this.reset_forms();
    this.get_system_codes();
  }

 

  get_system_codes() {
    if (!this.options.got_system_codes) {
      let s: SystemCodeModel[] = [
        { name: "provinces", context: "Address", field: "Province", active: true },
        ];
      let temp = this.apiService.get_system_codes(s).subscribe(x => {
        if (x["provinces"]) {
          this.provinces = x["provinces"];
          this.buildSkills();
        }
        this.options.got_system_codes = true;
        temp.unsubscribe(); 
      });   
    } 
  }


  reset_forms() {
    this.entity = {
      attachments: [],
      company: {},
      companyEntity: {},
      contactPersons: [],
      entity: {},
      requirements: []
    };
    this.options = {
      saving: false,
      duplicate: false,
      got_system_codes: false,
      requirementClicked: null,
      uploading: false,
      complete: false
    };
    
  }

  buildSkills() {
    for (var i = 0; i < this.provinces.length; i++) {
      this.CompanyDetailsForm.addControl(this.provinces[i].value, new FormControl(""));
    }
    if (sessionStorage.length > 0) {
      var obj = sessionStorage.getItem('companyDetailsform');
      var ob = JSON.parse(obj);
      this.CompanyDetailsForm.patchValue(ob);
      this.locations = this.CompanyDetailsForm.value.WEEEServicesLocations;
    }
  }

 addLocation(location) {
    var loc = this.locations;
      var check = loc.search(location);
      if (check > 0) {
        loc = loc.replace((":" + location), "");
        this.locations = loc;
      }
      else {
        this.locations = this.locations + ":" + location;
      } 
  }

  save() {
    this.CompanyDetailsForm.value.WEEEServicesLocations = this.locations;
    if (this.CompanyDetailsForm.value) {
      sessionStorage.setItem('companyDetailsform', JSON.stringify(this.CompanyDetailsForm.value));
      if (this.CompanyDetailsForm.valid) {
        sessionStorage.setItem('companyDetails', "true");
      }
    }
    
     }

 

  pt2() {
    this.router.navigate(['service-portal/service-portal/upload-documents'])
  }
}
