<div class="page-cont">
  <div class="site-content">
    <div class="page-body">
      <div class="section-header">
        Section 3: Management
      </div>
      <form [formGroup]="ManagementForm" class="form-group" autocomplete="off">
        <div class="pb">
          <h2>CEO/Managing Director</h2>
        </div>
        <div class="second-sec">
          <label class="form-label">CEO Full Name</label><input type="text" name="CEO" formControlName="CEO" placeholder="Enter Full Name" /><br /><br />
          <label class="form-label">Telephone Number(CEO)</label><input type="text" name="CEONumber" formControlName="CEONumber" placeholder="Enter Telephone Number" /><br /><br />
          <div class="input-error" *ngIf="ManagementForm.controls.CEONumber?.invalid && (ManagementForm.controls.CEONumber?.dirty || ManagementForm.controls.CEONumber?.touched)">
            <div *ngIf="ManagementForm.controls.CEONumber?.errors?.pattern">
              Contact Number can only be 10 digits.
            </div>
          </div>
          <label class="form-label">Email Address(CEO)</label><input type="text" name="CEOEmail" formControlName="CEOEmail" placeholder="Enter Email Address" /><br /><br />
        </div>
        <div class="pb">
          <h2>Technical Manager</h2>
        </div>
        <div class="second-sec">
          <label class="form-label required">Full Name(Technical Manager)</label><input type="text" name="technicalManager" formControlName="technicalManager" placeholder="Enter Full Name" required /><br /><br />
          <div class="input-error" *ngIf="ManagementForm.controls.technicalManager?.invalid && (ManagementForm.controls.technicalManager?.dirty || ManagementForm.controls.technicalManager?.touched)">
            <div *ngIf="ManagementForm.controls.technicalManager?.errors?.required">
              Technical Manager is required.
            </div>
          </div>
          <label class="form-label">Telephone Number(Technical Manager)</label><input type="text" name="technicalManagerNumber" formControlName="technicalManagerNumber" placeholder="Enter Telephone Number" /><br /><br />
          <div class="input-error" *ngIf="ManagementForm.controls.technicalManagerNumber?.invalid && (ManagementForm.controls.technicalManagerNumber?.dirty || ManagementForm.controls.technicalManagerNumber?.touched)">
            <div *ngIf="ManagementForm.controls.technicalManagerNumber?.errors?.pattern">
              Contact Number can only be 10 digits.
            </div>
          </div>
          <label class="form-label required">Email Address(Technical Manager)</label><input type="text" name="technicalManagerEmail" formControlName="technicalManagerEmail" placeholder="Enter Email Address" (change)="check_duplicate()" required /><br /><br />
          <div class="input-error" *ngIf="(ManagementForm.controls.technicalManagerEmail?.invalid && (ManagementForm.controls.technicalManagerEmail?.dirty || ManagementForm.controls.technicalManagerEmail?.touched)) || options.duplicate">
            <div *ngIf="ManagementForm.controls.technicalManagerEmail?.errors?.required || ManagementForm.controls.technicalManagerEmail?.errors?.pattern">
              Enter a valid email.
            </div>
             <div *ngIf="options.duplicate">
                Email Address already exists as user.
              </div>
          </div>

          <label class="form-label">Finance Manager(Technical Manager)</label><input type="text" name="financeManager" formControlName="financeManager" placeholder="Enter Finance Manager" /><br /><br />
        </div>

      </form>
      <div class="footer-sec">
        <div class="page-scroll">
          <button class="prev" (click)="pt1();save()">PREVIOUS</button>
          <span>3/8</span>
          <button class="next" (click)="pt2();save()">NEXT</button>
          <progress value="37.5" max="100"></progress>
        </div>
      </div>
    </div>
  </div>
</div>
