import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Address } from './models/address.model';
import { Bank } from './models/bank.model';
import { ContactNumber } from './models/contact-number.model';
import { Email } from './models/email.model';
import { Entity } from './models/entity.model';
import { PayGateRequest } from './models/paygate-request';
import { PaymentMethod } from './models/payment-method';
import { Property } from './models/property.model';
import { RegistrationData } from './models/registration-data.model';
import { SignInData } from './models/sign-in-data.model';
import { SystemUser } from './models/systemuser.model';
import { UserInfo } from './models/user-info.model';
import { Transaction } from './models/transaction.model';
import { SystemCode, SystemCodeModel } from './models/system-code.model';
import { EntityGood } from './models/entity-good.model';
import { Good } from './models/good.model';
import { Attachment } from './models/attachment.model';
import { TempFile } from './models/temp-file.model';
import { Registration } from './models/registration.model';
import { RequirementDetail } from './models/requirement-detail.model';
import { ResetPassword, ResetPasswordRequest, ResetPasswordResult } from './models/reset-password.model';

@Injectable({
  providedIn: 'root'
})
export class ApiAccessService {
  constructor(private http: HttpClient, @Inject("BASE_URL") private base_url: string) { }

  registration(data: RegistrationData) {
    return this.http.post<SystemUser>(this.base_url + "auth/registration", data);
  }

  user_info()  {
    return this.http.get<SystemUser>(this.base_url + "auth/user-info");
  }

  sign_in(data: SignInData) {
    return this.http.post<SystemUser>(this.base_url + "auth/sign-in", data);
  }

  sign_out() {
    return this.http.post(this.base_url + "auth/sign-out", {});
  }

  get_all_properties() {
    return this.http.get<Property[]>(this.base_url + "sales/all-objects-by-parent/VAP");
  }
  get_property(instance: number) {
    return this.http.get<Property>(this.base_url + `sales/get_dynamic_object/Flat/${instance}`);
  }

  createTransaction(instance: number, amount: number, method: PaymentMethod) {
    return this.http.post<PayGateRequest>(this.base_url + "entityproduct/with-object", { instance, amount, method });
  }

  get_user_information() {
    return this.http.get<UserInfo>(this.base_url + "entity/my-info");
  }

  save_address(address) {
    return this.http.post<Address>(this.base_url + "address/save", address);
  }

  save_email(email) {
    return this.http.post<Email>(this.base_url + "email/save", email);
  }

  save_contact_number(contact_number) {
    return this.http.post<ContactNumber>(this.base_url + "contactnumber/save", contact_number);
  }

  save_bank(bank) {
    return this.http.post<Bank>(this.base_url + "bank/save", bank);
  }

  del_address(id: number) {
    return this.http.post(this.base_url + `address/delete/${id}`, {});
  }

  del_email(id: number) {
    return this.http.post(this.base_url + `email/delete/${id}`, {});
  }

  del_contact_number(id: number) {
    return this.http.post(this.base_url + `contactnumber/delete/${id}`, {});
  }

  del_bank(id: number) {
    return this.http.post(this.base_url + `bank/delete/${id}`, {});
  }

  save_entity(entity) {
    return this.http.post<Entity>(this.base_url + "entity/update", entity);
  }

  getTransaction() {
    return this.http.get<Transaction>(this.base_url + "entityproduct/my-info");
  }

  get_system_codes(lst: SystemCodeModel[]) {
    return this.http.post<Map<string, SystemCode[]>>(this.base_url + "system-codes/collection", lst);
  }

  get_suburbs(value: string) {
    return this.http.get<SystemCode[]>(this.base_url + "system-codes/places?Value=" + value);
  }

  get_goods(period: string) {
    return this.http.get<EntityGood[]>(this.base_url + "entitygood/for-me/" + period);
  }

  find_goods(good: Good) {
    return this.http.post<Good[]>(this.base_url + "good/get", good);
  }

  create_entity_good(good: EntityGood) {
    return this.http.post<EntityGood>(this.base_url + "entitygood", good);
  }

  delete_entity_good(id: number) {
    return this.http.delete(this.base_url + "entitygood/" + id);
  }

  update_entity_good(good: EntityGood) {
    return this.http.post<EntityGood>(this.base_url + "entitygood/update", good);
  }

  get_upload_url() {
    return this.base_url.replace("https://", "wss://") + "entitygood/upload";
  }

  upload_temp_file(file: File) {
    var formData: any = new FormData();
    formData.append("tmpfile", file);
    return this.http.post<TempFile>(this.base_url + "/resources/temp", formData);
  }

  create_attachment(attachment: Attachment) {
    return this.http.post<Attachment>(this.base_url + "attachment/create/move", attachment);
  }

  get_file(url: string) {
    return this.http.get(this.base_url + url, { responseType: 'blob' as 'json', observe: 'response' }).subscribe((response: HttpResponse<Blob>) => {
      var fileNamePattern = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
      let dataType = response.headers.get('content-type');
      let binaryData = [];
      binaryData.push(response.body);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      downloadLink.setAttribute('download', fileNamePattern.exec(response.headers.get('content-disposition'))[1]);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    });
  }

  register(registration: Registration) {
    return this.http.post<Registration>(this.base_url + "entity/registration", registration);
  }

  get_requirements(id: number, req: RequirementDetail) {
    return this.http.post<RequirementDetail[]>(this.base_url + "requirementdetail/get/" + id, req);
  }

  check_duplicate_username(username: string) {
    return this.http.post(this.base_url + "auth/check-username", { Username: username});
  }

  request_reset_password(request: ResetPasswordRequest) {
    return this.http.post<ResetPasswordResult>(this.base_url + "auth/reset-password-request", request);
  }

  reset_password(value: ResetPassword) {
    return this.http.post(this.base_url + "auth/reset-password", value);
  }
}
