import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { PropertiesState } from "./properties.reducer";


export const selectProps = (state: AppState) => state.properties;

export const getProps = createSelector(
  selectProps,
  (state: PropertiesState) => state.properties
)
