import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';
import { ApiAccessService } from '../../api-access.service';
import { Attachment } from '../../models/attachment.model';
import { Entity } from '../../models/entity.model';
import { Registration } from '../../models/registration.model';
import { RequirementDetail } from '../../models/requirement-detail.model';
import { Requirement } from '../../models/requirement.model';
import { SystemCode, SystemCodeModel } from '../../models/system-code.model';
import { navbarData } from '../sidenav/nav-data';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { forEachChild } from 'typescript';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent implements OnInit {
  @Output()
  close_model = new EventEmitter<string>();

  ManagementForm: FormGroup;
  EntityDetails: FormGroup;
  entity: Registration;
  navData = navbarData;
  tradeName: string;

  provinces: SystemCode[];
  countries: SystemCode[];
  months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  years: number[];
  options: {
    saving: boolean,
    duplicate: boolean,
    got_system_codes: boolean,
    uploading: boolean,
    complete: boolean,
    requirementClicked: RequirementDetail,
  }

  constructor(private router: Router, private apiService: ApiAccessService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log("route changed");
        this.save();
      }
    });
}

  ngOnInit(): void {
    
    this.ManagementForm = new FormGroup({
      financeManager: new FormControl(),
      technicalManager: new FormControl(),
      technicalManagerNumber: new FormControl('', [Validators.pattern("^(\[0-9]{10})$")]),
      technicalManagerEmail: new FormControl('', [Validators.pattern('^[a-z0-9A-Z._-]+@[a-zA-Z0-9_-]+[.]{1,1}[a-zA-Z0-9._-]{2,10}$')]),
      CEO: new FormControl(),
      CEONumber: new FormControl('', [Validators.pattern("^(\[0-9]{10})$")]),
      CEOEmail: new FormControl('', [Validators.pattern('^[a-z0-9A-Z._-]+@[a-zA-Z0-9_-]+[.]{1,1}[a-zA-Z0-9._-]{2,10}$')]),
    });
    this.reset_forms();
  }

  reset_forms() {
    if (sessionStorage.length > 0) {
      var obj = sessionStorage.getItem('managementform');
      var ob = JSON.parse(obj);
      this.ManagementForm.patchValue(ob);
    }
    this.entity = {
      attachments: [],
      company: {},
      companyEntity: {},
      contactPersons: [],
      entity: {},
      requirements: []
    };
    this.options = {
      saving: false,
      duplicate: false,
      got_system_codes: false,
      requirementClicked: null,
      uploading: false,
      complete: false
    };

  }

  check_duplicate = function () {
    if (this.ManagementForm.value.technicalManagerEmail) {
      this.apiService.check_duplicate_username(this.ManagementForm.value.technicalManagerEmail).subscribe(successful => {
        this.options.duplicate = false;
      }, error => {
        this.options.duplicate = true;
      });
    }
  }

  save() {

    if (this.ManagementForm.value) {
      sessionStorage.setItem('managementform', JSON.stringify(this.ManagementForm.value));  
      if (this.ManagementForm.valid) {
        sessionStorage.setItem('Management', "true");
      }
    }

  }

  pt1() {
    this.router.navigate(['service-portal/service-portal/upload-documents'])
  }

  pt2() {
    this.router.navigate(['service-portal/service-portal/services-rendered'])
  }

}
