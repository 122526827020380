<div class="page-cont">
  <div class="site-content">
    <div class="page-body">
      <div class="section-header">
        Section 7: Site Information
      </div>
      <form [formGroup]="SiteInfoForm" class="form-group" autocomplete="off">
        <div class="second-sec">
          <label class="form-label">1. Please enter the name of your facility</label><input type="text" name="facilityName" formControlName="facilityName" placeholder="Enter text here" /><br /><br />
        </div>
        <div class="table-container" *ngFor="let i of sites; let index = index;">
          <table class="table pb" style="width:75%">
            <tr>
              <th></th>
              <th><b>Property Size(m2)</b></th>
            </tr>
            <tr>
              <th>What is your ERF Size?</th>
              <td><input type="number" name="ERFSize{{i}}" formControlName="ERFSize{{i}}" /></td>
            </tr>
            <tr>
              <th>What is your building size?</th>
              <td><input type="number" name="buildingSize{{i}}" formControlName="buildingSize{{i}}" /></td>
            </tr>
            <tr>
              <th>What is the size of the outside working area that is under a roof?</th>
              <td><input type="number" name="insideSpace{{i}}" formControlName="insideSpace{{i}}" /></td>
            </tr>
            <tr>
              <th>What is the size of the outside working area that in not under a roof?</th>
              <td><input type="number" name="outsideSpace{{i}}" formControlName="outsideSpace{{i}}" /></td>
            </tr>
          </table>
        </div>
        <div class="header center pb">
          <i (click)="add_site()" class="fa-solid fa-circle-plus" aria-hidden="true">Add Operation Site</i><br /><br />
        </div>
        <h5 class="center pb">Premises</h5>
        <div class="third-sec pb">
          <label class="form-label">Are these premises Leased or Owned</label><input type="checkbox" class="checkbox" name="lease" formControlName="lease" /><label class="checkbox-label">Leased</label><input type="checkbox" class="checkbox" name="owned" formControlName="owned" /><label class="checkbox-label">Owned</label><br /><br />
          <label class="form-label2">If leased, number of years left on lease</label><input class="number-input" type="number" name="yearsLeased" formControlName="yearsLeased" /> <span>Number of years</span><br /><br />
        </div>
        <h5 class="center pb">Security</h5>
        <div class="third-sec">
          <label class="form-label">Do you have physical security</label><input type="checkbox" class="checkbox" name="physicalSecurity" formControlName="physicalSecurity" /><label class="checkbox-label">Yes</label><input type="checkbox" class="checkbox" name="noPhysicalSecurity" formControlName="noPhysicalSecurity" /><label class="checkbox-label">No</label><br /><br />
        </div>
        <div class="pb">For each type of security, fill out the following:</div>
        <div class="table-container center">
          <table class="table" style="width:90%">
            <tr>
              <th>Day only</th>
              <th>Night only</th>
              <th>24 hrs</th>
            </tr>
            <tr>
              <td><select type="text" name="dayOnlySecurity" formControlName="dayOnlySecurity"><option>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <td><select type="text" name="nightOnlySecurity" formControlName="nightOnlySecurity"><option>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <td><select type="text" name="allDaySecurity" formControlName="allDaySecurity"><option>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
            </tr>
          </table>
        </div>
        <div class="third-sec">
          <label class="form-label">Do you have an alarm system?</label><input type="checkbox" class="checkbox" name="alarmSystem" formControlName="alarmSystem"/><label class="checkbox-label">Yes</label><input type="checkbox" class="checkbox" name="noAlarmSystem" formControlName="noAlarmSystem" /><label class="checkbox-label">No</label><br /><br />
          <label class="form-label">Do you have general alarm monitoring?</label><input type="checkbox" class="checkbox" name="alarmMonitoring" formControlName="alarmMonitoring"/><label class="checkbox-label">Yes</label><input type="checkbox" class="checkbox" name="noAlarmMonitoring" formControlName="noAlarmMonitoring" /><label class="checkbox-label">No</label><br /><br />
          <label class="form-label">Do you have armed response?</label><input type="checkbox" class="checkbox" name="alarmResponse" formControlName="alarmResponse"/><label class="checkbox-label">Yes</label><input type="checkbox" class="checkbox" name="noAlarmResponse" formControlName="noAlarmResponse" /><label class="checkbox-label">No</label><br /><br />
          <label class="form-label">Do you have insurance?</label><input type="checkbox" class="checkbox" name="insurance" formControlName="insurance"/><label class="checkbox-label">Yes</label><input type="checkbox" class="checkbox" name="noInsurance" formControlName="noInsurance" /><label class="checkbox-label">No</label><br /><br />
        </div>
        <div class="table-container1">
          <table class="table" style="width:70%">
            <tr>
              <th>Insurance Type</th>
              <th>Cover Amount(R)</th>
            </tr>
            <tr>
              <td><input type="text" name="insuranceType" formControlName="insuranceType" /></td>
              <td><input type="number" name="coverAmount" formControlName="coverAmount" /></td>
            </tr>
          </table>
        </div>
        <div>

        </div>
        <h5 class="center pb">Administration</h5>
        <div class="second-sec pb" style="margin:0">
          <label class="form-label">Number of offices?</label><input type="number" name="numberOffices" formControlName="numberOffices" /><br /><br />
        </div>
        <h5 class="center pb">Office Infrastructure</h5>
        <div class="table-container1 center">
          <table class="table center" style="width:70%">
             <tr>
              <th style="width:50%">Office Equipment</th>
              <td><select type="text" name="officeEquipment" formControlName="officeEquipment"><option>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
            </tr>
            <tr>
              <th>WiFi/Internet Connectivity</th>
              <td><select type="text" name="wiFi" formControlName="wiFi"><option>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
            </tr>
            <tr>
              <th>Printer/Scanner</th>
              <td><select type="text" name="printer" formControlName="printer"><option>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
            </tr>
            <tr>
              <th>PC/Laptops</th>
              <td><select type="text" name="laptops" formControlName="laptops"><option>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
            </tr>
          </table>
        </div>
        <h5 class="center pb">Systems</h5>
        <div class="pb"><span>Do you have any of the following systems?</span></div>
        <div class="table-container1 center">
          <table class="table center" style="width:70%">
             <tr>
              <th style="width:50%">Financial</th>
              <td><select type="text" name="financialSystem" formControlName="financialSystem"><option>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
            </tr>
            <tr>
              <th>Operational</th>
              <td><select type="text" name="operationalSystem" formControlName="operationalSystem"><option>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
            </tr>
            <tr>
              <th>Administration</th>
              <td><select type="text" name="administrationSystem" formControlName="administrationSystem"><option>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
            </tr>
          </table>
        </div>
        <h5 class="center pb">Logistics</h5>
        Vehicles:
        <div class="table-container2 center">
          <table class="table center" style="width:50%">
             <tr class="center" style="border:none">
              <th style="width:50%">Tonnage</th>
              <th>Number</th>
            </tr>
            <tr>
              <th>1</th>
              <td><input type="number" name="onetonVehicle" formControlName="onetonVehicle" /></td>
            </tr>
            <tr>
              <th>5</th>
              <td><input type="number" name="fivetonVehicle" formControlName="fivetonVehicle" /></td>
            </tr>
            <tr>
              <th>8</th>
              <td><input type="number" name="eighttonVehicle" formControlName="eighttonVehicle" /></td>
            </tr>
            <tr>
              <th>10</th>
              <td><input type="number" name="tentonVehicle" formControlName="tentonVehicle" /></td>
            </tr>
            <tr>
              <th>14</th>
              <td><input type="number" name="fourteentonVehicle" formControlName="fourteentonVehicle" /></td>
            </tr>
            <tr>
              <th>20+</th>
              <td><input type="number" name="twentytonVehicle" formControlName="twentytonVehicle" /></td>
            </tr>
          </table>
        </div>
        <div class="third-sec">
          <label class="form-label">Do you have a forklift?</label><input type="checkbox" class="checkbox" name="forklift" formControlName="forklift"/><label class="checkbox-label">Yes</label><input type="checkbox" class="checkbox" name="noForklift" formControlName="noForklift" /><label class="checkbox-label">No</label><br /><br />
          <label class="form-label">Do you do your own logistics?</label><input type="checkbox" class="checkbox" name="ownLogistics" formControlName="ownLogistics"/><label class="checkbox-label">Yes</label><input type="checkbox" class="checkbox" name="noOwnLogistics" formControlName="noOwnLogistics" /><label class="checkbox-label">No</label><br /><br />
          <label class="form-label">Do you have a logistics operating system?</label><input type="checkbox" class="checkbox" name="logisticsSystem" formControlName="logisticsSystem"/><label class="checkbox-label">Yes</label><input type="checkbox" class="checkbox" name="noLogisticsSystem" formControlName="noLogisticsSystem" /><label class="checkbox-label">No</label><br /><br />
        </div>
        <div class="second-sec" style="margin:0">
          <label class="form-label">What is your furthest collection radius?</label><input type="text" name="collectionRadius" formControlName="collectionRadius" placeholder="Enter text here" /><br /><br />
        </div>
        <div>

        </div>
        <div class="footer-sec">
          <div class="page-scroll">
            <button class="prev" (click)="pt1();save()">PREVIOUS</button>
            <span>7/8</span>
            <button class="next" (click)="pt2();save()">NEXT</button>
            <progress value="87.5" max="100"></progress>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
