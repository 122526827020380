import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Registration } from '../../models/registration.model';
import { RequirementDetail } from '../../models/requirement-detail.model';
import { SystemCode } from '../../models/system-code.model';
import { navbarData } from '../sidenav/nav-data';
import { Router, Event, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-dismantling-consolidation',
  templateUrl: './dismantling-consolidation.component.html',
  styleUrls: ['./dismantling-consolidation.component.css']
})
export class DismantlingConsolidationComponent implements OnInit {
  @Output()
  close_model = new EventEmitter<string>();

  DismantlingForm: FormGroup;
  EntityDetails: FormGroup;
  entity: Registration;
  navData = navbarData;
  public index = 2;

  provinces: SystemCode[];
  countries: SystemCode[];
  months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  years: number[];
  options: {
    saving: boolean,
    duplicate: boolean,
    got_system_codes: boolean,
    uploading: boolean,
    complete: boolean,
    requirementClicked: RequirementDetail
  };

  sites: number[];

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log("route changed");
        this.save();
      }
    });
}

  ngOnInit(): void {
    this.DismantlingForm = new FormGroup({});
    this.reset_forms();
  }

  reset_forms() {
    this.sites = [];
    this.add_site(1);
    if (sessionStorage.length > 0) {
      var obj = sessionStorage.getItem('dismantlingform');
      if (obj) {
        var ob = JSON.parse(obj);

        for (var i = 2; i < 6; i++) {
          if (ob.hasOwnProperty("totalNoDirectors" + i)) { this.add_site(i); };
        }
        this.DismantlingForm.patchValue(ob);
      }
    }
    this.entity = {
      attachments: [],
      company: {},
      companyEntity: {},
      contactPersons: [],
      entity: {},
      requirements: []
    };
  }

  add_site(i?: number) {
    if (this.sites.length > 5) {
      window.alert("Max of 5 sites allowed for now");
    }
    let num = i ? i : this.sites.length ? this.sites[this.sites.length - 1] + 1 : 1;
    this.DismantlingForm.addControl("totalNoSkilledStuff" + num, new FormControl(""));
    this.DismantlingForm.addControl("fullTimeSkilledStuff" + num, new FormControl(""));
    this.DismantlingForm.addControl("partTimeSkilledStuff" + num, new FormControl(""));
    this.DismantlingForm.addControl("maleSkilledStuff" + num, new FormControl(""));
    this.DismantlingForm.addControl("femaleSkilledStuff" + num, new FormControl(""));
    this.DismantlingForm.addControl("youthSkilledStuff" + num, new FormControl(""));
    this.DismantlingForm.addControl("disabledSkilledStuff" + num, new FormControl(""));
    this.DismantlingForm.addControl("totalNoManagers" + num, new FormControl(""));
    this.DismantlingForm.addControl("fullTimeManagers" + num, new FormControl(""));
    this.DismantlingForm.addControl("partTimeManagers" + num, new FormControl(""));
    this.DismantlingForm.addControl("maleManagers" + num, new FormControl(""));
    this.DismantlingForm.addControl("femaleManagers" + num, new FormControl(""));
    this.DismantlingForm.addControl("youthManagers" + num, new FormControl(""));
    this.DismantlingForm.addControl("disabledManagers" + num, new FormControl(""));
    this.DismantlingForm.addControl("totalNoGeneralWorkers" + num, new FormControl(""));
    this.DismantlingForm.addControl("fullTimeGeneralWorkers" + num, new FormControl(""));
    this.DismantlingForm.addControl("partTimeGeneralWorkers" + num, new FormControl(""));
    this.DismantlingForm.addControl("maleGeneralWorkers" + num, new FormControl(""));
    this.DismantlingForm.addControl("femaleGeneralWorkers" + num, new FormControl(""));
    this.DismantlingForm.addControl("youthGeneralWorkers" + num, new FormControl(""));
    this.DismantlingForm.addControl("disabledGeneralWorkers" + num, new FormControl(""));
    this.DismantlingForm.addControl("totalNoDirectors" + num, new FormControl(""));
    this.DismantlingForm.addControl("fullTimeDirectors" + num, new FormControl(""));
    this.DismantlingForm.addControl("partTimeDirectors" + num, new FormControl(""));
    this.DismantlingForm.addControl("maleDirectors" + num, new FormControl(""));
    this.DismantlingForm.addControl("femaleDirectors" + num, new FormControl(""));
    this.DismantlingForm.addControl("youthDirectors" + num, new FormControl(""));
    this.DismantlingForm.addControl("disabledDirectors" + num, new FormControl(""));

    this.sites.push(num);
  }

  remove_site(num: number, index: number) {
    this.DismantlingForm.removeControl("totalNoSkilledStuff" + num);
    this.DismantlingForm.removeControl("fullTimeSkilledStuff" + num);
    this.DismantlingForm.removeControl("partTimeSkilledStuff" + num);
    this.DismantlingForm.removeControl("maleSkilledStuff" + num);
    this.DismantlingForm.removeControl("femaleSkilledStuff" + num);
    this.DismantlingForm.removeControl("youthSkilledStuff" + num);
    this.DismantlingForm.removeControl("disabledSkilledStuff" + num);
    this.DismantlingForm.removeControl("totalNoManagers" + num);
    this.DismantlingForm.removeControl("fullTimeManagers" + num);
    this.DismantlingForm.removeControl("partTimeManagers" + num);
    this.DismantlingForm.removeControl("maleManagers" + num);
    this.DismantlingForm.removeControl("femaleManagers" + num);
    this.DismantlingForm.removeControl("youthManagers" + num);
    this.DismantlingForm.removeControl("disabledManagers" + num);
    this.DismantlingForm.removeControl("totalNoGeneralWorkers" + num);
    this.DismantlingForm.removeControl("fullTimeGeneralWorkers" + num);
    this.DismantlingForm.removeControl("partTimeGeneralWorkers" + num);
    this.DismantlingForm.removeControl("maleGeneralWorkers" + num);
    this.DismantlingForm.removeControl("femaleGeneralWorkers" + num);
    this.DismantlingForm.removeControl("youthGeneralWorkers" + num);
    this.DismantlingForm.removeControl("disabledGeneralWorkers" + num);
    this.DismantlingForm.removeControl("totalNoDirectors" + num);
    this.DismantlingForm.removeControl("fullTimeDirectors" + num);
    this.DismantlingForm.removeControl("partTimeDirectors" + num);
    this.DismantlingForm.removeControl("maleDirectors" + num);
    this.DismantlingForm.removeControl("femaleDirectors" + num);
    this.DismantlingForm.removeControl("youthDirectors" + num);
    this.DismantlingForm.removeControl("disabledDirectors" + num);
    this.sites.splice(index, 1);
  }

  save() {
    if (this.DismantlingForm.value) {
      sessionStorage.setItem('dismantlingform', JSON.stringify(this.DismantlingForm.value));
      sessionStorage.setItem('dismantlingConsolidation', "true");
    }
  }


  pt2() {
    this.router.navigate(['service-portal/service-portal/site-info']);
  }

  pt1() {
    this.router.navigate(['service-portal/service-portal/waste-collector']);
  }

}
