<div class="page-cont">
  <div class="site-content">
    <div class="page-body">
      <div class="section-header">
        Section 8: Business Model and Process Flow
      </div>
      <form [formGroup]="businessProcessForm" class="form-group" autocomplete="off">
        <h5 class="pb">1. What is the WEEE tonnage that you historically handle?<span style="font-size:0.9rem">(2019-2021 per annum average)</span></h5>
        <div class="table-container pb">
          <table class="table">
            <tr>
              <th></th>
              <th>2019</th>
              <th>2020</th>
              <th>2021</th>
              <th>2022</th>
            </tr>
            <tr>
              <th>Tonnage(Kg)</th>
              <td><input type="number" name="historyTonnage019" formControlName="historyTonnage019" /></td>
              <td><input type="number" name="historyTonnage020" formControlName="historyTonnage020" /></td>
              <td><input type="number" name="historyTonnage021" formControlName="historyTonnage021" /></td>
              <td><input type="number" name="historyTonnage022" formControlName="historyTonnage022" /></td>
            </tr>
          </table>
        </div>
        <h5 class="pb">2. What is your current WEEE tonnage handling capacity?</h5>
        <div class="table-container pb">
          <table class="table">
            <tr>
              <th></th>
              <th>Location1</th>
              <th>Location2</th>
              <th>Location3</th>
              <th>Location4</th>
            </tr>
            <tr>
              <th>Tonnage(Kg)</th>
              <td><input type="number" name="Loc1Tonnage" formControlName="Loc1Tonnage" /></td>
              <td><input type="number" name="Loc2tonnage" formControlName="Loc2Tonnage" /></td>
              <td><input type="number" name="Loc3Tonnage" formControlName="Loc3Tonnage" /></td>
              <td><input type="number" name="Loc4Tonnage" formControlName="Loc4Tonnage" /></td>
            </tr>
          </table>
        </div>
        <h5 class="pb">3. List the top 10 items of WEEE that you handle:</h5>
        <div class="table-container pb">
          <table class="table pb" style="width:90%">
            <tr>
              <th>Category</th>
              <th>No. of Tons(Kg)</th>
              <th>% of WEEE</th>
              <th>Do you disassemble WEEE</th>
              <th>Do you consolidate WEEE</th>
              <th>Is the WEEE destination local? </th>
            </tr>
            <tr *ngFor="let i of sites; let index = index;">
              <th>{{i}}</th>
              <td><input type="number" name="WEEEtonnage{{i}}" formControlName="WEEEtonnage{{i}}" /></td>
              <td><input type="number" name="WEEEPercent{{i}}" formControlName="WEEEPercent{{i}}" /></td>
              <td><select type="text" name="disassemble{{i}}" formControlName="disassemble{{i}}"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <td><select type="text" name="consolidate{{i}}" formControlName="consolidate{{i}}"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <td><select type="text" name="localDestination{{i}}" formControlName="localDestination{{i}}"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
            </tr>
            <tr>
           </table>
        </div>
        <div class="third-sec">
          <label class="form-label">4. Are you prepared to contract with ERA as an approved Service Provider?</label><select type="text" name="signContract" formControlName="signContract"><option>Please Select</option><option value="yes">Yes</option><option value="no">No</option></select><br /><br />
          <label class="form-label">5. Will you give ERA permission to independently check your legal and financial status with all relevant authorities?</label><select type="text" name="statusCheck" formControlName="statusCheck"><option disabled selected>Please Select</option><option value="yes">Yes</option><option value="no">No</option></select><br /><br />
          <label class="form-label">6. Will you allow ERA to inspect your operations, systems and reports?</label><select type="text" name="inspection" formControlName="inspection"><option disabled selected>Please Select</option><option value="yes">Yes</option><option value="no">No</option></select><br /><br />
          <label class="form-label">7. Is there any type of WEEE that you do not handle?</label><select type="text" name="WEEENotHandled" formControlName="WEEENotHandled"><option disabled selected>Please Select</option><option value="yes">Yes</option><option value="no">No</option></select><br /><br />
        </div>
        <div class="second-sec">
          <label class="form-label" style="padding-left:2%;font-weight:normal">7.1 If yes, what do you not handle</label><input style="height:70px" type="text" name="WEEENothandledType" formControlName="WEEENotHandledType" placeholder="150 characters" /><br /><br />
        </div>
        <div class="third-sec pb">
          <label class="form-label">8. Are you prepared to report your Recycling Activity, Source of Inflow, Inflow Type, Inflow Weight, Outflow Fractions, Outflow Weight and Outflow Destination to ERA?</label><select type="text" name="doReport" formControlName="doReport"><option disabled selected>Please Select</option><option value="yes">Yes</option><option value="no">No</option></select><br /><br />
        </div>
        <h5 class="pb">9. Please specify what you will charge ERA for the following:</h5>
        <div class="pb">5 tons of WEEE (unsorted by client) by the category/product below, collected from a distance of 60km from your site.</div>
        <div class="table-container pb">
          <table class="table pb">
            <tr>
              <th>Category/Product</th>
              <th>Transport Cost per Kg</th>
              <th>Recycling Cost per Kg</th>
              <th>Positive Fraction % age Return</th>
              <th>Consolidation</th>
              <th>Destination Local</th>
              <th>Destination Overseas</th>
            </tr>
            <tr *ngFor="let i of types; let index = index;">
              <th>{{i}}</th>
              <td><input type="number" name="transportCost{{i}}" formControlName="transportCost{{i}}" /></td>
              <td><input type="number" name="recyclingCost{{i}}" formControlName="recyclingCost{{i}}" /></td>
              <td><select type="text" name="positiveReturn{{i}}" formControlName="positiveReturn{{i}}"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <td><select type="text" name="consolidation{{i}}" formControlName="consolidation{{i}}"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <td><select type="text" name="destinationLocal{{i}}" formControlName="destinationLocal{{i}}"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <td><select type="text" name="overseasDestination{{i}}" formControlName="overseasDestination{{i}}"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
            </tr>
          </table>
        </div>
        <div class="second-sec">
          <label class="form-label">10. How long after the order is placed will/can you collect WEEE?</label><input type="text" name="returnTime" formControlName="returnTime" placeholder="Enter text here" /><br /><br />
          <label class="form-label">11. Is there a minimum amount of WEEE that you are prepared to collect per collection?</label><input type="text" name="weightMinimum" formControlName="weightMinimum" placeholder="Enter text here" /><br /><br />
          <label class="form-label">12. Do you have a perfomance for working on collection routine?</label><input type="text" name="perfomanceRoutine" formControlName="perfomanceRoutine" placeholder="Enter text here" /><br /><br />
          <label class="form-label">13. ERA typically has a 30 day payment cycle.<br />Are you willing to accept such payment cycle terms?</label><select type="text" class="select" name="paymentCycle" formControlName="paymentCycle"><option disabled selected>Please Select</option><option value="yes">Yes</option><option value="no">No</option></select><br /><br />
          <label class="form-label">14. What is your WEEE routine collection preference: (threshold weight only)(Weekly)(Monthly)</label><input type="text" name="collectionPreference" formControlName="collectionPreference" placeholder="Enter text here" /><br /><br />
          <label class="form-label">15. How soon after rendering the services, the services report and your invoice do you expect payment?</label><input type="text" name="paymentTimeFrame" formControlName="paymentTimeFrame" placeholder="Enter text here" /><br /><br />
        </div>
        <label class="form-label3 pb">16. Do you have the following licence/certificate/agreements/systems:</label>
        <div>
          <label class="form-label2">i. Copy of Compliance Certificate/s</label><select class="select" type="text" name="complianceCertificate" formControlName="complianceCertificate"><option disabled selected>Please Select</option><option value="yes">Yes</option><option value="no">No</option></select><br /><br />
          <label class="form-label2">ii. Certificates of Specialisation - if any</label><select type="text" class="select" name="specialisationCertificate" formControlName="specialisationCertificate"><option disabled selected>Please Select</option><option value="yes">Yes</option><option value="no">No</option></select><br /><br />
          <label class="form-label2">iii. Proof of Quality Management Systems(QMS) - if any</label><select type="text" class="select" name="QMS" formControlName="QMS"><option disabled selected>Please Select</option><option value="yes">Yes</option><option value="no">No</option></select><br /><br />
        </div>
        <label class="form-label3 pb">17. Please answer the following related to your WEEE documents</label>
        <div class="table-container1">
          <table class="table" style="width:80%">
            <tr>
              <th colspan="2">Do you have a WEEE Order Ticket for recording WEEE collected/delivered?</th>
              <td><select type="text" name="orderTicket" formControlName="orderTicket"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th>Copy</th>
            </tr>
            <tr>
              <th colspan="2">Do you have a system for recording a ticket for the extraction of inflow data?</th>
              <td><select type="text" name="dataInflowRecorder" formControlName="dataInflowRecorder"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th>Demo</th>
            </tr>
            <tr>
              <th colspan="2">Do you have a WEEE Shipment Ticket for recording WEEE that was shipped?</th>
              <td><select type="text" name="shipmentRecorder" formControlName="shipmentRecorder"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th>Copy</th>
            </tr>
            <tr>
              <th colspan="2">Do you have a system for recording for extraction of outflow data?</th>
              <td><select type="text" name="dataOutflowRecorder" formControlName="dataOutflowRecorder"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th>Demo</th>
            </tr>
            <tr>
              <th colspan="2">Are you able to report on your WEEE Service Provider activity to verify the invoicing and payment system??</th>
              <td><select type="text" name="activityRecording" formControlName="activityRecording"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th>Demo</th>
            </tr>
            <tr>
              <th>Source</th>
              <th>Licence/ Documeny / System</th>
              <td></td>
              <th></th>
            </tr>
            <tr>
              <th>1</th>
              <th>Waste Management Licence(National,provincial,hazardous)</th>
              <td><select type="text" name="wasteManagementLicence" formControlName="wasteManagementLicence"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>

            </tr>
            <tr>
              <th>2</th>
              <th>GDARD Registration: Recycler/Reprocessor</th>
              <td><select type="text" name="GDARDRegistration" formControlName="GDARDRegistration"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>3</th>
              <th>GDARD Registration: Hazardous Waste Transporter</th>
              <td><select type="text" name="GDARDRegistrationTransporter" formControlName="GDARDRegistrationTransporter"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>4</th>
              <th>Second-hand Goods Licence</th>
              <td><select type="text" name="secondhandGoodsLicence" formControlName="secondhandGoodsLicence"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>5</th>
              <th>Compensation Letter of Good Standing</th>
              <td><select type="text" name="letterofGoodStanding" formControlName="letterofGoodStanding"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>6</th>
              <th>ISO 14001 - Environmental Standard for E-Waste</th>
              <td><select type="text" name="ISO4001" formControlName="ISO4001"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>7</th>
              <th>Certificate of Destruction</th>
              <td><select type="text" name="destructionCertificate" formControlName="destructionCertificate"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>8</th>
              <th>Data Security</th>
              <td><select type="text" name="dataSecurity" formControlName="dataSecurity"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>9</th>
              <th>Sections 21-27 of the NEWMWA</th>
              <td><select type="text" name="NEWMWA2127" formControlName="NEWMWA2127"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>10</th>
              <th>Municipal Regulations - Identity/List</th>
              <td><select type="text" name="municipalRegulations" formControlName="municipalRegulations"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>11</th>
              <th>Trans-frontier WEEE licence / permission</th>
              <td><select type="text" name="transfrontierWEEELicence" formControlName="transfrontierWEEELicence"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>12</th>
              <th>Focus Materials Tracking / system (Eg. R2 or e-Stewards)</th>
              <td><select type="text" name="focusMaterialTracking" formControlName="focusMaterialTracking"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>13</th>
              <th>ISO 9001 - Operational and Process Standards</th>
              <td><select type="text" name="ISO9001" formControlName="ISO9001"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
            <tr>
              <th>14</th>
              <th>ISO 18001 Health and Safety Standard</th>
              <td><select type="text" name="ISO18001" formControlName="ISO18001"><option disabled selected>Y/N</option><option value="yes">Yes</option><option value="no">No</option></select></td>
              <th></th>
            </tr>
          </table>
        </div>
      </form>
      <div class="footer-sec">
        <div class="page-scroll">
          <button class="prev" (click)="pt1();save('')">PREVIOUS</button>
          <span>8/8</span>
          <button class="next" (click)="save('message')">SAVE</button>
          <progress value="100" max="100"></progress>
        </div>
      </div>
    </div>
  </div>
</div>
