
<div class="page-body">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
       
          <img src="" class="img-fluid" alt="Responsive image">
        </div>
      </div>
   </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div id="track-report" class="container-fluid">
            <ngx-slick-carousel class="grid-container"
                                #slickIpoModal="slick-carousel"
                                [config]="ipoSlideConfig">
              <div ngxSlickItem *ngFor="let slide of ipoSlides" class="grid-card">
                <div class="image image-1" [ngStyle]="{ 'background-image' : 'url(' + slide.img + ')'}"></div>
                <div class="text text-center">
                  <h2>{{slide.title}}</h2>
                  <div class="block">
                    <h3 class="figure">{{slide.raise}}</h3>
                    <p class="title">Raise</p>
                    <hr>
                    <h3 class="figure">{{slide.oversubscribed}}</h3>
                    <p class="title">Oversubscribed</p>
                    <hr>
                    <h3 class="figure">{{slide.investors}}</h3>
                    <p class="title"># Investors</p>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
