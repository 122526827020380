<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{title? title: 'Confirm'}}</h4>
  <button type="button" class="btn-close" aria-describedby="modal-title" (click)="close_model('cross click')"></button>
</div>
<div class="modal-body">
  <p>
    {{message? message: 'Are you sure you want to do this?'}}
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="close_model('cancel click')">No</button>
  <button type="button" class="btn btn-danger" (click)="confirm();">Yes</button>
</div>
