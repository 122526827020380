<div class="page-wrapper text-center">
  <nav class="row navbar navbar-dark goods-nav">
    <div class="container">
      <h2 class="col left">PoM Outputs</h2>
      <div class="col-2">
        <p style="margin: 0;">Reporting Period</p>
      </div>
      <form [formGroup]="periodForm" class="col-6">
        <select class="col-3 nav-dropdown" formControlName="type" (change)="period_select_type();">
          <option [ngValue]="null" [disabled]="true">-Select Type-</option>
          <option *ngFor="let t of period_info.types" [ngValue]="t">{{t}}</option>
        </select>
        <select id="period" [hidden]="!period_info.type_selected" class="col-3 nav-dropdown" formControlName="year" (change)="period_select_year()">
          <option [ngValue]="null" [disabled]="true">-Select Year-</option>
          <option *ngFor="let y of period_info.years" [ngValue]="y">{{y}}</option>
        </select>
        <select id="period" [hidden]="!period_info.type_selected" class="col-3 nav-dropdown" formControlName="period" (change)="get_goods()" [disabled]="getting_goods">
          <option [ngValue]="null" [disabled]="true">{{period_info.msg}}</option>
          <option *ngFor="let p of period_info.periods" [ngValue]="p.value" [disabled]="getting_goods">{{p.description}}</option>
        </select>
        <select id="download_type" [hidden]="!period_info.type_selected || !goods || getting_goods || !selected_period" class="col-3 nav-dropdown" formControlName="download_type" (change)="download_goods()">
          <option [value]="null" [disabled]="true">--Select Download Type--</option>
          <option *ngFor="let r of report_options" [ngValue]="r.value" [disabled]="getting_goods">{{r.text}}</option>
        </select>
      </form>
      <input type="file" #upload_attachment [hidden]="true" style="position:absolute; left: -1000px;" (change)="upload_file($event)" />
      <div class="col-2">
        <button class="col-6 btn btn-primary" type="button" [disabled]="!selected_period" (click)="upload_model(uploadModel);">Upload</button>
        <button class="col-6 btn btn-primary" type="button" (click)="download_template();">Get Template</button>
      </div>
    </div>
  </nav>

  <div class="col container" *ngIf="selected_period else noPeriodSelected">
    <div class="row search-div">
      <form [formGroup]="searchForm">
        <div class="col-10 inline search-input-div">
          <input type="text" class="form-control" #myInput (input)="search(myInput.value)" formControlName="search" placeholder="Search...">
        </div>
        <a class="icon col-1 inline" (click)="myInput.value = '';search() " style="margin-left:-30px">
          <img src="assets/icons/close_black_24dp.svg" alt="search">
        </a>
        <a class="icon col-1 inline" (click)="search()" style="padding-left: 10px">
          <img src="assets/icons/search_black_24dp.svg" alt="search">
        </a>
      </form>
    </div>
    <div class="row">
      <form [formGroup]="goodsForm">
        <div class="content-container">
          <div class="table-container">
            <table class="table">
              <thead>
                <tr *ngIf="!getting_goods else GettingGoods">
                  <th style="width: 50px">No.</th>
                  <th class="col-1">HS Code</th>
                  <th class="col-2">Category</th>
                  <th class="col-3">Description</th>
                  <th class="col-1">POM</th>
                  <th class="col-1">Avg/kg</th>
                  <th class="col-1">Waste Stream</th>
                  <th class="col-1">Levy</th>
                  <th class="col-1">Total kg</th>
                  <th class="col-1"></th>
                </tr>
                <ng-template #GettingGoods>
                  <tr><strong>Busy Getting Goods For Selected Period.</strong></tr>
                </ng-template>
              </thead>
              <tbody>
                <tr *ngFor="let g of goods; let i = index;">
                  <td style="width: 50px">{{ i + 1 }}</td>
                  <td class="col-1">{{ g.good?.hsCode }}</td>
                  <td class="col-2">{{ g.good?.category?.value  }}</td>
                  <td class="col-3">{{ g.good?.description }}</td>
                  <td class="col-1">
                    <input type="number" id="units_{{i}}" min="0" name="units_{{i}}" [value]="goods[i].units" step="1" style="width: 100%;" (change)="update_entity_good(g, 'units', $event)">
                  </td>
                  <td class="col-1">
                    <input type="number" id="avgKg_{{i}}" min="0" name="avgKg_{{i}}" [value]="goods[i].avgKg.toFixed(6)" step="0.000001" style="width: 100%;" (change)="update_entity_good(g, 'avgKg', $event)">
                  </td>
                  <td class="col-1">
                    <select id="wasteClass_{{i}}" name="wasteClass_{{i}}" class="form-control input-fonts" style="width: 100%;" formControlName="wasteClass_{{i}}" (change)="update_entity_good(g, 'wasteClass', i)">
                      <option *ngFor="let p of waste_classes" [ngValue]="p.id">{{p.value}}</option>
                    </select>
                  </td>
                  <td class="col-1">R {{ g.tariff }}</td>
                  <td class="col-1">{{ g.totalKg.toFixed(6) }} kg</td>
                  <td class="col-1">
                    <a class="mybtn icon btn-round" (click)="remove_good(g, delete_goods)">
                      <img src="assets/icons/close_black_24dp.svg" class="icon" />
                    </a>
                  </td>
                </tr>
                <tr *ngIf="!getting_goods">
                  <td style="width: 50px"></td>
                  <td class="col-1">
                    <ng-autocomplete formControlName="hsCode"
                                     [data]="find.hsCode"
                                     [searchKeyword]="keyword.hsCode"
                                     (inputChanged)="find.changed = 'hsCode'; onChangeFind($event)"
                                     (selected)="find.selected = 'hsCode'; onSelectedFind($event)"
                                     [itemTemplate]="hsCodeTemplateStatic"
                                     [notFoundTemplate]="hsCodeNotFoundTemplate">
                    </ng-autocomplete>
                    <ng-template #hsCodeTemplateStatic let-item>
                      <a [innerHTML]="item.hsCode" class="new-input-fonts"></a>
                    </ng-template>
                    <ng-template #hsCodeNotFoundTemplate let-notFound>
                      <div [innerHTML]="notFound" class="new-input-fonts"></div>
                    </ng-template>
                  </td>
                  <td class="col-2">
                    <input type="text" step="1" class="form-control new-input-fonts" id="category" required formControlName="category">
                  </td>
                  <td class="col-3">
                    <ng-autocomplete formControlName="description"
                                     [data]="find.description"
                                     [searchKeyword]="keyword.description"
                                     (inputChanged)="find.changed = 'description'; onChangeFind($event)"
                                     (selected)="find.selected = 'description'; onSelectedFind($event)"
                                     [itemTemplate]="descriptionTemplateStatic"
                                     [notFoundTemplate]="descriptionNotFoundTemplate">
                    </ng-autocomplete>
                    <ng-template #descriptionTemplateStatic let-item>
                      <a [innerHTML]="item.description" class="input-fonts"></a>
                    </ng-template>
                    <ng-template #descriptionNotFoundTemplate let-notFound>
                      <div [innerHTML]="notFound" class="input-fonts"></div>
                    </ng-template>
                  </td>
                  <td class="col-1">
                    <input type="number" step="1" min="0" class="form-control new-input-fonts" id="Units" required formControlName="units" (change)="calc_totalKg()">
                  </td>
                  <td class="col-1">
                    <input type="number" step="0.000001" min="0" class="form-control new-input-fonts" id="avgKg" required formControlName="avgKg" (change)="calc_totalKg()">
                  </td>
                  <td class="col-1">
                    <select id="WasteClass" class="form-control input-fonts" required formControlName="wasteClass" (change)="selectWasteClass()">
                      <option *ngFor="let p of waste_classes" [ngValue]="p">{{p.value}}</option>
                    </select>
                  </td>
                  <td class="col-1">
                    <input type="number" class="form-control input-fonts" id="tariff" required formControlName="tariff" step="0.000001">
                  </td>
                  <td class="col-1">
                    <input type="number" step="0.000001" class="form-control new-input-fonts" id="totalKg" disabled formControlName="totalKg">
                  </td>
                  <td class="col-1">
                    <a class="mybtn icon btn-round" [class.disabled]="!goodsForm.valid" (click)="create_good()">
                      <img src="assets/icons/done_black_24dp.svg" class="icon" />
                    </a>
                    <a class="mybtn icon btn-round" (click)="cancel_good()">
                      <img src="assets/icons/close_black_24dp.svg" class="icon" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #noPeriodSelected>
    <div class="col-12 container">
      <div class="row" style="padding-top: 50px;">
        <strong>
          Please Select a Reporting Period to manage goods for...
        </strong>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #uploadModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{process.header}}</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" [disabled]="process.uploading" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="upload-form">
      <div layout="column" *ngIf="!process.processing">
        <div layout="column">
          <p [innerHTML]="process.message_before_html">{{process.message_before}}</p>
        </div>
      </div>
      <div layout="column" *ngIf="process.processing">
        <div layout="row">
          <h2 class="md-title" style="text-align: center;">{{process.action}}</h2>
        </div>
        <div>
          <div layout="row" layout-align="center-center" *ngIf="!process.completed" class="spinner-div">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div layout="row" layout-padding style="text-align: center;" *ngIf="!process.completed && process.progress_mode == 0">This may take a few minutes, do not close your browser.</div>
          <div layout="row" layout-padding style="text-align: center;" *ngIf="!process.completed && process.progress_mode == 1">{{process.message_busy}} {{process.progress}} of {{process.total}} ({{process.percentage}})</div>
          <div layout="row" layout-padding style="text-align: center;" *ngIf="process.completed" [innerHTML]="process.message_complete_html">{{process.message_complete}}</div>
        </div>
      </div>
    </div>
  </div>
  <!--<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
  </div>-->
</ng-template>


<ng-template #delete_goods>
  <app-confirm (close)="close_dialog($event)" (confirmed)="confirm_delete_good($event)" [title]="delete_good_title" [message]="delete_good_message"></app-confirm>
</ng-template>
