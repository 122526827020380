<div class="page-body">
  <div>
    <img src="" class="img-fluid" alt="Responsive image">
  </div>
  <div>
  </div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-2">
        <img src="../../assets/how-it-works/create-your-account.png" class="d-block w-50 p-2" alt="p1" />
        <p class="px-3">Create Your Account</p>
      </div>
      <div class="col-sm-2">
        <img src="../../assets/how-it-works/browser-property-selection.png" class="d-block w-50 p-2" alt="p2" />
        <p class="px-3">Browser Property Selection</p>
      </div>
      <div class="col-sm-2">
        <img src="../../assets/how-it-works/buy-property-shares.png" class="d-block w-50 p-2" alt="p3" />
        <p class="px-3">Buy Property Shares</p>
      </div>
      <div class="col-sm-2">
        <img src="../../assets/how-it-works/monthly-rental-income.png" class="d-block w-50 p-2" alt="p4" />
        <p class="px-3">Earn Montly Rental<br>Income</p>
      </div>
      <div class="col-sm-2">
        <img src="../../assets/how-it-works/capital-gains.png" class="d-block w-50 p-2" alt="p5" />
        <p class="px-3">Realize Capital Gains</p>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-2">
      </div>
      <div class="col-sm-8">
        <div class="px-4 pt-5 pb-4 text-center">
          <h2 class="head">
            Our track record of IPOs speaks for itself
          </h2>
          <span class="sub-head text-light">Take a peek at our latest IPOs</span>
        </div>
      </div>
      <div class="col-sm-2">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div id="track-report" class="container-fluid">
            <ngx-slick-carousel class="grid-container"
                                #slickIpoModal="slick-carousel"
                                [config]="ipoSlideConfig">
              <div ngxSlickItem *ngFor="let slide of ipoSlides" class="grid-card">
                <div class="image image-1" [ngStyle]="{ 'background-image' : 'url(' + slide.img + ')'}"></div>
                <div class="text text-center">
                  <h2>{{slide.title}}</h2>
                  <div class="block">
                    <h3 class="figure">{{slide.raise}}</h3>
                    <p class="title">Raise</p>
                    <hr>
                    <h3 class="figure">{{slide.oversubscribed}}</h3>
                    <p class="title">Oversubscribed</p>
                    <hr>
                    <h3 class="figure">{{slide.investors}}</h3>
                    <p class="title"># Investors</p>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-2">
      </div>
      <div class="col-sm-8">
        <div class="px-4 pt-5 pb-4 text-center">
          <h2 class="head">
            <b>Buy and Sell Through Our Intergrated Martketplace</b>
          </h2>
        </div>
      </div>
      <div class="col-sm-2">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-2">
      </div>
      <div class="col-sm-2">
      </div>
      <div class="col-sm-6">
        <img src="../../assets/how-it-works/buy-and-sell-sells.png" class="d-block w-50 p-2 float-left" alt="p3" />
      </div>
      <div class="col-sm-2">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-2">
      </div>
      <div class="col-sm-8">
        <div class="px-4 pt-5 pb-4 text-center">
          <h2 class="head">
            <b>How do i make money?</b>
          </h2>
        </div>
      </div>
      <div class="col-sm-2">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row make_money_wrapper">
      <div class="money_content_wrapper">
        <div class="mone_content_inner">
          <div class="col-sm-6">
            <div class="box_content_wrapper box_1">
              <div class="box_inner">
                <div class="box_title_wrapper" style="background-color: #b87d1c;">
                  <div class="box_icon">
                    <img src="../../assets/how-it-works/monthly-rental-income.png" alt="capital-gains@3x" width="61" height="61">
                  </div>
                  <div class="box_title">
                    Guaranteed Monthly Rental Income
                  </div>
                </div>
                <div class="box_list_wrapper">
                  <div class="box_list_inner">
                    <ul>
                      <li>
                        <div class="check_icon">
                          <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>7C00AB79-DB81-42CE-A5EB-03BD33794E21@3x</title>
                            <g id="How-it-Works" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                              <g id="EE-Properties-How-it-Works" transform="translate(-279.000000, -3838.000000)" stroke="#C57A0F" stroke-width="2">
                                <g id="check" transform="translate(280.000000, 3839.500000)">
                                  <polyline id="Path" points="16 0 5 11 0 6"></polyline>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div><p>You get a share of rent received, after expenses, in the form of <strong>quarterly dividends</strong> in proportion to how many share you own.</p>
                      </li>
                      <li>
                        <div class="check_icon">
                          <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>7C00AB79-DB81-42CE-A5EB-03BD33794E21@3x</title>
                            <g id="How-it-Works" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                              <g id="EE-Properties-How-it-Works" transform="translate(-279.000000, -3838.000000)" stroke="#C57A0F" stroke-width="2">
                                <g id="check" transform="translate(280.000000, 3839.500000)">
                                  <polyline id="Path" points="16 0 5 11 0 6"></polyline>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div><p><strong>Benefit from rental pools</strong> for certain properties which could lead to increased revenues compared to a traditional residential product rental.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="plus_icon">
                <i class="fas fa-plus"></i>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="box_content_wrapper box_2">
              <div class="box_inner">
                <div class="box_title_wrapper" style="background-color: #e2a900;">
                  <div class="box_icon">
                    <img src="../../assets/how-it-works/capital-gains2.png" alt="capital-gains@3x" width="61" height="61">
                  </div>
                  <div class="box_title">
                    Capital Gains
                  </div>
                </div>
                <div class="box_list_wrapper">
                  <div class="box_list_inner">
                    <ul>
                      <li>
                        <div class="check_icon">
                          <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>D1E858F5-C749-4CF3-9AB6-5D21E3B2B3C7@3x</title>
                            <g id="How-it-Works" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                              <g id="EE-Properties-How-it-Works" transform="translate(-745.000000, -3844.000000)" stroke="#C57A0F" stroke-width="2">
                                <g id="check" transform="translate(746.000000, 3845.500000)">
                                  <polyline id="Path" points="16 0 5 11 0 6"></polyline>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <p><strong>Independent valuations</strong> are carried out by a professional every 6 months.</p>
                      </li>
                      <li>
                        <div class="check_icon">
                          <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>D1E858F5-C749-4CF3-9AB6-5D21E3B2B3C7@3x</title>
                            <g id="How-it-Works" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                              <g id="EE-Properties-How-it-Works" transform="translate(-745.000000, -3844.000000)" stroke="#C57A0F" stroke-width="2">
                                <g id="check" transform="translate(746.000000, 3845.500000)">
                                  <polyline id="Path" points="16 0 5 11 0 6"></polyline>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <p>Use the <strong>valuation</strong> as a guide to buy and sell property shares on the platform.</p>
                      </li>

                      <li>
                        <div class="check_icon">
                          <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>D1E858F5-C749-4CF3-9AB6-5D21E3B2B3C7@3x</title>
                            <g id="How-it-Works" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                              <g id="EE-Properties-How-it-Works" transform="translate(-745.000000, -3844.000000)" stroke="#C57A0F" stroke-width="2">
                                <g id="check" transform="translate(746.000000, 3845.500000)">
                                  <polyline id="Path" points="16 0 5 11 0 6"></polyline>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <p>Realise capital returns when we sell properties at market value after the investment period or via auction.</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-2">
      </div>
      <div class="col-sm-8">
        <div class="px-4 pt-5 pb-4 text-center">
          <h2 class="head">
            <b>Like Everything Else We Do, We’re Keeping Our Pricing Simple</b>
          </h2>
        </div>
      </div>
      <div class="col-sm-2">
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-5">
        <div>
          <img src="../../assets/how-it-works/annual-platform-fee.png" class="d-block w-50 p-2" alt="p1" />
          <b class="px-3">Annual Platform Fee</b>
        </div>
        <div>
          <img src="../../assets/how-it-works/tranction-fee.png" class="d-block w-50 p-2" alt="p2" />
          <b class="px-3">Tranaction Fee</b>
        </div>
      </div>
      <div class="col-sm-7">
        <img class="d-none d-md-block d-md-block wedo-image" src="../../assets/how-it-works/pricing_graphic.svg">
        <img class="d-md-none wedo-image" src="../../assets/how-it-works/pricing_diagram.png">
      </div>
    </div>
  </div>
</div>   








  <!--<div class="px-4 py-5 text-center page-hero flex-column justify-content-center">
    <h1 class="display-5 fw-bold">How it works</h1>
    <div class="col-lg-6 mx-auto">
    </div>
  </div>
  <div class="px-4 pt-5 pb-4 text-center">
    <h2 class="fw-bold">Invest with as little as R1</h2>
  </div>
  <div class="container-fluid">
    <ngx-slick-carousel class="carousel"
                        #slickModal="slick-carousel"
                        [config]="slideConfig">
      <div ngxSlickItem *ngFor="let slide of slides" class="invest_card_wrapper">
        <div class="invest_card_inner">
          <div class="invesr_img_wrapper">
            <img src="{{ slide.img }}" alt="{{ slide.alt }}" width="205" height="192">
            <span class="invest_with_arrow">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.75 8.5">
                <defs>
                  <style>
                    .cls-1, .cls-2 {
                      fill: none;
                      stroke-miterlimit: 10;
                      stroke-width: 0.5px;
                    }

                    .cls-1 {
                      stroke: #b5b5b6;
                    }

                    .cls-2 {
                      stroke: #bcbcbc;
                    }
                  </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><line class="cls-1" y1="4.25" x2="48.5" y2="4.25"></line><circle class="cls-2" cx="52.5" cy="4.25" r="4"></circle></g></g>
              </svg>
            </span>
          </div>
          <div class="invest_card_text" [innerHtml]="slide.text"><p>&nbsp;</p></div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
  <div id="fract-ownership" class="container" style="background-image: url(../../assets/how-it-works/fractionalised-ownership-bg.png);">
    <div class="fo-inner ">
      <div class="fo-right col-6">
        <div class="fo-content">
          <h2>Fractionalised Ownership</h2>
          <p>We fractionalise property ownership by creating a company which owns and distributes shares among investors on EasyProperties.</p>
        </div>
      </div>
    </div>
  </div>
  <div style="background-color: #8a5700;">
    <div class="container">
      <div class="new_listing_inner text-white">
        <div class="listing_left">
          <div class="text_wrapper">
            <h2 class="title">
              New Listings
            </h2>
            <p>IPO stands for Initial Public Offering, and this is when a property becomes available for investment by the public. <span style="color: #F1C669;">That’s you!</span></p>
            <p>You can submit an application for as many shares as you like and when the IPO successfully closes your shares will be issued to you.</p>
          </div>
        </div>
        <div class="listing_right">
          <div class="img_wrapper">
            <img src="../../assets/how-it-works/diversified-portfolio.svg" alt="diversified-portfolio" loading="lazy" style="max-width: 100%; height: auto;">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="px-4 pt-5 pb-4 text-center">
    <h2 class="head">
      Our track record of IPOs speaks for itself
    </h2>
    <span class="sub-head text-light">Take a peek at our latest IPOs</span>
  </div>

  <div id="track-report" class="container-fluid">
    <ngx-slick-carousel class="grid-container"
                        #slickIpoModal="slick-carousel"
                        [config]="ipoSlideConfig">
      <div ngxSlickItem *ngFor="let slide of ipoSlides" class="grid-card">
        <div class="image image-1" [ngStyle]="{ 'background-image' : 'url(' + slide.img + ')'}"></div>
        <div class="text text-center">
          <h2>{{slide.title}}</h2>
          <div class="block">
            <h3 class="figure">{{slide.raise}}</h3>
            <p class="title">Raise</p>
            <hr>
            <h3 class="figure">{{slide.oversubscribed}}</h3>
            <p class="title">Oversubscribed</p>
            <hr>
            <h3 class="figure">{{slide.investors}}</h3>
            <p class="title"># Investors</p>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
  <div class="px-4 pt-5 pb-4 text-center">
    <h2 class="head"> Buy &amp; Sell via the Market Place</h2>
    <span class="sub-head text-light">Quarterly in-platform auctions facilitate the buying and selling of <br> shares in existing properties on our platform.</span>
  </div>
  <div class="container-fluid liq-body">
    <div class="row justify-content-between liq-inner">
      <div class="liq-left col-6">
        <div class="left-inner">
          <div class="left-top">
            <h2><span>How it Works</span></h2>
            <p><span>To buy, place a bid for the value you want to invest and the highest price you’re prepared to pay. Or, offer up all or some of your shares for sale and set the lowest price you’re prepared to accept.</span></p>
          </div>
          <div class="list-node">
            <span class="icon"><img src="../../assets/how-it-works/property_inspections.svg"></span>
            <span class="list-item"> EasyProperties finds the price for the largest volume of shares to complete the transaction. </span>
          </div>
          <span class="bottom-text">That’s property investment in just a few clicks. </span>
        </div>
      </div>
      <div class="liq-right col-5">
        <video width="500" poster="../../assets/how-it-works/liquidity-video-poster.jpg" controls="">
          <source src="../../assets/how-it-works/auction_vid.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="long_invest_wrapper">
      <div class="long_invest_inner">
        <div class="img_wrapper1">
          <img src="../../assets/how-it-works/for_sale.svg" alt="for-sale@3x" width="164" height="164">
        </div>
        <div class="text_wrapper">
          <h2>
            Long term investing made Easy
          </h2>
          <p>
            Property investments are considered medium to long-term and that is why we intend to hold properties for 5-7 years at a time. Once a property's investment cycle ends, we will sell the property at market value with the aim to achieve maximum return on investment through quarterly dividends and capital gains
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="make_money_wrapper" style="background-color:rgba(247, 246, 246,1.0);">
      <div class="money_title_wrapper">
        <h2>
          How do i make money?
        </h2>
      </div>
      <div class="money_content_wrapper">
        <div class="mone_content_inner">
          <div class="box_content_wrapper box_1">
            <div class="box_inner">
              <div class="box_title_wrapper" style="background-color: #b87d1c;">
                <div class="box_icon">
                  <img src="../../assets/how-it-works/rent.svg" alt="rent@3x" loading="lazy" style="max-width: 100%; height: auto;">
                </div>
                <div class="box_title">
                  Earn Rental Income
                </div>
              </div>
              <div class="box_list_wrapper">
                <div class="box_list_inner">
                  <ul>
                    <li>
                      <div class="check_icon">
                        <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <title>7C00AB79-DB81-42CE-A5EB-03BD33794E21@3x</title>
                          <g id="How-it-Works" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g id="EE-Properties-How-it-Works" transform="translate(-279.000000, -3838.000000)" stroke="#C57A0F" stroke-width="2">
                              <g id="check" transform="translate(280.000000, 3839.500000)">
                                <polyline id="Path" points="16 0 5 11 0 6"></polyline>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div><p>You get a share of rent received, after expenses, in the form of <strong>quarterly dividends</strong> in proportion to how many share you own.</p>
                    </li>
                    <li>
                      <div class="check_icon">
                        <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <title>7C00AB79-DB81-42CE-A5EB-03BD33794E21@3x</title>
                          <g id="How-it-Works" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g id="EE-Properties-How-it-Works" transform="translate(-279.000000, -3838.000000)" stroke="#C57A0F" stroke-width="2">
                              <g id="check" transform="translate(280.000000, 3839.500000)">
                                <polyline id="Path" points="16 0 5 11 0 6"></polyline>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div><p><strong>Benefit from rental pools</strong> for certain properties which could lead to increased revenues compared to a traditional residential product rental.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="plus_icon">
              <i class="fas fa-plus"></i>
            </div>
          </div>

          <div class="box_content_wrapper box_2">
            <div class="box_inner">
              <div class="box_title_wrapper" style="background-color: #e2a900;">
                <div class="box_icon">
                  <img src="../../assets/how-it-works/capital_gains.svg" alt="capital-gains@3x" width="61" height="61">
                </div>
                <div class="box_title">
                  Capital Gains
                </div>
              </div>
              <div class="box_list_wrapper">
                <div class="box_list_inner">
                  <ul>
                    <li>
                      <div class="check_icon">
                        <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <title>D1E858F5-C749-4CF3-9AB6-5D21E3B2B3C7@3x</title>
                          <g id="How-it-Works" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g id="EE-Properties-How-it-Works" transform="translate(-745.000000, -3844.000000)" stroke="#C57A0F" stroke-width="2">
                              <g id="check" transform="translate(746.000000, 3845.500000)">
                                <polyline id="Path" points="16 0 5 11 0 6"></polyline>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <p><strong>Independent valuations</strong> are carried out by a professional every 6 months.</p>
                    </li>
                    <li>
                      <div class="check_icon">
                        <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <title>D1E858F5-C749-4CF3-9AB6-5D21E3B2B3C7@3x</title>
                          <g id="How-it-Works" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g id="EE-Properties-How-it-Works" transform="translate(-745.000000, -3844.000000)" stroke="#C57A0F" stroke-width="2">
                              <g id="check" transform="translate(746.000000, 3845.500000)">
                                <polyline id="Path" points="16 0 5 11 0 6"></polyline>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <p>Use the <strong>valuation</strong> as a guide to buy and sell property shares on the platform.</p>
                    </li>

                    <li>
                      <div class="check_icon">
                        <svg width="18px" height="14px" viewBox="0 0 18 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <title>D1E858F5-C749-4CF3-9AB6-5D21E3B2B3C7@3x</title>
                          <g id="How-it-Works" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
                            <g id="EE-Properties-How-it-Works" transform="translate(-745.000000, -3844.000000)" stroke="#C57A0F" stroke-width="2">
                              <g id="check" transform="translate(746.000000, 3845.500000)">
                                <polyline id="Path" points="16 0 5 11 0 6"></polyline>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <p>Realise capital returns when we sell properties at market value after the investment period or via auction.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div id="wedo-wrapper">
      <div class="row-fluid head">
        <div class="container">
          <div class="col text-center">
            <h2>Like Everything Else We Do, We’re Keeping Our Pricing Simple</h2>
          </div>
        </div>
      </div>

      <div class="row-fluid body">
        <div class="container flex">
          <div class="col-5 center left-col">
            <div>
              <div class="centericon"><img class="wedo-icon" src="../../assets/how-it-works/transaction_fee.svg"></div>
              <p class="wedo-text">Annual Platform Fee</p>
            </div>
            <div>
              <div class="centericon"> <img class="wedo-icon" src="../../assets/how-it-works/transaction_fee.svg"></div>
              <p class="wedo-text">Per Transaction Fees </p>
            </div>
          </div>
          <div class="col-7">
            <img class="d-none d-md-block d-md-block wedo-image" src="../../assets/how-it-works/pricing_graphic.svg">
            <img class="d-md-none wedo-image" src="../../assets/how-it-works/pricing_diagram.png">
          </div>
        </div>
      </div>
    </div>
  </div>-->
