import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';
import { ApiAccessService } from '../api-access.service';
import { Attachment } from '../models/attachment.model';
import { Entity } from '../models/entity.model';
import { Registration } from '../models/registration.model';
import { RequirementDetail } from '../models/requirement-detail.model';
import { Requirement } from '../models/requirement.model';
import { SystemCode, SystemCodeModel } from '../models/system-code.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  @Output()
  close_model = new EventEmitter<string>();

  CompanyDetailsForm: FormGroup;
  FurtherDetailsForm: FormGroup;
  FinancialInformationForm: FormGroup;
  CurrentWEEERecyclingForm: FormGroup;
  EntityDetails: FormGroup;

  entity: Registration;

  provinces: SystemCode[];
  countries: SystemCode[];
  months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  years: number[];
  options: {
    saving: boolean,
    duplicate: boolean,
    got_system_codes: boolean,
    uploading: boolean,
    complete: boolean,
    requirementClicked: RequirementDetail,
  }

  constructor(private apiService: ApiAccessService) { }

  ngOnInit() {
    this.reset_forms();
    this.CompanyDetailsForm = new FormGroup({
      name: new FormControl('', Validators.required),
      surname: new FormControl(),
      passport: new FormControl(),
      identity: new FormControl(),
      countryOfRegistration: new FormControl(),
      yearEstablished: new FormControl(0),
      physicalAddress: new FormControl(),
      postalAddress: new FormControl(),
      telephoneNumber: new FormControl('', [Validators.pattern("^(\[0-9]{10})$")]),
      websiteAddress: new FormControl('', [Validators.required,Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
      ]),
    });
    this.FurtherDetailsForm = new FormGroup({
      complianceManager: new FormControl(),
      complianceManagerNumber: new FormControl('', [Validators.pattern("^(\[0-9]{10})$")]),
      complianceManagerEmail: new FormControl('', [Validators.pattern('^[a-z0-9A-Z._-]+@[a-zA-Z0-9_-]+[.]{1,1}[a-zA-Z0-9._-]{2,3}$')]),
      executiveDirector: new FormControl(),
      officeLocation: new FormControl(),
      tradeLocation: new FormControl(),
      brandOwner: new FormControl(false),
      importer: new FormControl(false),
      licencedAgent: new FormControl(false),
      manufacturer: new FormControl(false),
      reseller: new FormControl(false),
      retailer: new FormControl(false)
    });
    this.FinancialInformationForm = new FormGroup({
      vatNumber: new FormControl(),
      sDLNumber: new FormControl(),
      taxNumber: new FormControl(),
      financialYearEnd: new FormControl()
    });
    this.CurrentWEEERecyclingForm = new FormGroup({
      wEEEManagementMethod: new FormControl(),
      serviceProvider: new FormControl(),
      wEEETonnageDirectlyProcessed: new FormControl(0),
      wEEETonnageViaServiceProvider: new FormControl(0)
    });
    this.EntityDetails = new FormGroup({
      name: new FormControl(),
      surname: new FormControl(),
      note: new FormControl(),
      entityContactNumber: new FormControl('', [Validators.pattern("^(\[0-9]{10})$")]),
      entityEmailAddress: new FormControl('', [Validators.pattern("^[a-z0-9A-Z._-]+@[a-zA-Z0-9_-]+[.]{1,1}[a-zA-Z0-9._-]{2,3}$")])
    });

    this.get_system_codes();
    window.scrollTo(0, 0);
  }

  get_system_codes() {
    if (!this.options.got_system_codes) {
      let s: SystemCodeModel[] = [
        { name: "provinces", context: "Address", field: "Province", active: true },
        { name: "countries", field: "Country", active: true }
      ];
      let temp = this.apiService.get_system_codes(s).subscribe(x => {
        if (x["provinces"]) {
          this.provinces = x["provinces"];
        }
        if (x["countries"]) {
          this.countries = x["countries"];
        }
        this.options.got_system_codes = true;

        this.years = [];
        let year = new Date().getFullYear();
        for (var i = 0; i < 200; i++) {
          this.years.push(year - i);
        }
        this.apiService.get_requirements(0, { service: "entity", category: "6. Document Uploads" }).subscribe(x => {
          this.entity.requirements = x;
        });
        temp.unsubscribe();
      });
    }
  }

  reset_forms() {
    this.entity = {
      attachments: [],
      company: {},
      companyEntity: {},
      contactPersons: [],
      entity: {},
      requirements: []
    };
    this.options = {
      saving: false,
      duplicate: false,
      got_system_codes: false,
      requirementClicked: null,
      uploading: false,
      complete: false
    };
    //this.CompanyDetailsForm.reset();
    //this.FurtherDetailsForm.reset();
    //this.FinancialInformationForm.reset();
    //this.CurrentWEEERecyclingForm.reset();
    //this.EntityDetails.reset();
  }

  add_person() {
    let new_empty: Entity = { name: "", surname: "", note: "", contactNumber:"", emailAddress: "" };
    if (!this.entity.contactPersons) {
      this.entity.contactPersons = [];
    }
    this.entity.contactPersons.push(new_empty);
  }

  del_person(person: Entity, index: number){
    this.entity.contactPersons.splice(index, 1);
  }

  addRequirementFiles(event: any) {
    if (event.target.files.length > 0) {

      this.options.uploading = true;
      for (var i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        this.apiService.upload_temp_file(file).subscribe(temp => {
          event.target.value = null;
          //update message to say file loadded now unpacking
          let attachment: Attachment = {
            name: file.name,
            contentType: file.type,
            relativeName: "requirement",
            tempFile: temp.tempFileName,
            path: "a place",
            id: 0
          };
          var index = 0;
          this.options.requirementClicked.attachments.push(attachment);
          this.options.uploading = false;
        }, error => {
          this.options.uploading = false;
        });
      }
    } else {
      this.options.uploading = false;
    }
  }

  attachments_zip(attach: Attachment, type: string) {
    this.apiService.get_file("attachment/zip/" + type + "/" + attach.id);
  }

  attachments_delete_all(s, type: string) {
    if (s.Attachments?.length) {
      s.Attachments = [];
    }
  }

  attachments_delete(item: RequirementDetail, index: number) {
    if (item.attachments?.length && item.attachments[index]) {
      item.attachments.splice(index, 1);

    }
  }

  check_duplicate = function () {
    if (this.EntityDetails.value.entityEmailAddress) {
      this.apiService.check_duplicate_username(this.EntityDetails.value.entityEmailAddress).subscribe(successful => {
        this.options.duplicate = false;
      }, error => {
        this.options.duplicate = true;
      });
    }
  }

  close(message: string) {
    this.close_model.emit(message);
  }

  save() {
    if (!this.CompanyDetailsForm.valid ||
      !this.FurtherDetailsForm.valid ||
      !this.FinancialInformationForm.valid ||
      !this.CurrentWEEERecyclingForm.valid ||
      !this.EntityDetails.valid) {
      window.alert("One of the forms are invalid.");
      return;
    } else if (this.entity?.contactPersons?.length < 1) {
      window.alert("No Contact persons supplied.");
      return;
    } else if (this.options.saving) {
      window.alert("Saving in progress.");
      return;
    }else if (this.options.duplicate) {
      window.alert("duplicate user name for email address.");
      return;
    }
    //for (var i = 0; i < this.entity.requirements.length; i++) {
    //  if (!this.entity?.requirements[i].attachments?.length) {
    //    window.alert("All attachments not supplied.");
    //    return;
    // }
    //}
    this.options.saving = true;
    //get all information into entity model and send via api call
    if (this.CompanyDetailsForm.value) {
      if (this.CompanyDetailsForm.value.name) { this.entity.companyEntity.name = this.CompanyDetailsForm.value.name; }
      if (this.CompanyDetailsForm.value.surname) { this.entity.companyEntity.surname = this.CompanyDetailsForm.value.surname; }
      if (this.CompanyDetailsForm.value.passport) { this.entity.companyEntity.passport = this.CompanyDetailsForm.value.passport; }
      if (this.CompanyDetailsForm.value.identity) { this.entity.companyEntity.identity = this.CompanyDetailsForm.value.identity; }
      if (this.CompanyDetailsForm.value.countryOfRegistration) { this.entity.company.countryOfRegistration = this.CompanyDetailsForm.value.countryOfRegistration; }
      if (this.CompanyDetailsForm.value.yearEstablished) { this.entity.company.yearEstablished = this.CompanyDetailsForm.value.yearEstablished; }
      if (this.CompanyDetailsForm.value.physicalAddress) { this.entity.company.physicalAddress = this.CompanyDetailsForm.value.physicalAddress; }
      if (this.CompanyDetailsForm.value.postalAddress) { this.entity.company.postalAddress = this.CompanyDetailsForm.value.postalAddress; }
      if (this.CompanyDetailsForm.value.telephoneNumber) { this.entity.company.telephoneNumber = this.CompanyDetailsForm.value.telephoneNumber; }
      if (this.CompanyDetailsForm.value.websiteAddress) { this.entity.company.websiteAddress = this.CompanyDetailsForm.value.websiteAddress; }
    }
    if (this.FurtherDetailsForm.value) {
      if (this.FurtherDetailsForm.value.complianceManager) { this.entity.company.complianceManager = this.FurtherDetailsForm.value.complianceManager; }
      if (this.FurtherDetailsForm.value.complianceManagerNumber) { this.entity.company.complianceManagerNumber = this.FurtherDetailsForm.value.complianceManagerNumber; }
      if (this.FurtherDetailsForm.value.complianceManagerEmail) { this.entity.company.complianceManagerEmail = this.FurtherDetailsForm.value.complianceManagerEmail; }
      if (this.FurtherDetailsForm.value.executiveDirector) { this.entity.company.executiveDirector = this.FurtherDetailsForm.value.executiveDirector; }
      if (this.FurtherDetailsForm.value.officeLocation) { this.entity.company.officeLocation = this.FurtherDetailsForm.value.officeLocation; }
      if (this.FurtherDetailsForm.value.tradeLocation) { this.entity.company.tradeLocation = this.FurtherDetailsForm.value.tradeLocation; }
      if (this.FurtherDetailsForm.value.brandOwner) { this.entity.company.brandOwner = this.FurtherDetailsForm.value.brandOwner; }
      if (this.FurtherDetailsForm.value.importer) { this.entity.company.importer = this.FurtherDetailsForm.value.importer; }
      if (this.FurtherDetailsForm.value.licencedAgent) { this.entity.company.licencedAgent = this.FurtherDetailsForm.value.licencedAgent; }
      if (this.FurtherDetailsForm.value.manufacturer) { this.entity.company.manufacturer = this.FurtherDetailsForm.value.manufacturer; }
      if (this.FurtherDetailsForm.value.reseller) { this.entity.company.reseller = this.FurtherDetailsForm.value.reseller; }
      if (this.FurtherDetailsForm.value.retailer) { this.entity.company.retailer = this.FurtherDetailsForm.value.retailer; }
    }
    if (this.FinancialInformationForm.value) {
      if (this.FinancialInformationForm.value.vatNumber) { this.entity.company.vatNumber = this.FinancialInformationForm.value.vatNumber; }
      if (this.FinancialInformationForm.value.sDLNumber) { this.entity.company.sDLNumber = this.FinancialInformationForm.value.sDLNumber; }
      if (this.FinancialInformationForm.value.taxNumber) { this.entity.company.taxNumber = this.FinancialInformationForm.value.taxNumber; }
      if (this.FinancialInformationForm.value.financialYearEnd) { this.entity.company.financialYearEnd = this.FinancialInformationForm.value.financialYearEnd; }
    }
    if (this.CurrentWEEERecyclingForm.value) {
      if (this.CurrentWEEERecyclingForm.value.wEEEManagementMethod) { this.entity.company.wEEEManagementMethod = this.CurrentWEEERecyclingForm.value.wEEEManagementMethod; }
      if (this.CurrentWEEERecyclingForm.value.serviceProvider) { this.entity.company.serviceProvider = this.CurrentWEEERecyclingForm.value.serviceProvider; }
      if (this.CurrentWEEERecyclingForm.value.wEEETonnageDirectlyProcessed) { this.entity.company.wEEETonnageDirectlyProcessed = this.CurrentWEEERecyclingForm.value.wEEETonnageDirectlyProcessed; }
      if (this.CurrentWEEERecyclingForm.value.wEEETonnageViaServiceProvider) { this.entity.company.wEEETonnageViaServiceProvider = this.CurrentWEEERecyclingForm.value.wEEETonnageViaServiceProvider; }
    }
    if (this.EntityDetails.value) {
      if (this.EntityDetails.value.name) { this.entity.entity.name = this.EntityDetails.value.name; }
      if (this.EntityDetails.value.surname) { this.entity.entity.surname = this.EntityDetails.value.surname; }
      if (this.EntityDetails.value.note) { this.entity.entity.note = this.EntityDetails.value.note; }
      if (this.EntityDetails.value.entityContactNumber) { this.entity.entityContactNumber = this.EntityDetails.value.entityContactNumber; }
      if (this.EntityDetails.value.entityEmailAddress) { this.entity.entityEmailAddress = this.EntityDetails.value.entityEmailAddress; }
    }
    this.entity.sendEmails = true;
    this.apiService.register(this.entity).subscribe(x => {
      this.options.saving = false;
      this.options.complete = true;
    }, error => {
      this.options.saving = false;
    });
  }
}
