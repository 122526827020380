import { Component, ElementRef, OnInit, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ApiAccessService } from "../api-access.service";
import { EntityGood } from "../models/entity-good.model";
import { Good } from "../models/good.model";
import { SystemCode, SystemCodeModel } from '../models/system-code.model';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { alertRecieved } from "../state/system/system.actions";
import { Store } from "@ngrx/store";
import { AppState } from "../state/app.state";
import { Alert } from "../models/alert.model";
import { Attachment } from "../models/attachment.model";
import { HttpResponse } from "@angular/common/http";

@Component({
  selector: 'app-goods-management',
  templateUrl: './goods-management.component.html',
  styleUrls: ['./goods-management.component.css']
})
export class GoodsManagementComponent implements OnInit {
  socket: WebSocket;
  waste_classes: SystemCode[];
  goods: EntityGood[] = [];
  to_delete: EntityGood;
  all_goods: EntityGood[] = [];
  confirm_dialog: NgbModalRef;
  delete_good_message = "Are you sure you want to delete this goods from the basket? This process cant be undone.";
  delete_good_title = "Remove Good";
  @ViewChild('upload_attachment') upload_attachment: ElementRef<HTMLElement>;
  find: {
    description: Good[],
    hsCode: Good[],
    category: Good[],
    changed: string,
    selected: string
  };
  keyword = {
    description: "description",
    hsCode: "hsCode",
    category: "category"
  };
  process = {
    uploading: false,
    header: "Uploading File",
    started: false,
    processing: false,
    completed: false,
    progress_mode: 0,
    progress: 0,
    total: 0,
    percentage: "0%",
    action: "Generating Goods",
    message_busy: "Generating Goods",
    message_before_html: "<p>Please wait a moment while this process begins.</p><p>Download button in top right for template with headers, make sure that the uploaded file in same format (column names matter).</p><p>Please put number in policy column even if a 1 as it helps the system identify that its a row to add.</p><p>Note if this process fails in the first 30 seconds it usually means file uploaded is incorrectly formated.</p>",
    message_complete_html: ""
  };
  upload_data = {
    Total: 0,
    Progress: 0,
    Goods: 0,
    Start: 0,
    Attach: 0,
    Message: ""
  };
  period_info: {
    msg: string,
    type_selected: boolean,
    types: string[],
    years: string[],
    periods: SystemCode[],
    all_periods: SystemCode[]
  };

  report_options = [{
      disabled: false,
      text: "Microsoft Excel Open XML Format Spreadsheet (xlsx)",
      value: "xlsx"
    }, {
      disabled: false,
      text: "Comma Separated Values (csv)",
      value: "csv"
    }, {
      disabled: false,
      text: "Portable Document Format (pdf)",
      value: "pdf"
    }
  ];

  getting_goods: boolean = false;
  saving_good: boolean = false;
  selected_period: boolean = false;
  good: Good;

  periodForm: FormGroup;
  searchForm: FormGroup;
  goodsForm: FormGroup;
  tempGoodsForm: any;

  constructor(private apiService: ApiAccessService, private formBuilder: FormBuilder, private modalService: NgbModal, private state: Store<AppState>) { }

  ngOnInit(): void {
    this.period_info = {
      msg: "",
      type_selected: false,
      types: [],
      years: [],
      periods: [],
      all_periods: []
    };
    this.to_delete = null;
    this.find = {
      description: [],
      category: [],
      hsCode: [],
      changed: "",
      selected: ""
    }
    window.scrollTo(0, 0);
    this.periodForm = new FormGroup({
      period: new FormControl(),
      type: new FormControl(),
      year: new FormControl(),
      download_type: new FormControl()
    });
    this.searchForm = new FormGroup({
      search: new FormControl()
    });
    this.tempGoodsForm = {
      description: new FormControl(),
      hsCode: new FormControl(),
      category: new FormControl(),
      wasteClass: new FormControl(),
      units: new FormControl(0),
      avgKg: new FormControl(0.000000),
      totalKg: new FormControl(0),
      tariff: new FormControl({ value: 0, disabled: true }),
    };
    this.goodsForm = new FormGroup(this.tempGoodsForm);
    this.getSystemCodes();
    this.get_goods();
  }

  period_select_type() {
    this.period_info.type_selected = true;
    this.period_info.periods = [];
    this.period_info.msg = "-Select " + this.periodForm.value.type.replace("ly", "") + "-";
  }

  period_select_year() {
    if (this.periodForm.value.year) {
      this.period_info.periods = this.period_info.all_periods.filter(x => x.code == this.periodForm.value.type && x.value.substring(0, 4) == this.periodForm.value.year);
    }
  }

  get_goods(ignore?: boolean) {
    if (this.periodForm.value.period) {
      this.getting_goods = true;
      this.all_goods = [];
      this.goods = [];
      let temp = this.apiService.get_goods(this.periodForm.value.period).subscribe(x => {
        this.selected_period = true;
        if (x) {
          this.all_goods = x;
          this.goods = x;
          if (!ignore) this.cancel_good();
          this.getting_goods = false;
        } 
        temp.unsubscribe();
      });
    } else {
      this.selected_period = false;
    }
  }

  getSystemCodes() {
    let s: SystemCodeModel[] = [
      { name: "periods", context: "Good", field: "Period", active: true },
      { name: "wasteClasses", context: "Good", field: "WasteClass", active: true }
    ];
    let temp = this.apiService.get_system_codes(s).subscribe(x => {
      if (x["wasteClasses"]) { this.waste_classes = x["wasteClasses"]; }
      if (x["periods"]) {
        this.period_info.all_periods = x["periods"];
        for (var i = 0; i < this.period_info.all_periods.length; i++) {
          if (this.period_info.years.indexOf(this.period_info.all_periods[i].value.substring(0, 4)) == -1) {
            this.period_info.years.push(this.period_info.all_periods[i].value.substring(0, 4));
          }
          if (this.period_info.types.indexOf(this.period_info.all_periods[i].code) == -1) {
            this.period_info.types.push(this.period_info.all_periods[i].code);
          }
        }
      }
      temp.unsubscribe();
    });
  }

  assignCopy() {
    this.goods = Object.assign([], this.all_goods);
  }
  search(value) {
    if (!value) {
      this.assignCopy();
    } // when nothing has typed
    this.goods = Object.assign([], this.all_goods).filter(
      item => item.good.description.toLowerCase().indexOf(value.toLowerCase()) > -1
        || item.good.hsCode.toLowerCase().indexOf(value.toLowerCase()) > -1
        || item.good.category.value.toLowerCase().indexOf(value.toLowerCase()) > -1
        || item.wasteClass.value.toLowerCase().indexOf(value.toLowerCase()) > -1
    )
  }

  calc_totalKg() {
    if (this.goodsForm.value.avgKg && this.goodsForm.value.units) {
      this.goodsForm.get('totalKg').setValue(+this.goodsForm.value.avgKg * +this.goodsForm.value.units);
    }
  }

  update_entity_good(good: EntityGood, type: string, event: any) {
    if (good.id) {
      if (type == "wasteClass") {
        let value = this.waste_classes.find(x => x.id == this.goodsForm.value[type + "_" + event]);
        good[type] = value;
        if (good[type].code) { good["tariff"] = +good[type].code; }
      } else {
        good[type] = +event.target.value;
      }
      this.apiService.update_entity_good(good).subscribe(result => {
        this.get_goods(true)//good = result;
      });
    }
  }

  remove_good(good: EntityGood, delete_goods: any) {
    if (good?.id) {
      this.to_delete = good;
      this.confirm_dialog = this.modalService.open(delete_goods, {});
    }
  }

  close_dialog(event) {
    this.to_delete = null;
    this.confirm_dialog.close();
  }

  confirm_delete_good(event) {
    if (this.to_delete?.id) {
      this.apiService.delete_entity_good(this.to_delete.id).subscribe(x => {
        this.get_goods(true);
        this.to_delete = null;
        this.confirm_dialog.close();
      });
    }
  }

  create_good() {
    if (this.goodsForm.invalid) { return; }
    this.saving_good = true;
    let result: EntityGood = {
      id: 0,
      units: this.goodsForm.value.units ?? 0,
      avgKg: this.goodsForm.value.avgKg ?? this.goodsForm.controls["avgKg"].value ?? 0,
      avgKgOld: 0,
      avgLifeSpan: 0,
      tariff: this.goodsForm.controls["tariff"].value ?? 0,
      selected: true,
      period: this.periodForm.value.period ?? "2022001",
      wasteClass: this.goodsForm.value.wasteClass ?? null,
      good: this.good ?? {
        hsCode: this.goodsForm.value.hsCode ?? "",
        description: this.goodsForm.value.description ?? "",
        avgKg: this.goodsForm.value.avgKg ?? 0,
        category: this.good?.category?.id ? this.good.category : this.goodsForm.value.category ? {
          context: "Good",
          field: "Category",
          value: this.goodsForm.value.category
        } : null
      }
    };
    let tarrif = this.goodsForm.get('tariff');
    this.apiService.create_entity_good(result).subscribe(x => {
      this.get_goods();
    });
  }

  cancel_good() {
    //clear form
    this.goodsForm.reset();
    let temp = Object.assign([], this.tempGoodsForm);
    for (var i = 0; i < this.all_goods.length; i++) {
      if (this.all_goods[i].wasteClass?.id) temp["wasteClass_" + i] = new FormControl(this.all_goods[i].wasteClass.id);
      else temp["wasteClass_" + i] = new FormControl(0);
    }
    this.goodsForm = new FormGroup(temp);
    this.good = null;
  }

  selectWasteClass() {
    if (this.goodsForm.value.wasteClass && this.goodsForm.value.wasteClass.code) {
      this.goodsForm.get('tariff').setValue(+this.goodsForm.value.wasteClass.code);
    }
  }

  onChangeFind(item) {
    if (this.find.changed) {
      if (item.length >= 3) {
        let good: Good = {};
        good[this.find.changed] = item;
        this.apiService.find_goods(good).subscribe(x => {
          this.find[this.find.changed] = x;
        })
      }
    }
  }

  onSelectedFind(item: Good) {
    if (this.find.selected) {
      this.good = item;
      if (item.description) { this.goodsForm.controls["description"].setValue(item.description); }
      if (item.hsCode) { this.goodsForm.controls["hsCode"].setValue(item.hsCode); }
      if (item.category && item.category.value) { this.goodsForm.controls["category"].setValue(item.category.value); }
      if (item.avgKg && !this.goodsForm.controls["avgKg"].value) { this.goodsForm.controls["avgKg"].setValue(item.avgKg); }
    }
  }

  cancel_upload(event: any) {
    if (event.target.files.length == 0) {
      this.process.uploading = false;
    }
  }

  upload_file(event: any) {
    if (event.target.files.length > 0) {

      this.process.uploading = true;
      let file = event.target.files[0];
      this.apiService.upload_temp_file(file).subscribe(temp => {
        //update message to say file loadded now unpacking
        let attachment: Attachment = {
          name: file.name,
          contentType: file.type,
          relativeName: "migration",
          tempFile: temp.tempFileName,
          path: "a place",
          id: 0
        };
        //start cocket connection
        this.apiService.create_attachment(attachment).subscribe(x => {
          event.target.value = null;
          this.start_socket(x);
        }, error => {
          this.process.uploading = false;
          this.process.message_busy = "Error Uploading File";
        });
      }, error => {
        this.process.uploading = false;
        this.process.message_busy = "Error Uploading File";
      });
    } else {
      this.process.uploading = false;
    }
  }

  upload_model(uploadModel) {
    this.modalService.open(uploadModel, {
      backdrop: 'static',
      keyboard: false
    });

    this.process.started = false;
    this.process.processing = false;
    this.process.completed = false;
    this.process.header = "Uploading File";
    this.process.message_before_html = "<p>Please wait a moment while this process begins.</p><p>Download button in top right for template with headers, make sure that the uploaded file in same format (column names matter).</p><p>Please put number in policy column even if a 1 as it helps the system identify that its a row to add.</p><p>Note if this process fails in the first 30 seconds it usually means file uploaded is incorrectly formated.</p>";

    this.upload_attachment.nativeElement.click();
  }

  download_template() {
    this.apiService.get_file("attachment/file/1");
  }

  download_goods() {
    this.apiService.get_file("report/basket-of-goods/for-me/" + this.periodForm.value.period + "?format=" + this.periodForm.value.download_type);
  }

  start_socket(attachment: Attachment) {
    this.socket = new WebSocket(this.apiService.get_upload_url());
    let ctrl = this;
    this.socket.onopen = function (event) {
      ctrl.process.action = "Unpacking data for migration...";
      ctrl.process.progress_mode = 0;
      ctrl.process.processing = true;
      this.send(JSON.stringify({ 'Entity': 0, 'Attach': attachment.id, 'Period': ctrl.periodForm.value.period }));
    }

    this.socket.onmessage = function (event) {
      if (!ctrl.process.started) {
        ctrl.process.action = "Creating Goods for you...";
        ctrl.process.progress_mode = 1;
        ctrl.process.started = true;
      }
      var data = JSON.parse(event.data);
      ctrl.upload_data = {
        Total: data.Total,
        Progress: data.Progress,
        Start: data.Start,
        Attach: data.Attach,
        Goods: data.Goods,
        Message: data.Message
      };
      ctrl.process.progress = ctrl.upload_data.Goods;
      ctrl.process.total = ctrl.upload_data.Total;
      ctrl.process.percentage = ctrl.upload_data.Progress + "%";
    }

    this.socket.onerror = function (event) {
      ctrl.process.completed = true;
      ctrl.process.processing = false;
      ctrl.process.header = "Error Completing migration";
      ctrl.process.message_before_html = "<p>Note failed to migrate goods.</p><p>Please contact surport if this issue persists.</p><p>Possible reasons for failure include: </p><ul><li><p>File is not excel document.</p></li><li><p>Information in document information is incorrectly formated.</p></li><li><p>Document is not in same format as downloadable template.</p></li><li><p>If this message appeared immediately might be hat you have automatically been signed.</p></li></ul>";
      if (ctrl.upload_data.Message != "") ctrl.process.message_before_html = "<p>NOTE: " + ctrl.upload_data.Message + "</p>" + ctrl.process.message_before_html;
      ctrl.process.uploading = false;
    }

    this.socket.onclose = function (event) {
      if (event.code === 1000) {
        ctrl.process.completed = true;
      } else if (event.code === 4001) {
        ctrl.process.processing = false;
        if (event.reason) { ctrl.state.dispatch(alertRecieved(new Alert(event.reason, 3))); }
      } else {
        ctrl.process.processing = false;
        if (event.reason) { ctrl.state.dispatch(alertRecieved(new Alert(event.reason, 2))); }
      }
      if (!ctrl.process.completed) {
        ctrl.process.header = "Error Completing migration";
        ctrl.process.message_before_html = "<p>Error Completing migration...</p>";
        if (ctrl.upload_data.Message != "") ctrl.process.message_before_html += "<p>NOTE: " + ctrl.upload_data.Message + "</p>";

        if (event.reason) {
          let split = event.reason.split('.');
          for (var i = 0; i < split.length; i++) {
            if (split[i] != "") ctrl.process.message_before_html += "<p>" + split[i] + ".</p>";
          }
        }
      } else {
        ctrl.process.header = "Migration Complete";
        ctrl.process.action = "Migration Results for Period: " + ctrl.periodForm.value.period;
        ctrl.process.progress = ctrl.upload_data.Goods;
        ctrl.process.total = ctrl.upload_data.Total;
        ctrl.process.percentage = ctrl.upload_data.Progress + "%";
        ctrl.process.message_complete_html = "<p>Migration Completed with stats as follows:</p><p>Total Goods Found: " + ctrl.upload_data.Total + " </p>";
        if (ctrl.process.total != ctrl.upload_data.Goods) {
          ctrl.process.message_complete_html += "<p>Number of Goods with no issues: " + ctrl.upload_data.Goods + "</p>"
          ctrl.process.message_complete_html += "<p>Number of Goods with errors in file: " + (ctrl.process.total - ctrl.upload_data.Goods) + "</p>"
        }
        if (ctrl.upload_data.Message != "") ctrl.process.message_complete_html = "<p>NOTE: " + ctrl.upload_data.Message + "</p>" + ctrl.process.message_complete_html;

        if (ctrl.upload_data.Attach) {
          ctrl.apiService.get_file("attachment/file/" + ctrl.upload_data.Attach);
          ctrl.get_goods();
        }
      }
      ctrl.process.uploading = false;
    }
  }
}
