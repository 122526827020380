import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SignInData } from '../../models/sign-in-data.model';
import { userSignIn } from '../../state/auth/auth.actions';
import { AuthState } from '../../state/auth/auth.reducer';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Output()
  close_model = new EventEmitter<string>();

  loginForm: FormGroup;

  registerModal: NgbModalRef;
  resetPasswordModal: NgbModalRef;

  constructor(private store: Store<AuthState>, private modalService: NgbModal) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      username: new FormControl(),
      password: new FormControl()
    });

    window.scrollTo(0, 0);
  }

  openRegister(register) {
    this.registerModal = this.modalService.open(register, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  open_reset_password(reset_password) {
    this.resetPasswordModal = this.modalService.open(reset_password, {});
  }

  submit_loginform(data) {
    let result: SignInData = { username: this.loginForm.value.username, deviceKey: "dev.easyprop.temodigital.com", password: this.loginForm.value.password };
    this.store.dispatch(userSignIn(result));
    this.close("Login Complete");
  }

  close(message: string) {
    this.close_model.emit(message);
  }

  close_register() {
    this.registerModal.close();
  }

  close_reset_password() {
    this.resetPasswordModal.close();
  }

  reset_password_complete() {
    this.resetPasswordModal.close();
  }
}
