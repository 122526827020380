export const navbarData = [
  {
  
    routeLink: 'service-portal/company-details',
    img: "assets/era/ProviderIcons/Company-Details.svg",
    label: 'Company Details',
  },
  {
   
    routeLink: 'service-portal/upload-documents',
    img: "assets/era/ProviderIcons/Upload-Documents.svg",
    label: 'Upload Documents',
  },
  {
  
    routeLink: 'service-portal/management',
    img: "assets/era/ProviderIcons/Management.svg",
    label: 'Management',
  },
  {
    
    routeLink: 'service-portal/services-rendered',
    img: "assets/era/ProviderIcons/WEEE-Services-Rendered.svg",
    label: 'WEEE Services Rendered',
  },
  {
   
    routeLink: 'service-portal/waste-collector',
    img: "assets/era/ProviderIcons/Waste-Collector.svg",
    label: 'Waste Collector',
  },
  {

    routeLink: 'service-portal/dismantling-consolidation',
    img: "assets/era/ProviderIcons/WEEE-Dismantling-and-Consolidation.svg",
    label: 'WEEE Dismantling and Consolidation',
  },
  {
    
    routeLink: 'service-portal/site-info',
    img: "assets/era/ProviderIcons/Site-Information.svg",
    label: 'Site Information',
  },
  {
  
    routeLink: 'service-portal/business-process',
    img: "assets/era/ProviderIcons/Business-Mode-and-Process-flow.svg",
    label: 'Business Model and Process Flow',
  }
];
