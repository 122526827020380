<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Login</h4>
  <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="close('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="login-box__form">
    <h2>Login</h2>
    <form [formGroup]="loginForm" (ngSubmit)="submit_loginform(loginForm.value)" class="ng-pristine ng-invalid ng-touched">
      <div class="form-group">
        <label class="form-group__label">
          <span>Username</span>
        </label>
        <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" formControlName="username" required type="text">
      </div>
      <div class="form-group">
        <label class="form-group__label">
          <span>Password</span>
        </label>
        <input class="form-group__control ng-untouched ng-pristine ng-invalid" formControlName="password" required type="password">
      </div>
      <br />
      <p class="center">
        <a class="center forgot-password" (click)="open_reset_password(reset_password);">
          Forgot your Password?
        </a>
      </p>
      <div class="form-actions">
        <button class="button button--dark-blue" type="submit" value="Login" (click)="close('Cross click')" [disabled]="!loginForm.valid">
          Login
        </button>
      </div>
      <p class="center">
        <a class="center forgot-password" (click)="openRegister(register)">
          Not Registered?
        </a>
      </p>
    </form>
  </div>
</div>

<ng-template #register>
  <app-register (close_model)="close_register($event)"></app-register>
</ng-template>

<ng-template #reset_password>
  <app-password-reset (close)="close_reset_password($event)" (completed)="reset_password_complete($event)"></app-password-reset>
</ng-template>
