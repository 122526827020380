import { Component } from '@angular/core';
import { AuthState } from './state/auth/auth.reducer';
import { Store } from '@ngrx/store';
import { userInfo } from './state/auth/auth.actions';


@Component({
  selector: '[app-root]',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
 
  constructor(private store: Store<AuthState>) { }

  ngOnInit() { this.store.dispatch(userInfo()); }
}
