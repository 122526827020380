<div *ngIf="options.complete else notComplete">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Registration Complete</h4>
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="close('cross clicked')"></button>
  </div>
  <div class="modal-body center">
    <p>Thank you for completing the registration process.</p>
    <p>An Email will be sent to the following email address with the next steps in this process.</p>
    <p><strong>{{entity.entityEmailAddress}}</strong></p>
  </div>
  <div class="form-actions row">
    <button class="button btn-primary col-11" type="button" value="Cancel" (click)="close()">
      Close
    </button>
  </div>
</div>


<ng-template class="full-page" #notComplete>
  <div class="container-container" #viewTop>

    <div class="first-container">
      <div class="first-container-container">
        <div class="sidenav">
          <span class="sidnav-left">
            <img class="img-fluid" src="assets/era/ProviderIcons/Profile-Picture.svg">
          </span>
          <div class="sidnav-right">
            <div class="sidnav-right"><h5>ProducerX</h5></div>
            <div class="sidnav-right sidnav-text-top">Producer@demo.com</div>
          </div>
        </div>
        <div class="sidenav-first" *ngFor="let data of navData" [routerLink]="[data.routeLink]" routerLinkActive="active">
          <span class="sidnav-left">
            <img class="img-fluid" [src]="data.img">
          </span>
          <span class="">{{data.label}}</span>
        </div>
        
      </div>
    </div>

    <div class="second-container">

      <div class="content">


        <router-outlet></router-outlet>

      </div>

    </div>

  </div>
  <div class="footer-sec">

    <div class="page-save">
      <button class="submit" type="button" (click)="save('submit')">SUBMIT</button>
      </div>
  </div>
</ng-template>



