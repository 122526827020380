import { navbarData } from './nav-data';
import { Router, NavigationStart } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiAccessService } from '../api-access.service';
import { Attachment } from '../models/attachment.model';
import { Entity } from '../models/entity.model';
import { Registration } from '../models/registration.model';
import { RequirementDetail } from '../models/requirement-detail.model';
import { Requirement } from '../models/requirement.model';
import { SystemCode, SystemCodeModel } from '../models/system-code.model';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}


@Component({
  selector: 'app-service-portal',
  templateUrl: './service-portal.component.html',
  styleUrls: ['./service-portal.component.css']
})
export class ServicePortalComponent implements OnInit {

  isSideNavCollapsed = false;
  fullpage: boolean = true;
  first: boolean = false;
  entity: Registration;
  navData = navbarData;
  @Output()
  close_model = new EventEmitter<string>();
  options: {
    saving: boolean,
    duplicate: boolean,
    got_system_codes: boolean,
    uploading: boolean,
    complete: boolean,
    requirementClicked: RequirementDetail,
  }

  constructor(private router: Router, private apiService: ApiAccessService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.scrollTop();
      }
    });
}

  ngOnInit(): void {
    this.reset_forms();
    this.get_system_codes();
    this.scrollTop();
  }

  get_system_codes() {
    if (!this.options.got_system_codes) {
      let s: SystemCodeModel[] = [ ];
      let temp = this.apiService.get_system_codes(s).subscribe(x => {
        this.apiService.get_requirements(0, { service: "entity", category: "6. Document Uploads" }).subscribe(x => {
          this.entity.requirements = x;
        });
        temp.unsubscribe();
      });
    }
  }

  reset_forms() {
    this.entity = {
      attachments: [],
      company: {},
      companyEntity: {},
      contactPersons: [],
      entity: {},
      requirements: []
    };
    this.options = {
      saving: false,
      duplicate: false,
      got_system_codes: false,
      requirementClicked: null,
      uploading: false,
      complete: false
    };
  }

  scrollTop() {
    var scrollTop = document.querySelector(".container-container");
    scrollTop.scrollIntoView();
  }

  close() {
    this.router.navigateByUrl('');
  }


  save(type) {
    if (!sessionStorage.length) {
      window.alert("Please fill out the forms");
      return;
    }
   
      if (sessionStorage.getItem('companyDetails') != "true") {
        window.alert("Company Details Form is incomplete");
        return;
      }
      if (sessionStorage.getItem('UploadDocuments') != "true") {
        window.alert("Upload Document Form is incomplete");
        return;
      }
      if (sessionStorage.getItem('Management') != "true") {
        window.alert("Management Form is incomplete");
        return;
      }
      if (sessionStorage.getItem('servicesRendered') != "true") {
        window.alert("Services Rendered Form is incomplete");
        return;
      }
      if (sessionStorage.getItem('siteInfo') != "true") {
        window.alert("Site Info Form is incomplete");
        return;
      }
      if (sessionStorage.getItem('WasteCollector') != "true") {
        window.alert("Waste Collector Form is incomplete");
        return;
      }
      if (sessionStorage.getItem('businessProcess') != "true") {
        window.alert("Business Process Form is incomplete");
        return;
      }
      if (sessionStorage.getItem('dismantlingConsolidation') != "true") {
        window.alert("Dismantling Consolidation Form is incomplete");
        return;
      }


    if (this.options.saving) {
      window.alert("Saving in progress.");
      return;
    }
    //for (var i = 0; i < this.entity.requirements.length; i++) {
    //  if (!this.entity?.requirements[i].attachments?.length) {
    //    window.alert("All attachments not supplied.");
    //    return;
    // }
    //}
    this.options.saving = true;

    let ctrl = this;
    //get all information into entity model and send via api call
      var companydetailsform = JSON.parse(sessionStorage.getItem('companyDetailsform'));
      var managementform = JSON.parse(sessionStorage.getItem('managementform'));
      var siteinfoform = JSON.parse(sessionStorage.getItem('siteinfoform'));
      var wastecollectorform = JSON.parse(sessionStorage.getItem('wastecollectorform'));
      var dismantlingform = JSON.parse(sessionStorage.getItem('dismantlingform'));
      var businessprocessform = JSON.parse(sessionStorage.getItem('businessprocessform'));
      var servicesRenderedform = JSON.parse(sessionStorage.getItem('servicesRenderedform'));
      var documents = JSON.parse(sessionStorage.getItem('documents'));

      //uploadDocument
      this.entity.requirements = documents;

      //companydetails
      if (companydetailsform.wasteCollector) { this.entity.company.wasteCollector = companydetailsform.wasteCollector; }
      if (companydetailsform.recycler) { this.entity.company.recycler = companydetailsform.recycler; }
      if (companydetailsform.transporter) { this.entity.company.transporter = companydetailsform.transporter; }
      if (companydetailsform.legalEntity) { this.entity.companyEntity.surname = companydetailsform.legalEntity; }
      if (companydetailsform.tradeName) { this.entity.companyEntity.name = companydetailsform.tradeName; }
      if (companydetailsform.prividerRegistration) { this.entity.companyEntity.identity = companydetailsform.providerRegistration; }
      if (companydetailsform.address) { this.entity.company.registeredAddress = companydetailsform.address; }
      if (companydetailsform.taxNumber) { this.entity.company.taxNumber = companydetailsform.taxNumber; }
      if (companydetailsform.vatNumber) { this.entity.company.vatNumber = companydetailsform.vatNumber; }
      if (companydetailsform.companyEmail) { this.entity.company.companyEmail = companydetailsform.companyEmail; }
      if (companydetailsform.financeEmail) { this.entity.company.financeEmail = companydetailsform.financeEmail; }
      if (companydetailsform.yearEstablished) { this.entity.company.yearEstablished = parseInt(companydetailsform.yearEstablished,10); }
      if (companydetailsform.yearsAsProvider) { this.entity.company.yearsAsProvider = parseInt(companydetailsform.yearsAsProvider,10); }
      if (companydetailsform.physicalAddress) { this.entity.company.physicalAddress = companydetailsform.physicalAddress; }
      if (companydetailsform.websiteAddress) { this.entity.company.websiteAddress = companydetailsform.websiteAddress; }
    if (companydetailsform.WEEEServicesLocations) { this.entity.company.WEEEServicesLocations = companydetailsform.WEEEServicesLocations; }

      //managemntform
      if (managementform.financeManager) { this.entity.company.financeManager = managementform.financeManager; }
      if (managementform.technicalManager) { this.entity.entity.name = managementform.technicalManager; }
      if (managementform.technicalManagerNumber) { this.entity.entityContactNumber = managementform.technicalManagerNumber; }
      if (managementform.technicalManagerEmail) { this.entity.entityEmailAddress = managementform.technicalManagerEmail; }
      if (managementform.CEO) { this.entity.company.CEO = managementform.CEO; }
      if (managementform.CEONumber) { this.entity.company.CEONumber = managementform.CEONumber; }
      if (managementform.CEOEmail) { this.entity.company.CEOEmail = managementform.CEOEmail; }

      //businessprocessfrom
      if (businessprocessform) {
        Object.keys(businessprocessform).forEach(function (key) {
          if (businessprocessform[key]) ctrl.entity.company[key] = businessprocessform[key];
        });
      }
      //if (businessprocessform.focusMaterialTracking) { this.entity.company.focusMaterialTracking = businessprocessform.focusMaterialTracking; }
      //if (businessprocessform.transfrontierWEEELicence) { this.entity.company.transfrontierWEEELicence = businessprocessform.transfrontierWEEELicence; }
      //if (businessprocessform.municipalRegulations) { this.entity.company.municipalRegulations = businessprocessform.municipalRegulations; }
      //if (businessprocessform.NEWMWA2127) { this.entity.company.NEWMWA2127 = businessprocessform.NEWMWA2127; }
      //if (businessprocessform.dataSecurity) { this.entity.company.dataSecurity = businessprocessform.dataSecurity; }
      //if (businessprocessform.ISO18001) { this.entity.company.ISO18001 = businessprocessform.ISO18001; }
      //if (businessprocessform.ISO9001) { this.entity.company.ISO9001 = businessprocessform.ISO9001; }
      //if (businessprocessform.ISO4001) { this.entity.company.ISO4001 = businessprocessform.ISO4001; }
      //if (businessprocessform.letterofGoodStanding) { this.entity.company.letterofGoodStanding = businessprocessform.letterofGoodStanding; }
      //if (businessprocessform.secondhandGoodsLicence) { this.entity.company.secondhandGoodsLicence = businessprocessform.secondhandGoodsLicence; }
      //if (businessprocessform.GDARDRegistrationCertificate) { this.entity.company.GDARDRegistrationCertificate = businessprocessform.GDARDRegistrationCertificate; }
      //if (businessprocessform.GDARDRegistration) { this.entity.company.GDARDRegistration = businessprocessform.GDARDRegistration; }
      //if (businessprocessform.wasteManagementLicence) { this.entity.company.wasteManagementLicence = businessprocessform.wasteManagementLicence; }
      //if (businessprocessform.activityRecording) { this.entity.company.activityRecording = businessprocessform.activityRecording; }
      //if (businessprocessform.dataOutflowRecorder) { this.entity.company.dataOutflowRecorder = businessprocessform.dataOutflowRecorder; }
      //if (businessprocessform.shipmentRecorder) { this.entity.company.shipmentRecorder = businessprocessform.shipmentRecorder; }
      //if (businessprocessform.dataInflowRecorder) { this.entity.company.dataInflowRecorder = businessprocessform.dataInflowRecorder; }
      //if (businessprocessform.orderTicket) { this.entity.company.orderTicket = businessprocessform.orderTicket; }
      //if (businessprocessform.QMS) { this.entity.company.QMS = businessprocessform.QMS; }
      //if (businessprocessform.specialisationCertificate) { this.entity.company.specialisationCertificate = businessprocessform.specialisationCertificate; }
      //if (businessprocessform.complianceCertificate) { this.entity.company.complianceCertificate = businessprocessform.complianceCertificate; }
      //if (businessprocessform.paymentTimeFrame) { this.entity.company.paymentTimeFrame = businessprocessform.paymentTimeFrame; }
      //if (businessprocessform.collectionPreference) { this.entity.company.collectionPreference = businessprocessform.collectionPreference; }
      //if (businessprocessform.paymentCycle) { this.entity.company.paymentCycle = businessprocessform.paymentCycle; }
      //if (businessprocessform.perfomanceRoutine) { this.entity.company.perfomanceRoutine = businessprocessform.perfomanceRoutine; }
      //if (businessprocessform.weightMinimum) { this.entity.company.weightMinimum = businessprocessform.weightMinimum; }
      //if (businessprocessform.returnTime) { this.entity.company.returnTime = businessprocessform.returnTime; }
      //if (businessprocessform.overseasDestination) { this.entity.company.overseasDestination = businessprocessform.overseasDestination; }
      //if (businessprocessform.destinationLocal) { this.entity.company.destinationLocal = businessprocessform.destinationLocal; }
      //if (businessprocessform.consolidation) { this.entity.company.consolidation = businessprocessform.consolidation; }
      //if (businessprocessform.positiveReturn) { this.entity.company.positiveReturn = businessprocessform.positiveReturn; }
      //if (businessprocessform.recyclingCost) { this.entity.company.recyclingCost = businessprocessform.recyclingCost; }
      //if (businessprocessform.transportCost) { this.entity.company.transportCost = businessprocessform.transportCost; }
      //if (businessprocessform.doReport) { this.entity.company.doReport = businessprocessform.doReport; }
      //if (businessprocessform.WEEENotHandledType) { this.entity.company.WEEENotHandledType = businessprocessform.WEEENotHandledType; }
      //if (businessprocessform.WEEENotHandled) { this.entity.company.WEEENotHandled = businessprocessform.WEEENotHandled; }
      //if (businessprocessform.inspection) { this.entity.company.inspection = businessprocessform.inspection; }
      //if (businessprocessform.statusCheck) { this.entity.company.statusCheck = businessprocessform.statusCheck; }
      //if (businessprocessform.signContract) { this.entity.company.signContract = businessprocessform.signContract; }
      //if (businessprocessform.localDestination) { this.entity.company.localDestination = businessprocessform.localDestination; }
      //if (businessprocessform.consolidate) { this.entity.company.consolidate = businessprocessform.consolide; }
      //if (businessprocessform.disassemble) { this.entity.company.disassemble = businessprocessform.disassemble; }
      //if (businessprocessform.WEEEPercent) { this.entity.company.WEEEPercent = businessprocessform.WEEEPercent; }
      //if (businessprocessform.WEEEtonnage) { this.entity.company.WEEEtonnage = businessprocessform.WEEEtonnage; }
      //if (businessprocessform.Loc4Tonnage) { this.entity.company.Loc4Tonnage = businessprocessform.Loc4Tonnage; }
      //if (businessprocessform.Loc3Tonnage) { this.entity.company.Loc3Tonnage = businessprocessform.Loc3Tonnage; }
      //if (businessprocessform.Loc2Tonnage) { this.entity.company.Loc2Tonnage = businessprocessform.Loc2Tonnage; }
      //if (businessprocessform.Loc1Tonnage) { this.entity.company.Loc1Tonnage = businessprocessform.Loc1Tonnage; }
      //if (businessprocessform.historyTonnage022) { this.entity.company.historyTonnage022 = businessprocessform.historyTonnage022; }
      //if (businessprocessform.historyTonnage021) { this.entity.company.historyTonnage021 = businessprocessform.historyTonnage021; }
      //if (businessprocessform.historyTonnage020) { this.entity.company.historyTonnage020 = businessprocessform.historyTonnage020; }
      //if (businessprocessform.historyTonnage019) { this.entity.company.historyTonnage019 = businessprocessform.historyTonnage019; }

      //Wastecollectorform
      if (wastecollectorform) {
        Object.keys(wastecollectorform).forEach(function (key) {
          if (wastecollectorform[key]) ctrl.entity.company[key] = wastecollectorform[key];
        });
      }
      //if (wastecollectorform.wearBrandedPPE) { this.entity.company.wearBrandedPPE = wastecollectorform.wearBrandedPPE; }
      //if (wastecollectorform.serviceAgreement) { this.entity.company.serviceAgreement = wastecollectorform.serviceAgreement; }
      //if (wastecollectorform.training) { this.entity.company.training = wastecollectorform.training; }
      //if (wastecollectorform.personalTransportTonnage) { this.entity.company.personalTransportTonnage = wastecollectorform.personalTransportTonnage; }
      //if (wastecollectorform.personalTransport) { this.entity.company.personalTransport = wastecollectorform.personalTransport; }
      //if (wastecollectorform.WEEESource) { this.entity.company.WEEESource = wastecollectorform.WEEESource; }
      //if (wastecollectorform.monthlyWasteCollection) { this.entity.company.monthlyWasteCollection = wastecollectorform.monthlyWasteCollection; }
      //if (wastecollectorform.weeklyWasteCollection) { this.entity.company.weeklyWasteCollection = wastecollectorform.weeklyWasteCollection; }
      //if (wastecollectorform.dailyWasteCollection) { this.entity.company.dailyWasteCollection = wastecollectorform.dailyWasteCollection; }
      //if (wastecollectorform.wasteBuyerReason) { this.entity.company.wasteBuyerReason = wastecollectorform.wasteBuyerReason; }
      //if (wastecollectorform.wasteBuyerEmail) { this.entity.company.wasteBuyerEmail = wastecollectorform.wasteBuyerEmail; }
      //if (wastecollectorform.wasteBuyerNumber) { this.entity.company.wasteBuyerNumber = wastecollectorform.wasteBuyerNumber; }
      //if (wastecollectorform.wasteBuyerName) { this.entity.company.wasteBuyerName = wastecollectorform.wasteBuyerName; }
      //if (wastecollectorform.wasteBuyer) { this.entity.company.wasteBuyer = wastecollectorform.wasteBuyer; }
      //if (wastecollectorform.wasteCollectorSource) { this.entity.company.wasteCollectorSource = wastecollectorform.wasteCollectorSource; }
      //if (wastecollectorform.wasteCollectorAction) { this.entity.company.wasteCollectorAction = wastecollectorform.wasteCollectorAction; }
      //if (wastecollectorform.wasteCollectorEmail) { this.entity.company.wasteCollectorEmail = wastecollectorform.wasteCollectorEmail; }
      //if (wastecollectorform.wasteCollectorNumber) { this.entity.company.wasteCollectorNumber = wastecollectorform.wasteCollectorNumber; }
      //if (wastecollectorform.wasteCollectorContactName) { this.entity.company.wasteCollectorContactName = wastecollectorform.wasteCollectorContactName; }
      //if (wastecollectorform.wasteCollectorReferenceNo) { this.entity.company.wasteCollectorReferenceNo = wastecollectorform.wasteCollectorReferenceNo; }
      //if (wastecollectorform.wasteCollectorAssos) { this.entity.company.wasteCollectorAssos = wastecollectorform.wasteCollectorAssos; }

      //siteInfo
      if (siteinfoform) {
        Object.keys(siteinfoform).forEach(function (key) {
          if (siteinfoform[key]) ctrl.entity.company[key] = siteinfoform[key];
        });
      }
      //if (siteinfoform.facilityName) { this.entity.company.facilityName = siteinfoform.facilityName; }
      //if (siteinfoform.ERFSize) { this.entity.company.ERFSize = siteinfoform.ERFSize; }
      //if (siteinfoform.buildingSize) { this.entity.company.buildingSize = siteinfoform.buildingSize; }
      //if (siteinfoform.insideSpace) { this.entity.company.insideSpace = siteinfoform.insideSpace; }
      //if (siteinfoform.outsideSpace) { this.entity.company.outsideSpace = siteinfoform.outsideSpace; }
      //if (siteinfoform.lease) { this.entity.company.lease = siteinfoform.lease; }
      //if (siteinfoform.yearsLeased) { this.entity.company.yearsLeased = siteinfoform.yearsLeased; }
      //if (siteinfoform.physicalSecurity) { this.entity.company.physicalSecurity = siteinfoform.physicalSecurity; }
      //if (siteinfoform.dayOnlySecurity) { this.entity.company.dayOnlySecurity = siteinfoform.dayOnlySecurity; }
      //if (siteinfoform.nightOnlySecurity) { this.entity.company.nightOnlySecurity = siteinfoform.nightOnlySecurity; }
      //if (siteinfoform.allDaySecurity) { this.entity.company.allDaySecurity = siteinfoform.allDaySecurity; }
      //if (siteinfoform.alarmSystem) { this.entity.company.alarmSystem = siteinfoform.alarmSystem; }
      //if (siteinfoform.alarmMonitoring) { this.entity.company.alarmMonitoring = siteinfoform.alarmMonitoring; }
      //if (siteinfoform.alarmResponse) { this.entity.company.alarmResponse = siteinfoform.alarmResponse; }
      //if (siteinfoform.insurance) { this.entity.company.insurance = siteinfoform.insurance; }
      //if (siteinfoform.numberOffices) { this.entity.company.numberOffices = siteinfoform.numberOffices; }
      //if (siteinfoform.officeEquipment) { this.entity.company.officeEquipment = siteinfoform.officeEquipment; }
      //if (siteinfoform.wiFi) { this.entity.company.wiFi = siteinfoform.wiFi; }
      //if (siteinfoform.printer) { this.entity.company.printer = siteinfoform.printer; }
      //if (siteinfoform.laptops) { this.entity.company.laptops = siteinfoform.laptops; }
      //if (siteinfoform.financialSystem) { this.entity.company.financialSystem = siteinfoform.financialSystem; }
      //if (siteinfoform.operationalSystem) { this.entity.company.operationalSystem = siteinfoform.operationalSystem; }
      //if (siteinfoform.administrationSystem) { this.entity.company.administrationSystem = siteinfoform.administrationSystem; }
      //if (siteinfoform.onetonVehicle) { this.entity.company.onetonVehicle = siteinfoform.onetonVehicle; }
      //if (siteinfoform.fivetonVehicle) { this.entity.company.fivetonVehicle = siteinfoform.fivetonVehicle; }
      //if (siteinfoform.eighttonVehicle) { this.entity.company.eighttonVehicle = siteinfoform.eighttonVehicle; }
      //if (siteinfoform.tentonVehicle) { this.entity.company.tentonVehicle = siteinfoform.tentonVehicle; }
      //if (siteinfoform.fourteentonVehicle) { this.entity.company.fourteentonVehicle = siteinfoform.fourteentonVehicle; }
      //if (siteinfoform.twentytonVehicle) { this.entity.company.twentytonVehicle = siteinfoform.twentytonVehicle; }
      //if (siteinfoform.forklift) { this.entity.company.forklift = siteinfoform.forklift; }
      //if (siteinfoform.ownLogistics) { this.entity.company.ownLogistics = siteinfoform.ownLogistics; }
      //if (siteinfoform.logisticsSystem) { this.entity.company.logisticsSystem = siteinfoform.logisticsSystem; }
      //if (siteinfoform.collectionRadius) { this.entity.company.collectionRadius = siteinfoform.collectionRadius; }

      //servicesrendered
      if (servicesRenderedform) {
        Object.keys(servicesRenderedform).forEach(function (key) {
          if (servicesRenderedform[key]) ctrl.entity.company[key] = servicesRenderedform[key];
        });
      }
      //if (servicesRenderedform.collectorName) { this.entity.company.collectorName = servicesRenderedform.collectorName; }
      //if (servicesRenderedform.collectorAddress) { this.entity.company.collectorAddress = servicesRenderedform.collectorAddress; }
      //if (servicesRenderedform.buyBackCenter) { this.entity.company.buyBackCenter = servicesRenderedform.buyBackCenter; }
      //if (servicesRenderedform.consolidationPort) { this.entity.company.consolidationPort = servicesRenderedform.consolidationPort; }
      //if (servicesRenderedform.refurbisherName) { this.entity.company.refurbisherName = servicesRenderedform.refurbisherName; }
      //if (servicesRenderedform.dismantlerName) { this.entity.company.dismantlerName = servicesRenderedform.dismantlerName; }
      //if (servicesRenderedform.recyclerName) { this.entity.company.recyclerName = servicesRenderedform.recyclerName; }
      //if (servicesRenderedform.transportUsed) { this.entity.company.transportUsed = servicesRenderedform.transportUsed; }
      

      //dismantlingform
      if (dismantlingform) {
        Object.keys(dismantlingform).forEach(function (key) {
          if (dismantlingform[key]) ctrl.entity.company[key] = dismantlingform[key];
        });
      }
      //if (dismantlingform.totalNoSkilledStuff) { this.entity.company.totalNoSkilledStuff = dismantlingform.totalNoSkilledStuff; }
      //if (dismantlingform.fullTimeSkilledStuff) { this.entity.company.fullTimeSkilledStuff = dismantlingform.fullTimeSkilledStuff; }
      //if (dismantlingform.partTimeSkilledStuff) { this.entity.company.partTimeSkilledStuff = dismantlingform.partTimeSkilledStuff; }
      //if (dismantlingform.youthSkilledStuff) { this.entity.company.youthSkilledStuff = dismantlingform.youthSkilledStuff; }
      //if (dismantlingform.maleSkilledStuff) { this.entity.company.maleSkilledStuff = dismantlingform.maleSkilledStuff; }
      //if (dismantlingform.femaleSkilledStuff) { this.entity.company.femaleSkilledStuff = dismantlingform.femaleSkilledStuff; }
      //if (dismantlingform.disabledSkilledStuff) { this.entity.company.disabledSkilledStuff = dismantlingform.disabledSkilledStuff; }
      //if (dismantlingform.totalNoManager) { this.entity.company.totalNoManagers = dismantlingform.totalNoManager; }
      //if (dismantlingform.fullTimeManager) { this.entity.company.fullTimeManagers = dismantlingform.fullTimeManager; }
      //if (dismantlingform.partTimeManager) { this.entity.company.partTimeManagers = dismantlingform.partTimeManager; }
      //if (dismantlingform.youthManager) { this.entity.company.youthManagers = dismantlingform.youthManager; }
      //if (dismantlingform.maleManager) { this.entity.company.maleManagers = dismantlingform.maleManager; }
      //if (dismantlingform.femaleManager) { this.entity.company.femaleManagers = dismantlingform.femaleManager; }
      //if (dismantlingform.disabledManager) { this.entity.company.disabledManagers = dismantlingform.disabledManager; }
      //if (dismantlingform.totalNoGeneralWorkers) { this.entity.company.totalNoGeneralWorkers = dismantlingform.totalNoGeneralWorkers; }
      //if (dismantlingform.fullTimeGeneralWorkers) { this.entity.company.fullTimeGeneralWorkers = dismantlingform.fullTimeGeneralWorkers; }
      //if (dismantlingform.partTimeGeneralWorkers) { this.entity.company.partTimeGeneralWorkers = dismantlingform.partTimeGeneralWorkers; }
      //if (dismantlingform.youthGeneralWorkers) { this.entity.company.youthGeneralWorkers = dismantlingform.youthGeneralWorkers; }
      //if (dismantlingform.maleGeneralWorkers) { this.entity.company.maleGeneralWorkers = dismantlingform.maleGeneralWorkers; }
      //if (dismantlingform.femaleGeneralWorkers) { this.entity.company.femaleGeneralWorkers = dismantlingform.femaleGeneralWorkers; }
      //if (dismantlingform.disabledGeneralWorkers) { this.entity.company.disabledGeneralWorkers = dismantlingform.disabledGeneralWorkers; }
      //if (dismantlingform.totalNoDirectors) { this.entity.company.totalNoDirectors = dismantlingform.totalNoDirectors; }
      //if (dismantlingform.fullTimeDirectors) { this.entity.company.fullTimeDirectors = dismantlingform.fullTimeDirectors; }
      //if (dismantlingform.partTimeDirectors) { this.entity.company.partTimeDirectors = dismantlingform.partTimeDirectors; }
      //if (dismantlingform.youthDirectors) { this.entity.company.youthDirectors = dismantlingform.youthDirectors; }
      //if (dismantlingform.maleDirectors) { this.entity.company.maleDirectors = dismantlingform.maleDirectors; }
      //if (dismantlingform.femaleDirectors) { this.entity.company.femaleDirectors = dismantlingform.femaleDirectors; }
      //if (dismantlingform.disabledDirectors) { this.entity.company.disabledDirectors = dismantlingform.disabledDirectors; }

      this.entity.entity.userRole = "service_provider";

    if (type == "submit") {
      this.entity.sendEmails = true;
      this.apiService.register(this.entity).subscribe(x => {
        this.options.saving = false;
        this.options.complete = true;
        sessionStorage.clear();
      }, error => {
        this.options.saving = false;
      });
    }
  }
  
 
  hasRoute(router: string) {
    return this.router.url == "/service-portal";
  }

   is_first_load() {
    if (sessionStorage.length == 0) {
      return true;
    }
    return false;
  }

}

