import { Component, OnInit } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ApiAccessService } from '../api-access.service';
import { Transaction } from '../models/transaction.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private apiService: ApiAccessService) { }

  valuation = 0.00;
  valuationGain = 0.00;
  rentalDividend = 0.00;
  tileStatus = {
    profit: false,
    costs: false,
    accruals: false,
    funds: false,
    actions: false
  };

  activeTab = 0;
  activeButton = 0;

  button = {
    setAll: true,
    setOffer: false,
    setBid: false
  }

  transactions = [
    {
      date: new Date(2022, 1, 24),
      description: "Account Balance Carried Forward",
      amount: 50000.00
    },
    {
      date: new Date(2021, 11, 24),
      description: "Account Balance Carried Forward",
      amount: 10500.00
    },
    {
      date: new Date(2021, 9, 11),
      description: "Account Balance Carried Forward",
      amount: 5100.00
    },
  ]

  balanceList = [
    {
      Id: 0,
      DisplayName: 'Profit/Loss',
      Value: 0.00,
      Type: 1,
      toggle: false,
      Items: [
        {
          DisplayName: 'Rental Dividend',
          Value: 0.00
        },
        {
          DisplayName: 'Latest Valuation Gains',
          Value: 0.00
        },
        {
          DisplayName: 'Purchase Value',
          Value: 0.00
        },
        {
          DisplayName: 'Pending Purchases',
          Value: 0.00
        },
      ]
    },
    {
      Id: 1,
      DisplayName: 'Total Costs',
      Value: 0.00,
      Type: 1,
      toggle: false,
      Items: [
        {
          DisplayName: 'Brokerage Costs',
          Value: 0.00
        },
        {
          DisplayName: 'Statutory Costs',
          Value: 0.00
        },
        {
          DisplayName: 'IPO Capital raising fees',
          Value: 0.00
        },
      ]
    },
    {
      Id: 2,
      DisplayName: 'Net Accruals',
      Value: 0.00,
      Type: 1,
      toggle: false,
      Items: [
        {
          DisplayName: 'Accrued Interest Income',
          Value: 0.00
        },
        {
          DisplayName: 'Accrued Platform Fee',
          Value: 0.00
        },
      ]
    },
    {
      Id: 3,
      DisplayName: 'Your funds to invest',
      Value: 0.00,
      Type: 1,
      toggle: false,
      Items: [
        {
          DisplayName: 'Withdrawable Funds',
          Value: 0.00
        },
        {
          DisplayName: 'Reserved Funds',
          Value: 0.00
        },
        {
          DisplayName: 'Unsettled Cash',
          Value: 0.00
        },
        {
          DisplayName: 'Locked Funds',
          Value: 0.00
        },
      ]
    },
    {
      Id: 4,
      DisplayName: 'Account Actions',
      Value: null,
      Type: 0,
      toggle: false,
      Items: [
        {
          Icon: '../../assets/dashboard/icons/money.svg',
          Link: ' / wallet / fund - this - account',
          Text: 'Fund this account',
        },
        {
          Icon: '../../assets/dashboard/icons/arrow-circle-right.svg',
          Link: ' / wallet / withdraw - money',
          Text: 'Withdraw money',
        },
        {
          Icon: '../../assets/dashboard/icons/arrow-circle-bidirectional.svg',
          Link: ' / wallet / fund - this - account',
          Text: 'Inter - account transfers',
        },
      ]
    },
  ];

  toggleTile(tile) {
    this.balanceList[this.balanceList.findIndex(e => e.Id === tile)].toggle = !this.balanceList[this.balanceList.findIndex(e => e.Id === tile)].toggle;
  }
  
  properties = [
    {
      title: "The Rockefeller 2",
      img: "../../assets/browse-properties/ROCKEFELLER-022 1-1.webp",
      type: "Coastal Investment Apartments",
      status: "New Listing",
      address: "12 Christiaan Barnard, Foreshore",
      target: 16729808,
      balance: 5000000,
      rateOfReturn: 0.0970,
      netRentalYield: 0.0630,
      marketValuation: 27009000,
      discount: 0.0500,
      note: "Offer ends in 15 days"
    },
    {
      title: "The Monroe",
      img: "../../assets/browse-properties/MonroeView 2_DUSK-1.webp",
      type: "Urban Living Apartments",
      status: "Recently Funded",
      address: "39 Sturdee Ave, Rosebank",
      target: 12387225,
      balance: 13000000,
      rateOfReturn: 0.1090,
      netRentalYield: 0.0790,
      marketValuation: 16200000,
      discount: 0.0850,
      note: "Available on resale market soon"
    },
    {
      title: "Ballito Groves",
      type: "Multi-Family",
      status: "Recently Funded",
      address: "Dolphin Coast, Ballito, KwaZulu-Natal",
      target: 14278000,
      balance: 15000000,
      rateOfReturn: 0.0950,
      netRentalYield: 0.0650,
      marketValuation: 21690000,
      discount: 0.0600,
      note: "Available on resale market soon"
    },
    {
      title: "Four on O - Sea Point",
      img: "../../assets/browse-properties/12004_2.webp",
      type: "Coastal Investment Apartments",
      status: "Recently Funded",
      address: "4 Oliver Road, Sea Point",
      target: 13379226,
      balance: 13450000,
      rateOfReturn: 0.0970,
      netRentalYield: 0.0620,
      marketValuation: 19045000,
      discount: 0.1153,
      note: "Available on resale market soon"
    },
    {
      title: "The Bailey",
      img: "../../assets/browse-properties/Roomtech-6-2.webp",
      type: "Urban Living Apartments",
      status: "Recently Funded",
      address: "42 Berkeley Avenue, Bryanston",
      target: 15222350,
      balance: 15222350,
      rateOfReturn: 0.1160,
      netRentalYield: 0.0780,
      marketValuation: 23225000,
      discount: 0.0947,
      note: "Available on resale market soon"
    },
    {
      title: "The Reid",
      img: "../../assets/browse-properties/Apartment 719 76-1.webp",
      type: "Multi-Family",
      status: "Recently Funded",
      address: "28 Beacon Avenue, Linbro Park",
      target: 18985750,
      balance: 19300000,
      rateOfReturn: 0.1000,
      netRentalYield: 0.0757,
      marketValuation: 28290800,
      discount: 0.0457,
      note: "Available on resale market soon"
    },
  ]

  progress(balance, target) {
    return balance / target;
  }

  setProgressStyle(progress) {
    var progressStyle: Record<string, any> = {};

    progressStyle = {
      'background-color': Math.floor(progress * 100) / 100 < 1 ? '#005bff' : '#31ff00',
      'width': Math.floor(progress) < 1 ? Math.floor(progress * 100) + '%' : '100%'
    };

    return progressStyle;
  }

  setActiveTab(tab) {
    this.activeTab = tab;
  }

  setActiveButton(option) {

    this.button.setAll = false;
    this.button.setOffer = false;
    this.button.setBid = false;

    switch (option) {
      case 0:
        this.button.setAll = true;
        break;
      case 1:
        this.button.setOffer = true;
        break;
      case 2:
        this.button.setBid = true;
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.transaction$ = from(this.apiService.getTransaction());
  }

  transaction$: Observable<Transaction>
}
