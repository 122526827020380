<div class="page-cont">
  <div class="site-content">
    <div class="page-body">
      <div class="section-header">
        Section 2: Upload Documents
      </div>
      <form class="form-group" *ngIf="entity" autocomplete="off">
        <input type="file" class="upload-input" #add_requirement_attachment [hidden]="true" (change)="addRequirementFiles($event)" multiple />
        <div class="second-sec row" *ngFor="let s of entity.requirements | slice : 2">
          <label class="form-label col-sm-6">{{s.requirementObject.name}}</label>
          <div class="requirement-button icon btn-round col-sm-6" aria-label="Upload Attachment" [disabled]="options.uploading" (click)="options.requirementClicked = s; add_requirement_attachment.click()">
            <div class="row">
              <div class="col-sm-4 upload-input">
                <img src="assets/icons/icon-upload.png" width="25" alt="search">
                <span>Upload Document</span>
              </div>
              <div *ngIf="s.attachments" class="upload col-sm-8" style="border:1px solid black;">
                <div *ngFor="let item of s.attachments">{{item.name}}</div>
              </div>
            </div>
          </div>
          
        </div>
      </form>
      <div class="footer-sec">
        <div class="page-scroll">
          <button class="prev" (click)="pt1();save()">PREVIOUS</button>
          <span>2/8</span>
          <button class="next" (click)="pt2();save()">NEXT</button>
          <progress value="25" max="100"></progress>
        </div>
      </div>
    </div>
    </div>
  </div>
