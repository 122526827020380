
<div class="full-page" >
  <div class="container-container" >
    
    <div class="first-container">
      <div class="first-container-container">
        <div class="sidenav">
          <span class="sidnav-left">
            <img class="img-fluid" src="assets/era/ProviderIcons/Profile-Picture.svg">
          </span>
          <div class="sidnav-right">
            <div class="sidnav-right"><h5>ProducerX</h5></div>
            <div class="sidnav-right sidnav-text-top">Producer@demo.com</div>
          </div>
        </div>
        <div class="sidenav-first" *ngFor="let data of navData" [routerLink]="[data.routeLink]" routerLinkActive="active">
          <span class="sidnav-left">
            <img class="img-fluid" [src]="data.img">
          </span>
          <span class="sidnav-text">{{data.label}}</span>
        </div>
        <div class="sidnav-span"><span class="sidnav-text">Already Registered ?</span><u class="sidnav-sign-in">Sign In</u></div>
        <hr>
        <div class="sidenav">
          <span class="sidnav-text sidnav-left">Logout</span>
          <span class="sidnav-right-last">
            <img class="img-fluid" src="assets/era/ProviderIcons/Logout.svg">
          </span>
        </div>
      </div>
    </div>

    <div class="second-container">
     
        <router-outlet name="details"></router-outlet>

    
    </div>
  </div>
</div>



