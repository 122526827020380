import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiAccessService } from '../../api-access.service';
import { ResetPassword, ResetPasswordRequest } from '../../models/reset-password.model';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  @Output() close = new EventEmitter<string>();
  @Output() completed = new EventEmitter<string>();

  request: boolean = true;
  busy: boolean = false;

  resetpasswordForm: FormGroup;
  changepasswordForm: FormGroup;
  user_id: number;

  constructor(private apiService: ApiAccessService, private form_builder: FormBuilder) { }

  ngOnInit() {
    this.request = true;
    this.resetpasswordForm = new FormGroup({
      username: new FormControl('', Validators.required),
      method: new FormControl('Email', Validators.required)
    });

    this.changepasswordForm = this.form_builder.group({
      otp: ['', [Validators.required]],
      newpassword: ['', [Validators.required]],
      confirmpassword: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('newpassword', 'confirmpassword')
    });
  }

  close_modal(message: string) {
    this.close.emit(message);
  }

  req_change_password(changepassword) {
    if (this.resetpasswordForm.value.username) {
      this.busy = true;
      let request: ResetPasswordRequest = {
        username: this.resetpasswordForm.value.username,
        recaptcha: "",
        method: this.resetpasswordForm.value.method
      };
      this.apiService.request_reset_password(request).subscribe(x => {
        this.user_id = x.user.id;
        this.request = false;
        this.busy = false;
      }, error => {
        this.busy = false;
      });
    }
  }

  reset_password() {
    if (this.changepasswordForm.valid) {
      this.busy = true;
      let result: ResetPassword = {
        oTP: this.changepasswordForm.value.otp,
        password: this.changepasswordForm.value.newpassword,
        userId: this.user_id ?? 0,
      };
      this.apiService.reset_password(result).subscribe(x => {
        this.busy = false;
        this.completed.emit("");
      }, error => {
        this.busy = false;
      });
    }
  }
}

export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
