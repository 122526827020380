<header class="p-x bg-white text-black">
  <nav class="navbar navbar-expand-md navbar">
    <div class="nav-container">
      <div class="nav-container-container1">
        <a [routerLink]="['/']">
          <img src="assets/nav-menu/Logos/ar96c-i2ij1.svg" alt="Responsive image" class="img-fluid-logo" />
        </a>
        <div class="right">
          <button class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarCollapse"
                  aria-controls="navbarCollapse"
                  aria-label="Toggle navigation"
                  [attr.aria-expanded]="isExpanded">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>


      <div class="collapse navbar-collapse" id="navbarCollapse" style="position:relative">

        <div class="burger" *ngIf="systemUser$ | async as user else notSignedIn">
          <div class="nav-container-container2">
            <div class="nav-containers">
              <div class="nav-container-container-container">

                <a style="color:#000" href="tel:+27 21 001 2186">

                  +27 21 001 2186
                </a>
              </div>
              <div class="nav-container-container-container">

                <a style="color:#000" href="mailto:erainfo@eranpc.%63o.%7aa">
                  erainfo@eranpc.co.za
                </a>
              </div>
              <div class="nav-container-container-container">
                <a style="color:#000">
                  Cape Town, South Africa
                </a>
              </div>
              <div class="nav-container-container-container">
                <a style="color:#000;" [routerLinkActive]="['link-active']" [routerLink]="['/goods-management']">PoM Outputs</a>
              </div>
            </div>
          </div>
          <div class="nav-container-container33">
            <ul>
              <li class="col-2 nav-item dropdown" [routerLinkActive]="['link-active']">
                <a class="nav-link dropdown-toggle" href="#" id="navbarAbout" role="button" data-bs-toggle="dropdown" [attr.aria-expanded]="isAboutExpanded">
                  <svg class="nav-icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="40" height="40" viewBox="0 0 24 24" style="position:inherit">
                    <path d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
                  </svg>
                </a>
                <ul class="dropdown-menu" style="margin-left: -60px;" aria-labelledby="navbarAbout">
                  <li><a class="dropdown-item" [routerLinkActive]="['link-active']" [routerLink]="['/account/my-profile']">My Profile</a></li>
                  <li><a class="dropdown-item" (click)="open_reset_password(reset_password)">Change Password</a></li>
                  <li><a class="dropdown-item" [routerLinkActive]="['link-active']" (click)="logout()">Logout</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <ng-template #notSignedIn>
          <div class="nav-container-container2">
            <div class="nav-containers">
              <div class="nav-container-container-container">
                <a style="color:#000">
                  +27 21 001 2186
                </a>
              </div>
              <div class="nav-container-container-container">
                <a style="color:#000" href="mailto:erainfo@eranpc.%63o.%7aa">
                  erainfo@eranpc.co.za
                </a>
              </div>
              <div class="nav-container-container-container">
                <a style="color:#000">
                  Cape Town, South Africa
                </a>
              </div>
              <div class="nav-container-container-container">
                <img src="assets/nav-menu/Logos/a2rid-zbnz9.svg" alt="Responsive image" class="img-fluid-logo" />
              </div>
            </div>
          </div>
          <div class="nav-container-container3" style="display: contents;">
            <div class="dropdown">
              <button class="btn  me-2 dropdown-toggle">
                Register
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="openRegister(register)">Producer Portal</a>
                <a class="dropdown-item" [routerLinkActive]="['link-active']" [routerLink]="['service-portal/service-portal/company-details']">Service Provider Portal</a>
              </div>
            </div>
            <button type="button" class="btn  me-3" (click)="openLogin(login)">Login</button>
          </div>
        </ng-template>
      </div>
    </div>
  </nav>

  <ng-template #login let-modal>
    <app-login (close_model)="close_login($event)"></app-login>
  </ng-template>

  <ng-template #register>
    <app-register (close_model)="close_register($event)"></app-register>
  </ng-template>

  <ng-template #reset_password>
    <app-password-reset (close)="close_reset_password($event)" (completed)="reset_password_complete($event)"></app-password-reset>
  </ng-template>
</header>
