import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fund-this-account',
  templateUrl: './fund-this-account.component.html',
  styleUrls: ['./fund-this-account.component.css']
})
export class FundThisAccountComponent implements OnInit {

  first: boolean = true;
  second: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  fund() {
    this.first = true;
    this.second = false;
  
  }

  transfer() {
    this.first = false;
    this.second = true;
  }


}
