<div class="wrapper">
  <div class="row">
    <h1 class="page-title col-sm-12">
      Withdraw Funds
    </h1>
  </div>
  <div class="panel panel--padded">
    <div class="accounting-card accounting-card--compact accounting-card--dark ng-star-inserted">
      <div class="accounting-card__content" *ngFor="let withdraw of withdrawls">
        <div class="row">
          <div class="col-sm-9 small-text">From Account</div>
          <div class="col-sm-2">
             <select class="account-selector">
                <option value="option-1">
                  EasyProperties ZAR
                </option>
                <option value="option-2">
                  EasyEquities ZAR
                </option>
              </select>
          </div>
        </div>
        <div class="row">
          <div class="space-between">
            <div class="col-sm-9 small-text">
              Your Funds to Invest
              <i>
                <img src="../../../assets/wallet/tooltip2.svg" />
                <div class="tooltiptext">
                  Available funds reflects your available funds for you to invest.
                </div>
              </i>
            </div>
            <p class="col-sm-2 small-text bold">{{withdraw.invest}}</p>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-12 space-between">
            <span class="small-text">
              Pending Funds
            </span>
          </div>
        </div>
        <div class="row">
          <div class="space-between">
            <div class="col-sm-9 locked">
              Locked Funds
              <i>
                <img src="../../../assets/wallet/tooltip2.svg" />
                <div class="tooltiptext">
                  Locked funds represents funds that are either associated with a debit order or redeemed voucher.
                  The locked up period for debit orders and redeemed vouchers atr 40 and 150 days respectively, from
                  the date the debit order was received or the voucher redeemed. For more informaion on these lock-ups,
                  pleasse see the terms and conditions for the respective products.
                </div>
              </i>
            </div>
            <p class="col-sm-2 small-text bold">{{withdraw.locked}}</p>
          </div>
        </div>
        <div class="row">
          <div class="space-between">
            <div class="col-sm-9 locked">
              Unsettled Cash
              <i>
                <img src="../../../assets/wallet/tooltip2.svg" />
                <div class="tooltiptext">
                  Unsettled cash represents funds that are in the process of being settled as a result of the sale of your investment.
                  When selling ypur investment, the proceeds of this sale are classified a Unsettled Cash for a period of 5 business days
                  until they are deemed as settled("Settlement Period"). Once the Settlement Period is complete the cash will reflect under
                  your withdrawable funds amount and will be available for withdrawl. This is in line with JSE's Equities Rules (which dictates
                  a T+3 settlement period) and our terms and conditions which make provision for a maximum of 5 business days fot settlement.
                </div>
              </i>
            </div>
            <p class="col-sm-2 small-text bold">{{withdraw.unsettled}}</p>
          </div>
        </div>
        <div class="row1">
          <div class="space-between">
            <div class="col-sm-9 available">
              Available to withdraw
            </div>
            <p class="col-sm-2 available bold">{{withdraw.available}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="section section--padded ng-star-inserted">
      <h3 class="section__title">
        <div class="dot">1 </div> Withdraw
      </h3>
      <div class="accounting-card accounting-card--light">
        <div class="accounting-card__content" *ngFor="let withdraw of withdrawls">
          <div class="row">
            <div class="space-between">
              <span class="amount">
                Amount to Withdraw
              </span>
              <div class="small-text">
                <div class="form-group error">
                  <div class="form-group__input-group">
                    <span class="prefix bolder">
                      R
                    </span>
                    <input class="control ng-untouched ng-pristine ng-valid" type="text" [(ngModel)]="amount" (click)="toogleTag()" >
                  </div>
                  <div class="form-group__validation ng-star-inserted">
                    {{withdraw.validation}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="space-between">
              <span class="small-text">
              </span>
              <p class="small-text bold">R{{amount}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
     
    <div class="alert alert--easy-red ng-star-inserted" *ngIf="showMe">
      <p>
        As a financial service provider we are required to conduct ongoing customer due diligence (CDD)
        and at this time, we need some information from you. For more information please consult this
        <a href="https://support.easyproperties.co.za/support/solutions/articles/13000077411-what-does-we-need-some-information-from-you-message-on-withdrawals-page-mean-">
          FAQ article
        </a>
      </p>
    </div>

    <div class="section section--padded">
      <h3 class="section__title">
        <div class="dot">2</div> Confirm your bank details
      </h3>
      <div class="section">
        <div class="info-text">
          <img src="../../../assets/wallet/tooltip.svg" />
          <span> Want to change your banking details?</span>
        </div>
      </div>
    </div>

    <div class="alert alert--easy-red ng-star-inserted">
      <p>
        The service that provides your banking details seems to be down at the moment, or you
        have not capture any banking details yet. Add your banking details
        <a href="https://platform.easyequities.co.za/Profile/BankDetailsCma">
          here
        </a>
      </p>
    </div>

    <div class="panel__actions">
      <button class="ep-btn-outline ep-btn-outline--primary">
        Cancel
      </button>
      <button class="ep-btn ep-btn--primary" disabled="">
        Withdraw
      </button>
    </div>

    <div class="section">
      <h6 class="label">PLEASE NOTE</h6>
      <p> Confirmed transactions cannot be reveresed or cancelled. </p>
      <p>
        Requests received <strong>before 2pm MON - FRI</strong>, will be
        released from our Mercantile account by 4pm.
      </p>
      <p>
        Requests received <strong _ngcontent-aih-c35="">after 2pm MON - FRI</strong>, as well
        as requests received on a weekend/public holidays, will only be released the following business day.
      </p>
    </div>

  </div>
</div>

