import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';
import { ApiAccessService } from '../../api-access.service';
import { Attachment } from '../../models/attachment.model';
import { Entity } from '../../models/entity.model';
import { Registration } from '../../models/registration.model';
import { RequirementDetail } from '../../models/requirement-detail.model';
import { Requirement } from '../../models/requirement.model';
import { SystemCode, SystemCodeModel } from '../../models/system-code.model';
import { navbarData } from '../sidenav/nav-data';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { forEachChild } from 'typescript';

@Component({
  selector: 'app-services-rendered',
  templateUrl: './services-rendered.component.html',
  styleUrls: ['./services-rendered.component.css']
})
export class ServicesRenderedComponent implements OnInit {
  @Output()
  close_model = new EventEmitter<string>();

  ServicesRenderedForm: FormGroup;
  EntityDetails: FormGroup;
  entity: Registration;
  navData = navbarData;

  provinces: SystemCode[];
  countries: SystemCode[];
  months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  years: number[];
  options: {
    saving: boolean,
    duplicate: boolean,
    got_system_codes: boolean,
    uploading: boolean,
    complete: boolean,
    requirementClicked: RequirementDetail
  }

  extra: {
    refurbisher: number[],
    dismantler: number[],
    recycler: number[],
    transportUsed: number[]
  }

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log("route changed");
        this.save();
      }
    });
}

  ngOnInit(): void {
    this.ServicesRenderedForm = new FormGroup({
      collectorName: new FormControl(),
      collectorAddress: new FormControl(),
      buyBackCenter: new FormControl(),
      consolidationPort: new FormControl(),
      refurbisherName: new FormControl(),
      dismantlerName: new FormControl(),
      recyclerName: new FormControl(),
      transportUsed: new FormControl()
    });
    this.reset_forms();
  }

  reset_forms() {
    this.extra = {
      refurbisher: [],
      dismantler: [],
      recycler: [],
      transportUsed: []
    };
    var obj = sessionStorage.getItem('servicesRenderedform');
    if (sessionStorage.length > 0 && obj) {
      var ob = JSON.parse(obj);
      for (var i = 1; i < 20; i++) {
        if (ob.hasOwnProperty("refurbisher" + i)) { this.add_to_list("refurbisher", i); };
        if (ob.hasOwnProperty("dismantler" + i)) { this.add_to_list("dismantler", i); };
        if (ob.hasOwnProperty("recycler" + i)) { this.add_to_list("recycler", i); };
        if (ob.hasOwnProperty("transport" + i)) { this.add_to_list("transport", i); };
      }
      this.ServicesRenderedForm.patchValue(ob);
    }
    this.entity = {
      attachments: [],
      company: {},
      companyEntity: {},
      contactPersons: [],
      entity: {},
      requirements: []
    };
  }

  add_to_list(name: string, i?: number) {
    let num = i? i: this.extra[name].length ? this.extra[name][this.extra[name].length - 1] + 1 : 1;
    this.ServicesRenderedForm.addControl(name + num, new FormControl(''));
    this.extra[name].push(num);
  }

  remove_from_list(name: string, index: number) {
    this.ServicesRenderedForm.removeControl(name + (index + 1));
    this.extra[name].splice(index, 1);
  }

  add_director() {

  }

  save() {

    if (this.ServicesRenderedForm.value) {
      sessionStorage.setItem('servicesRenderedform', JSON.stringify(this.ServicesRenderedForm.value));
      sessionStorage.setItem('servicesRendered', "true");
    }

  }



  pt2() {
    this.router.navigate(['service-portal/service-portal/waste-collector'])
  }

  pt1() {
    this.router.navigate(['service-portal/service-portal/management'])
  }

}
