import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { userSignOut } from '../state/auth/auth.actions';
import { getUser } from '../state/auth/auth.selectors';
import { SignInData } from '../models/sign-in-data.model';
import { userSignIn } from '../state/auth/auth.actions';
import { userRegistration } from '../state/auth/auth.actions';
import { RegistrationData } from './../models/registration-data.model';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
  host: { 'class': 'd-flex flex-column position-relative'}
})
export class NavMenuComponent {

  isExpanded = false;
  isPropertiesExpanded = false;
  isAboutExpanded = false;
  systemUser$ = this.store.select(getUser);

  loginModal: NgbModalRef;
  registerModal: NgbModalRef;
  resetPasswordModal: NgbModalRef;


  constructor(private store: Store<AppState>, private modalService: NgbModal) { }

  openLogin(login) {
    this.loginModal = this.modalService.open(login,{});
  }

  openRegister(register) {
    this.registerModal = this.modalService.open(register, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  open_reset_password(reset_password) {
    this.resetPasswordModal = this.modalService.open(reset_password,{});
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  collapse() {
    this.isExpanded = false;
  }

  collapseAbout() {
    this.isAboutExpanded = false;
  }

  collapseProperties() {
    this.isPropertiesExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  toggleAbout() {
    this.isAboutExpanded = !this.isAboutExpanded;
  }

  toggleProperties() {
    this.isPropertiesExpanded = !this.isPropertiesExpanded;
  }

  logout() {
    this.store.dispatch(userSignOut());
  }

  close_login() {
    this.loginModal.close();
  }

  close_register() {
    this.registerModal.close();
  }

  close_reset_password() {
    this.resetPasswordModal.close();
  }

  reset_password_complete() {
    this.resetPasswordModal.close();
  }

 

}
