import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, RequiredValidator } from '@angular/forms';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiAccessService } from '../../api-access.service';
import { Address } from '../../models/address.model';
import { ContactNumber } from '../../models/contact-number.model';
import { Email } from '../../models/email.model';
import { Bank } from '../../models/bank.model';
import { Entity } from '../../models/Entity.model';
import { UserInfo } from '../../models/user-info.model';
import { SystemCode, SystemCodeModel } from '../../models/system-code.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  addressForm: FormGroup;
  emailForm: FormGroup;
  contactForm: FormGroup;
  entityForm: FormGroup;
  bankForm: FormGroup;

  addresstypes: SystemCode[];
  emailtypes: SystemCode[];
  contacttypes: SystemCode[];
  titles: SystemCode[];
  countries: SystemCode[];
  provinces: SystemCode[];
  suburbs: SystemCode[];
  maritalstatuses: SystemCode[];
  banknames: SystemCode[];
  btypes: SystemCode[];
  banktypes: SystemCode[];
  keyword = 'value';

  editing_address: Address;
  editing_contact: ContactNumber;
  editing_email: Email;
  editing_bank: Bank;
  editing_entity: Entity;

  info$: Observable<UserInfo>;

  residence: boolean = false;
  residence2: boolean = true;
  saving_contact: boolean = false;
  saving_address: boolean = false;
  saving_email: boolean = false;
  saving_bank: boolean = false;
  saving_entity: boolean = false;

  constructor(private apiService: ApiAccessService, private formBuilder: FormBuilder, private modalService: NgbModal) { }

  openDelete_address(delete_address) {
    this.modalService.open(delete_address, {});
  }

  openDelete_contact(delete_contact) {
    this.modalService.open(delete_contact, {});
  }

  openDelete_email(delete_email) {
    this.modalService.open(delete_email, {});
  }

  openDelete_bank(delete_bank) {
    this.modalService.open(delete_bank, {});
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.getSystemCodes();
    this.go_to("first");
    this.entityForm = new FormGroup({
      title: new FormControl(),
      name: new FormControl('', Validators.required),
      surname: new FormControl('', Validators.required),
      suburb: new FormControl('', Validators.required),
      note: new FormControl(),
      birthDate: new FormControl(Date, [Validators.required]),
      gender: new FormControl(),
      status: new FormControl(),
      identity: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(13), Validators.pattern("^(\[0-9]{13})$")]),
    });
    this.addressForm = new FormGroup({
      streetNo: new FormControl('', Validators.required),
      streetName: new FormControl('', Validators.required),
      suburb: new FormControl('', Validators.required),
      province: new FormControl('', Validators.required),
      country: new FormControl(),
      building: new FormControl(),
      postCode: new FormControl(0, [Validators.required, Validators.pattern("^(\[0-9]{4})$")]),
      preferred: new FormControl(false),
      dispatch: new FormControl(false),
      type: new FormControl(0)
    });
    this.emailForm = new FormGroup({
      address: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      preferred: new FormControl(false),
      type: new FormControl(0)
    });
    this.contactForm = new FormGroup({
      number: new FormControl(0, [Validators.required, Validators.pattern("^[0-9]{10}$")]),
      preferred: new FormControl(false),
      type: new FormControl(0),
    });
    this.bankForm = new FormGroup({
      name: new FormControl(),
      branch: new FormControl('', [Validators.required, Validators.pattern("^(\[0-9]{6})$")]),
      branchName: new FormControl(),
      bankType: new FormControl(),
      type: new FormControl(),
      account: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10), Validators.pattern("^(\[1-9]{1}[0-9]{9})$")]),
      verified: new FormControl(false)
    });
  }

  getSystemCodes() {
    let s: SystemCodeModel[] = [
      { name: "addresstypes", context: "Address", field: "Type", active: true },
      { name: "emailtypes", context: "Email", field: "Type", active: true },
      { name: "contacttypes", context: "Contact Number", field: "Type", active: true },
      { name: "titles", context: "Person", field: "Title", active: true },
      { name: "provinces", context: "Address", field: "Province", active: true },
      { name: "maritalstatuses", context: "Person", field: "Marital Status", active: true },
      { name: "banknames", context: "Bank", field: "Name", active: true },
      { name: "btypes", context: "Bank", field: "Type", active: true },
      { name: "banktypes", context: "Bank", field: "BankType", active: true },
      { name: "countries", field: "Country", active: true }
    ];
    let temp = this.apiService.get_system_codes(s).subscribe(x => {
      if (x["addresstypes"]) {
        this.addresstypes = x["addresstypes"];
      }
      if (x["emailtypes"]) {
        this.emailtypes = x["emailtypes"];
      }
      if (x["contacttypes"]) {
        this.contacttypes = x["contacttypes"];
      }
      if (x["titles"]) {
        this.titles = x["titles"];
      }
      if (x["countries"]) {
        this.countries = x["countries"];
      }
      if (x["provinces"]) {
        this.provinces = x["provinces"];
      }
      if (x["maritalstatuses"]) {
        this.maritalstatuses = x["maritalstatuses"];
      }
      if (x["banknames"]) {
        this.banknames = x["banknames"];
      }
      if (x["btypes"]) {
        this.btypes = x["btypes"];
      }
      if (x["banktypes"]) {
        this.banktypes = x["banktypes"];
      }
      temp.unsubscribe();
    });
  }

  getUserInfo() {
    this.info$ = this.apiService.get_user_information().pipe(
      tap(userInfo => this.setEntityForm(userInfo.entity))
    );
  }

  setEntityForm(entity: Entity) {
    entity.birthDate = this.getFormatedDate(new Date(entity.birthDate), "yyyy-MM-dd");
    this.entityForm.patchValue(entity);
    this.editing_entity = entity;
    //this.entityForm.value.birthDate = this.getFormatedDate(entity.birthDate, "yyyy-MM-dd");
  }

  getFormatedDate(date: Date, format: string) {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, format);
  }

  save_entity() {
    if (this.entityForm.invalid) { return; }
    this.saving_entity = true;

    let result: Entity = {
      id: this.editing_entity?.id ?? 0,
      externalNo: this.editing_entity?.externalNo ?? "",
      level: this.editing_entity?.level ?? 0,
      type: this.editing_entity?.type ?? 0,
      title: this.entityForm?.value?.title ?? 0,
      name: this.entityForm?.value?.name ?? "",
      surname: this.entityForm?.value?.surname ?? "",
      identity: this.entityForm?.value?.identity ?? "",
      birthDate: this.entityForm?.value?.birthDate ?? this.getFormatedDate(new Date(), 'yyyy-MM-dd'),
      gender: this.entityForm?.value?.gender ?? 0,
      language: this.editing_entity?.language ?? 0,
      occupation: this.editing_entity?.occupation ?? 0,
      taxNo: this.editing_entity?.taxNo ?? "",
      status: this.editing_entity?.status ?? 0,
      note: this.entityForm?.value?.note ?? "",
      commissionType: this.editing_entity?.commissionType ?? 0,
      commission: this.editing_entity?.commission ?? 0,
    };
    this.apiService.save_entity(result).subscribe(x => {
      //new address add to address list
      // update address to update address on front end
      this.entityForm.reset();
      this.save_done('first');
    });
  }

  taxx() {
    this.residence = true;
    this.residence2 = false;
  }

  //address functions
  add_address() {
    this.addressForm.reset();
    this.editing_address = null;
    this.go_to("address");
  }

  edit_address(address: Address) {
    this.editing_address = address;
    this.addressForm.reset();
    this.addressForm.patchValue(address); //taking model and through into form
    this.go_to("address");
  }

  del_address(id: number) {
    this.apiService.del_address(id).subscribe(() => { this.getUserInfo(); });
  }

  save_address() {
    if (this.addressForm.invalid) { return; }
    this.saving_address = true;
    let result: Address = {
      id: this.editing_address?.id ?? 0,
      streetNo: this.addressForm.value.streetNo,
      streetName: this.addressForm.value.streetName,
      building: this.addressForm.value.building ?? "",
      suburb: this.addressForm.value?.suburb?.value ?? this.addressForm.value?.suburb ?? "",
      city: this.editing_address?.city ?? "",
      province: this.addressForm.value.province,
      country: this.addressForm.value?.country ?? 0,
      postCode: this.addressForm.value.postCode,
      person: this.editing_address?.person ?? 0,
      moveDate: this.editing_address?.moveDate ?? new Date(),
      preferred: this.addressForm.value?.preferred ?? false,
      dispatch: this.addressForm.value?.dispatch ?? false,
      addressText: this.editing_address?.addressText ?? "",
      type: this.addressForm.value?.type ?? this.editing_address?.type ?? 0,
    };
    this.apiService.save_address(result).subscribe(x => {
      this.save_done("second");
      //new address add to address list
      // update address to update address on front end
    });
  }

  onSelected(item) {
    this.addressForm.value.suburb = item.value;
    //if (item.code){ this.addressForm.value.postCode = +item.code; }
  }

  onChangeSearch() {
    if (this.addressForm?.value?.suburb?.length >= 3) {
      this.apiService.get_suburbs(this.addressForm.value.suburb).subscribe(x => {
        this.suburbs = x;
      })
    }
  }


  //email functions
  add_email() {
    this.emailForm.reset();
    this.editing_email = null;
    this.go_to("email");
  }

  edit_email(email: Email) {
    this.editing_email = email;
    this.emailForm.reset();
    this.emailForm.patchValue(email);
    this.go_to("email");
  }

  del_email(id: number) {
    this.apiService.del_email(id).subscribe(() => { this.getUserInfo(); });
  }

  save_email() {
    if (this.emailForm.invalid) { return; }
    this.saving_email = true;
    let result: Email = {
      id: this.editing_email?.id ?? 0,
      address: this.emailForm.value.address,
      person: this.editing_email?.person ?? 0,
      preferred: this.emailForm.value?.preferred ?? false,
      type: this.emailForm.value?.type ?? this.editing_email?.type ?? 0,
    };
    this.apiService.save_email(result).subscribe(x => {
      //new address add to address list
      // update address to update address on front end
      this.save_done("second");
    });
  }


  //contact functions
  add_contact() {
    this.editing_contact = null;
    this.contactForm.reset();
    this.go_to("contact");
  }

  edit_contact(contact: ContactNumber) {
    this.editing_contact = contact;
    this.contactForm.reset();
    this.contactForm.patchValue(contact);
    this.go_to("contact");
  }

  del_contact(id: number) {
    this.apiService.del_contact_number(id).subscribe(() => { this.getUserInfo(); });
  }

  save_contact() {
    if (this.contactForm.invalid) { return; }
    this.saving_contact = true;
    let result: ContactNumber = {
      id: this.editing_contact?.id ?? 0,
      number: this.contactForm.value.number,
      person: this.editing_contact?.person ?? 0,
      preferred: this.contactForm.value?.preferred ?? false,
      type: this.contactForm.value?.type ?? this.editing_contact?.type ?? 0,
    };
    this.apiService.save_contact_number(result).subscribe(x => {
      //new address add to address list
      // update address to update address on front end
      this.save_done("second");
    });
  }

  // bank functions 
  add_bank() {
    this.bankForm.reset();
    this.editing_bank = null;
    sessionStorage.clear();
    sessionStorage.setItem("recent_tab", "bank");
  }

  edit_bank(bank: Bank) {
    this.editing_bank = bank;
    this.bankForm.reset();
    this.bankForm.patchValue(bank);
    this.go_to("bank");
  }

  del_bank(id: number) {
    this.apiService.del_bank(id).subscribe(() => { this.getUserInfo(); });
  }

  save_bank() {
    if (this.bankForm.invalid) { return; }
    this.saving_bank = true;
    let result: Bank = {
      id: this.editing_bank?.id ?? 0,
      name: this.bankForm.value?.name,
      code: this.editing_bank?.code ?? "",
      branch: this.bankForm.value.branch,
      branchName: this.bankForm.value?.branchName,
      type: this.bankForm.value?.type,
      bankType: this.bankForm.value?.bankType ?? 0,
      account: this.bankForm.value.account,
      verified: this.bankForm.value?.verified ?? false,
      entity: this.editing_bank?.entity ?? 0
    };
    this.apiService.save_bank(result).subscribe(x => {
      this.save_done("fifth");
    });
  }


  cancel(location?: string) {
    this.editing_address = null;
    this.editing_contact = null;
    this.editing_email = null;
    this.editing_bank = null;
    this.saving_email = false;
    this.saving_contact = false;
    this.saving_address = false;
    this.saving_bank = false;
    this.go_to(location);
  }

  save_done(location?: string) {
    this.getUserInfo();
    this.cancel(location);
  }

  get_from_lst(id?: number, lst?: any[]) {
    if (!id || lst?.length == 0) { return ""; }
    let result = lst.find(x => x.id === id);
    return result?.value ?? "";
  }

  // Checks if session storage is empty, meaning this the first time the page has loaded
  is_first_load() {
    if (sessionStorage.length == 0) {
      return true;
    }
    return false;
  }

  // returns the last tab visited and stored in sessionStorage 
  get_recent() {
    return sessionStorage.getItem("recent_tab");
  }

  is_pages(lst: string[]) {
    for (var i = 0; i < lst.length; i++) {
      if (this.current_page(lst[i])) {
        return true;
      }
    } return false;
  }

  current_page(location?: string) {
    if (location == "first") {
      this.is_first_load() || this.get_recent() == location;
    }
    return this.get_recent() === location;
  }

  go_to(location?: string) {
    sessionStorage.clear();
    if (location) {
      sessionStorage.setItem("recent_tab", location);
    } else {
      sessionStorage.setItem("recent_tab", "first"); // takes user to personal info for now
    }
  }
}



