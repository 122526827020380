import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';
import { ApiAccessService } from '../../api-access.service';
import { Attachment } from '../../models/attachment.model';
import { Entity } from '../../models/entity.model';
import { Registration } from '../../models/registration.model';
import { RequirementDetail } from '../../models/requirement-detail.model';
import { Requirement } from '../../models/requirement.model';
import { SystemCode, SystemCodeModel } from '../../models/system-code.model';
import { navbarData } from '../sidenav/nav-data';
import { Router, RouterLink, Event, NavigationStart } from '@angular/router';
import { forEachChild } from 'typescript';

@Component({
  selector: 'app-waste-collector',
  templateUrl: './waste-collector.component.html',
  styleUrls: ['./waste-collector.component.css']
})
export class WasteCollectorComponent implements OnInit {
  @Output()
  close_model = new EventEmitter<string>();

  WasteCollectorForm: FormGroup;
  EntityDetails: FormGroup;
  entity: Registration;
  navData = navbarData;

  provinces: SystemCode[];
  countries: SystemCode[];
  months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  years: number[];
  options: {
    saving: boolean,
    duplicate: boolean,
    got_system_codes: boolean,
    uploading: boolean,
    complete: boolean,
    requirementClicked: RequirementDetail,
  }

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log("route changed");
        this.save();
      }
    });
}

  ngOnInit(): void {
     this.WasteCollectorForm = new FormGroup({
      wasteCollectorAssos: new FormControl(),
      wasteCollectorReferenceNo: new FormControl(),
      wasteCollectorContactName: new FormControl(),
      wasteCollectorNumber: new FormControl(),
      wasteCollectorEmail: new FormControl(),
      dailyWasteCollection: new FormControl(),
      weeklyWasteCollection: new FormControl(),
      monthlyWasteCollection: new FormControl(),
      wasteCollectionAction: new FormControl(),
      wasteCollectionSource: new FormControl(),
      wasteBuyer: new FormControl(),
      wasteBuyerName: new FormControl(),
      wasteBuyerNumber: new FormControl(),
      wasteBuyerEmail: new FormControl(),
      wasteBuyerReason: new FormControl(),
      WEEESource: new FormControl(),
      personalTransport: new FormControl(),
      personalTransportTonnage: new FormControl(),
      training: new FormControl(),
      serviceAgreement: new FormControl(),
      wearBrandedPPE: new FormControl(),
     });
    this.reset_forms();
  }

  reset_forms() {
    if (sessionStorage.length > 0) {
      var obj = sessionStorage.getItem('wastecollectorform');
      var ob = JSON.parse(obj);
      this.WasteCollectorForm.patchValue(ob);
    }
    this.entity = {
      attachments: [],
      company: {},
      companyEntity: {},
      contactPersons: [],
      entity: {},
      requirements: []
    };

  }

  save() {

    if (this.WasteCollectorForm.value) {
      sessionStorage.setItem('wastecollectorform', JSON.stringify(this.WasteCollectorForm.value));
       sessionStorage.setItem('WasteCollector', "true");
    }

  }


  pt2() {
    this.router.navigate(['service-portal/service-portal/dismantling-consolidation'])
  }

  pt1() {
    this.router.navigate(['service-portal/service-portal/services-rendered'])
  }

}
